export class Wizard {
    stepName: any;
    stepNum: number;
    stepDesc: any;
    stepClass: any;
    isStepMandatory: boolean;
    iconClass: any;
    iconTooltip: any;
    iconPlacement: any;
    progressbarType: any;
    progressbarMessage: any;
    progressbarValue: number;
    progressbarMessageArray: KeyValueArray[];
    stepDescMessageArray: KeyValueArray[];
    stepDescMessage: any;
    stepExtraMessageArray: any;
    stepExtraMessage: any;
    functionId: number;
    alertPanelType: number;
    highlightStep: any;
    isDisabled: boolean;
    isInfoIcon: boolean;
    infoIconMsg: any;
    stepUrl: any;
    disabledClass:any;
    isProgressBar:any;
    comingSoonDesc:any;
}
export class WizardResponse {
    wizardList: Wizard[];
    cancelledFuncListDetails: any[];
    purchasedFunctionList: any[];
    mileageValidationDate:number;
    isAccountingIntegrationTurnOn: boolean;
    isAllStepCompleted: boolean;
    defaultScrollId: number;
}
export class KeyValueArray {
    key: number;
    value: string
}

export class wizardRequestConfig {
    companyId: number;
    accountingSystemId: number;
}

export class WizardDetailRequest extends wizardRequestConfig {
    WizardAction: number;
    OtherId: number;
   
}
export class wizardConfig {
    mainScrollConditionRequired: boolean;
    defaultScrollId: number;
}
