import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription, first } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';
import { PolicyViolationInputModel } from "src/app/_models/claimant-on-web/claimant-on-web";
import { RequestPolicyViolation,IRequestPolicyViolation,IResponsePolicyViolation } from 'src/app/_models/twostepapproval/claimant-expense-detail';
import { IApiRequest, ApiRequest, BaseCategoryTypeEnum } from 'src/app/_models';
import { PolicyDataService } from 'src/app/_services/policy/policy-data.service';

@Component({
    selector: 'formly-policy-violation',
    templateUrl: "./formly-policy-violation.component.html"
})

export class FormlyPolicyViolationComponent extends FieldType implements OnInit, OnDestroy {

    isFormSubmitted: boolean = false;
    formSubmissionSubscription: Subscription;
    fieldKey: string;
    openPanelSubscription: Subscription;
    zindexClass:string;
    showPolicyViolationPanel: boolean = false;
    policyViolationInputModel: PolicyViolationInputModel;
    isReceiptNumberSettingsEnabled:boolean=false;
    expenseModel: any;
    getPolicyRequest: IApiRequest<IRequestPolicyViolation> = new ApiRequest<RequestPolicyViolation>("", 1, "");
    getPolicyResponse: IResponsePolicyViolation;

    constructor(private dynamicFormService: DynamicFormService,private policyDataService: PolicyDataService) {
        super();
    }

    ngOnInit() {
        if (this.field.key) {
            this.fieldKey = this.field.key.toString();
        }
        
        if (this.field && this.field.templateOptions) {
        this.isReceiptNumberSettingsEnabled = this.field.templateOptions.isReceiptNumberSettingsEnabled;
        }

        this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
            this.isFormSubmitted = data;
        });
        this.openPanelSubscription = this.dynamicFormService.getOpenPanelEvent().subscribe(key => {
          if (key == this.field.key) {
            this.fnOpenPolicyViolationPanel();
          }
          // if (key == this.field.key && this.model 
          //   && this.model.baseCategoryId != BaseCategoryTypeEnum.SpecialCategory) {
          //   this.fnOpenPolicyViolationPanel();
          // }
        });
    }

    ngOnDestroy() {
        if (this.formSubmissionSubscription) {
            this.formSubmissionSubscription.unsubscribe();
        }
        if(this.openPanelSubscription) {
          this.openPanelSubscription.unsubscribe();
        }
    }

    fnOpenPolicyViolationPanel() {
      if(this.model.orgExpenseCategoryId) {

        if(this.model.baseCategoryId == BaseCategoryTypeEnum.SpecialCategory)
        {
          if(this.model.expenseId && this.model.expenseId > 0)
          {
            this.getPolicyRequest.requestInfo = new RequestPolicyViolation();
            this.getPolicyRequest.requestInfo.expenseId = this.model.expenseId;
            this.getPolicyRequest.requestInfo.orgSubCategoryMappings = this.model.orgSubCategoryMappings;
              this.expenseModel = this.model; 
                this.policyDataService.GetPolicyData(this.getPolicyRequest)
                .pipe(first())
                .subscribe(
                    data => {
                        if (data.statusCode == 0) {
                            this.getPolicyResponse = data.responseInfo;
                            this.expenseModel.orgSubCategoryMappings = this.getPolicyResponse.orgSubCategoryMappings;
                            this.expenseModel.PolicyOnMainCategory = this.getPolicyResponse.policyViolation;
                            this.props.click(this.field, this.model.baseCategoryId);
                        }
                    },
                    error => {
                        //this.toastr.error(this.translate.instant("failed"));
                    }
                );
          }
          else
          {
            this.props.click(this.field, this.model.baseCategoryId);   
          }
          
              
        }
        else
        {
          this.zindexClass="z-99999";
          this.policyViolationInputModel = new PolicyViolationInputModel(
          this.model.expenseId,
          this.model.expenseCategoryName,
          this.model.baseCategoryId,
          this.model.justificationNotes,
          false,
          ''
          );
          this.policyViolationInputModel.policyViolationDetails =  this.model.policyViolation;
          this.showPolicyViolationPanel = true;
        }

      }
    }

    fnClosePolicyViolationPanel(justificationNotes) {
      this.policyViolationInputModel = null;
      this.showPolicyViolationPanel = false;
      if (justificationNotes) {
        this.to.change(this.field, justificationNotes);
      }
    }

}
