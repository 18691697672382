import { OnInit, Component, EventEmitter, Input, Output, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { ExpenseCategoryRequest, ExpenseCategoryRequestInfo } from 'src/app/_models/claimant/expense-category-request';
import { environment } from "src/environments/environment";
import { SelectExpenseCategoryPopupModel } from 'src/app/_models/claimant-on-web/claimant-on-web';
import { BaseCategoryTypeEnum, EmailEnum, ApiRequest, IApiresponse } from "src/app/_models";
import { Claims } from "src/app/_models/common/claims";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { ExpenseCategoryService } from "src/app/_services";
import { Subscription } from "rxjs";
import { AdvancesService } from "src/app/_services/advances/advance-service";
import { AdvancePassbookItem } from "src/app/_models/advances/advances";
import { AlertPanelModel } from "src/app/_models/common/alertPanelModel";
import { TranslateService } from "@ngx-translate/core";
import { EmailSendRequest, IEmailSendRequest, ISendReminderResponse } from "src/app/_models/common/email.model";
import { EmailService } from "src/app/_services/common/email.service";
import { FunctionPackageEnum } from "../../../../_models";
import { OrganisationFunctionService } from "../../../../_services/organisation-setting/organisation-function.service";
@Component({
  selector: "select-category-panel",
  templateUrl: "./select-category-panel.component.html"
})
export class SelectCategoryPanelComponent implements OnInit, OnDestroy {

  @Input()
  inputData: SelectExpenseCategoryPopupModel;

  @Output() closePanelEvent = new EventEmitter<boolean>();
  @Output() selectItemEvent = new EventEmitter<SelectExpenseCategoryPopupModel>();

  claimsSubscription: Subscription;
  claims: Claims;
  param: any;
  orgExpenseCategoryList = <any[]>[];
  recentCategoryList: any = [];
  selectedOrgExpenseCategoryId: number = 0;
  selectedCategoryModel: SelectExpenseCategoryPopupModel;
  showPopUpForNoCategorySetup: boolean;
  showPopUpForNoCategorySetupModel: AlertPanelModel;
  isAdvancesDisplay: boolean = false;
  advancesList: any[] = [];
  selectedAdvanceId: number = 0;
  isAssignCategoriesToGradePurchased: boolean = false;
  isSupplierPurchased: boolean = false;
  hasReciept : boolean=false;
  constructor(
    private claimsService: ClaimsService,
    private expenseCategoryService: ExpenseCategoryService,
    private advancesService: AdvancesService,
    private translate: TranslateService,
    private emailService: EmailService,
    private organisationFunctionService: OrganisationFunctionService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.claimsSubscription = this.claimsService.currentClaims.subscribe((claims) => {
      this.claims = claims;
    });
  }

  ngOnInit() {
    this.checkFunctionPurchased();
    this.fnGetOrgExpenseCategoryList();
    this.fnSetExpenseCategoryInputValues();
  }

  ngOnDestroy(): void {
    this.claimsSubscription.unsubscribe();
  }

  fnGetOrgExpenseCategoryList() {
    let request = new ExpenseCategoryRequest();
    request.WebApiName = "GetExpenseCategoryByID";
    request.WebApiVersion = environment.applicationVersion;
    request.RequestId = Number(environment.requestId);

    request.RequestInfo = new ExpenseCategoryRequestInfo();
    request.RequestInfo.OrgId = this.claims.OrgId;
    request.RequestInfo.LastUpdatedTimeStamp = 0;
    request.RequestInfo.IsForCreditCardExpense = this.inputData.isIncompleteCreditCardExpense;
    request.RequestInfo.UserId = this.inputData.claimantId;
    this.expenseCategoryService
      .getOrgExpenseCategory(request)
      .subscribe((response) => {
        if (response.responseInfo != null) {
          let resData = response.responseInfo;
          this.orgExpenseCategoryList = resData.categories.filter(i => i.isActive === true && i.baseCategoryId !== BaseCategoryTypeEnum.MileageGPS);
          let disableBaseCategoryIds = this.inputData && this.inputData.disableBaseCategoryIds ? this.inputData.disableBaseCategoryIds : [];
         if(this.inputData.receiptsInfo)
          {
            this.hasReciept = this.inputData.receiptsInfo.length> 0 ? true : false;
            if(this.hasReciept)
              {
                this.orgExpenseCategoryList = this.orgExpenseCategoryList.filter(i => i.baseCategoryId != BaseCategoryTypeEnum.Mileage && 
                  i.baseCategoryId != BaseCategoryTypeEnum.MileageGPS && i.baseCategoryId != BaseCategoryTypeEnum.MileageGoogle)
              }
          }
          
          if (this.isAssignCategoriesToGradePurchased)
            this.orgExpenseCategoryList = this.orgExpenseCategoryList.filter(i => !disableBaseCategoryIds.includes(i.baseCategoryId) && i.isAssignedToGrade);
          else
            this.orgExpenseCategoryList = this.orgExpenseCategoryList.filter(i => !disableBaseCategoryIds.includes(i.baseCategoryId));

            if (this.inputData.isFmEditingExpense)
            {
              this.orgExpenseCategoryList = this.orgExpenseCategoryList.filter(i => i.baseCategoryId == this.inputData.baseCategoryId);
            }
            
            
          if (this.orgExpenseCategoryList.length > 0) {
            this.fnSortCategoryList();
            this.fnGetRecentCategoriesOnTop();
          }
          else {
            this.fnShowNoCategoriesAssigned();
          }
        }
      });
  }
  fnSelectExpenseCategory(item: any) {
    if (item) {      
      this.selectedCategoryModel = new SelectExpenseCategoryPopupModel();
      this.selectedCategoryModel.orgExpenseCategoryId = item.orgExpenseCategoryId;
      this.selectedCategoryModel.expenseCategoryName = item.expenseCategoryName;
      this.selectedCategoryModel.baseCategoryId = item.baseCategoryId;
      this.selectedCategoryModel.isPolicyAvailable = item.isPolicyAvailable;
      this.selectedCategoryModel.advanceId = 0;
      this.selectedCategoryModel.isTipsEnabled = item.isTipsEnabled;
      this.selectedCategoryModel.customFields = item.customFields;
      this.selectedCategoryModel.isNotesMandatory = item.isNotesMandatory;
      this.selectedCategoryModel.categoryAdditionalFieldData = item.categoryAdditionalFieldData
      this.selectedCategoryModel.isCFPReporting = item.isCFPReporting;
      this.selectedCategoryModel.orgSubCategoryMappings = item.orgSubCategoryMappings;
      if (item.expenseCategoryId == 28) {
        //cash advance on credit card category handling
        this.showAdvancesList();
      }
      else {
        this.selectItemEvent.emit(this.selectedCategoryModel);
      }
    }
  }

  fnShowNoCategoriesAssigned() {
    this.showPopUpForNoCategorySetupModel = new AlertPanelModel(
      true,
      this.translate.instant("label_alert"),
      [],
      this.translate.instant("text_cancel"),
      this.translate.instant("label_confirm"),
      true,
      this.translate.instant("no_categories_assigned")
    );
    this.showPopUpForNoCategorySetup = true;
    this.changeDetectorRef.detectChanges();
  }

  checkFunctionPurchased() {
    const functionIds = [FunctionPackageEnum.assigncategoriestograde, FunctionPackageEnum.Supplier];
    this.organisationFunctionService.checkFunctionsSubscribed(functionIds, this.claims.OrgId).subscribe((subscription) => {
      this.isAssignCategoriesToGradePurchased = subscription.indexOf(FunctionPackageEnum.assigncategoriestograde) > -1;
      this.isSupplierPurchased = subscription.indexOf(FunctionPackageEnum.Supplier) > -1;
    });
  } 

  fnShowPopUpForNoCategorySetupReceiver(returnValue) {
    if (returnValue) {
      let claimantName = this.inputData.claimantName;
      let keyValuePairs: { [key: string]: string; } = {};
      keyValuePairs["CategoryName"] = "";
      keyValuePairs["ClaimantName"] = claimantName.trim();
      keyValuePairs["OrgCategoryId"] = "0";
      this.fnSendReminderEmail(EmailEnum.GradeNotAssigned, keyValuePairs);
    }
    this.showPopUpForNoCategorySetupModel = new AlertPanelModel(false, "", [""], "", "", true, "");
    this.showPopUpForNoCategorySetup = false;
    this.fnClosePanel(true);
  }

  fnSendReminderEmail(templateName, keyValuePairs) {
    let request = new ApiRequest<IEmailSendRequest>("GetSetDetailForSendEmail", environment.applicationVersion, environment.requestId);
    request.requestInfo = new EmailSendRequest(templateName, this.inputData.claimantId, this.claims.OrgId, keyValuePairs);

    this.emailService.SendReminderEmail(request)
      .subscribe((response: IApiresponse<ISendReminderResponse>) => { });
  }

  fnClosePanel(isClose: boolean) {
    this.closePanelEvent.emit(isClose);
  }
  fnSetExpenseCategoryInputValues() {
    if (this.inputData) {
      this.selectedOrgExpenseCategoryId = this.inputData.orgExpenseCategoryId;
    }
  }
  fnSortCategoryList() {
    if (this.orgExpenseCategoryList && this.orgExpenseCategoryList.length > 0) {
      this.orgExpenseCategoryList.sort((a: any, b: any) => {
        //sort
        if (a.expenseCategoryName.toLocaleLowerCase() > b.expenseCategoryName.toLocaleLowerCase()) {
          return 1;
        } else if (b.expenseCategoryName.toLocaleLowerCase() > a.expenseCategoryName.toLocaleLowerCase()) {
          return -1;
        } else {
          return 0;
        }
      });
    }
  }

  showAdvancesList() {
    this.advancesService.getAdvancesPassbookNotLinked(this.inputData.claimantId).subscribe((result: AdvancePassbookItem[]) => {
      if (result && result.length > 0) {
        this.isAdvancesDisplay = true;
        this.advancesList = result.map(r => {
          return {
            advanceId: r.advanceId,
            amount: r.amount.toFixed(2)
          }
        });
      }
    });
  }

  fnSelectAdvanceItem(item) {
    this.isAdvancesDisplay = false;
    this.selectedAdvanceId = item.advanceId;
    this.selectedCategoryModel.advanceId = item.advanceId;
    this.selectItemEvent.emit(this.selectedCategoryModel);
  }

  fnGetRecentCategoriesOnTop() {
    this.recentCategoryList = this.orgExpenseCategoryList.filter(it=>it.isRecentCategory).sort(function(a, b){return b.recentExpenseCount-a.recentExpenseCount});; 
    this.recentCategoryList = this.recentCategoryList.concat(this.orgExpenseCategoryList.filter(it=>it.isRecentCategory==false));   

    this.changeDetectorRef.detectChanges();
  }

}
