<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <div class="rightSidePanel d-flex flex-column w-75pc">
        <header class="py-1 d-flex justify-content-between align-items-center height-60px">
            <div class="viewDemo d-flex align-items-center">
                <h2>{{'label_alert' | translate}}</h2>
            </div>            
            <div>
                <button type="button" class="btn eod-btn-primary mr-2" 
                [ngClass]="{'disabled bg-secondary opacity-60': disableSaveButton}" (click)="fnOnSave()">
                    {{'label_save' | translate}}
                </button>
                <button type="button" class="btn eod-btn-outline-warning" (click)="fnClosePanel()">
                    {{ 'label_close' | translate }}
                </button>
            </div>
        </header>
        <div>
            <div class="block mt-2">
                <div class="d-flex align-items-center mb-0 pb-0 border-0 a">
                    <i class="fas fa-exclamation-circle f-24 eod-text-danger d-inline-block pr-3"></i>
                    <div>
                        <h6 class="eod-text-danger fw-700 mb-1">{{"Policy_alert_msg1"| translate }}</h6>
                        <h6 class="mb-0">{{"Policy_alert_msg2"| translate }}</h6>
                    </div>
                </div>
            </div>
            <div class="block">
                <h6 class="fw-600 pb-2"><i class="fas fa-exclamation-triangle pr-3 f-18 eod-text-danger"></i>{{"submission_time_limit_voilation_expenses"| translate }}</h6>
                <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 no-padding-right-left heightCalc-8">
                    <!----------------------Start Grid--------------------------->
                    <app-grid [columns]="gridColumnDefs" [data]="gridData" [gridConfigItems]="gridConfigItems" 
                    (onCellClick)="fnOnGridCellClick($event)"></app-grid>      
                    <!----------------------End Grid--------------------------->
                </div>
            </div>
            
        </div>
    </div>
</div>

<policy-violation-panel *ngIf="showPolicyViolationPanel" [inputData]="policyViolationInputModel"
    (closePanelEvent)="fnClosePolicyViolationPanel($event)"></policy-violation-panel>