import {
  Component,
  OnInit,
  ElementRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { Claims } from "src/app/_models/common/claims";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { DateFormatPipe } from "src/app/_helpers/pipe.module";
import { IApiRequest, ApiRequest } from "src/app/_models";
import { first } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import { OrganisationSubscriptionInvoiceService } from "src/app/_services/billing-pricing/Organisation-Subscription-Invoice.service";
import { FunctionDetail } from "src/app/_models/billing-pricing/FunctionDetail";
import { OrgSubscriptionFunctionService } from "src/app/_services/billing-pricing/Org-Subscription-Function.service";
import { environment } from "src/environments/environment";
import {
  StripeInstantPaymentSessionRequest,
  IStripeInstantPaymentSessionRequest,
  StripeInstantPaymentInvoiceItem,
  StripeSessionRequest,
  IStripeSessionRequest,
} from "src/app/_models/stripe/stripe";
declare var Stripe: any;
import { SaveOrderCartDetailsService } from "src/app/_services/cart-order/SaveOrder-Cart-Details.service";
import { StripeService } from "src/app/_services/stripe/stripe.service";
import { TranslateService } from "@ngx-translate/core";
import { Location } from "@angular/common";
import { parse } from "url";
import {
  UpdateOrganisationSubscriptionInvoiceStatusRequest,
  IUpdateOrganisationSubscriptionInvoiceStatusRequest,
  OrgSubscription,
} from "src/app/_models/common/OrgSubscription";
import { GoogleMappingService } from "src/app/_services/google-mapping/google-mapping.service";
import {
  IUpdateOrganisationGooglePackagePaymentDetailStatusRequest,
  UpdateOrganisationGooglePackagePaymentDetailStatusRequest,
} from "src/app/_models/google-mapping/google-mapping";
import { Guid } from "guid-typescript";
import { PaymentMethodService } from "src/app/_services/billing-pricing/Payment-Method.service";
import { PaymentMethodList } from "src/app/_models/billing-pricing/PaymentMethodList";

@Component({
  selector: "stripe-invoice-failed-details-panel",
  templateUrl: "./stripe-invoice-failed-details-panel.component.html",
})
export class StripeInvoiceFailedDetailsPanelComponent implements OnInit {
  @Output() closePanelEvent = new EventEmitter<boolean>();

  param: any;
  claims: Claims;
  orgGoogleInvoiceFailedRecord: any;
  orgTransactionDownloadInvoiceFailedRecord: any;
  orgSubscriptionInvoiceFailedRecord: any;
  paymentCurrency: string;
  customerId: string = "";
  stripeSessionId: any;
  stripeSessionIdFromUrl: string;
  submitted: boolean = false;

  constructor(
    private claimsService: ClaimsService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private organisationSubscriptionInvoiceService: OrganisationSubscriptionInvoiceService,
    private orgSubscriptionFunctionService: OrgSubscriptionFunctionService,
    private saveOrderCartDetailsService: SaveOrderCartDetailsService,
    private stripeService: StripeService,
    private elRef: ElementRef,
    private translate: TranslateService,
    private location: Location,
    private googleMappingService: GoogleMappingService,
    private paymentMethodService: PaymentMethodService,
  ) {
    this.claimsService.currentClaims.subscribe((claims) => {
      this.claims = claims;
    });
  }

  ngOnInit() {
    if (this.route.snapshot.queryParams["sessionId"]) {
      this.stripeSessionIdFromUrl =
        this.route.snapshot.queryParams["sessionId"];

      this.fnResetUrl();
      this.fnGetStripeCustomerDetailsFromSessionId();
    }
    this.fnGetOrganisationInvoiceFailedRecords();
    this.fnGetOrganisationPaymentCurrency();
    this.fnGetStripeCustomerDetails();
    this.fnLoadStripe();
    this.getPaymentType();
  }

  fnClosePanel() {
    this.closePanelEvent.emit(true);
  }

  fnGetOrganisationInvoiceFailedRecords() {
    this.organisationSubscriptionInvoiceService
      .GetOrganisationInvoiceFailedRecords(this.claims.OrgId)
      .subscribe(
        (data) => {
          if (data.statusCode == 0) {
            
            this.orgGoogleInvoiceFailedRecord =
              data.responseInfo.orgGoogleInvoiceFailedRecord;

              this.orgTransactionDownloadInvoiceFailedRecord =
              data.responseInfo.orgTransactionDownloadInvoiceFailedResponse;

            this.orgSubscriptionInvoiceFailedRecord =
              data.responseInfo.orgSubscriptionInvoiceFailedRecord;

              this.showCardExpiryNotification = false;

              if (data.responseInfo.orgStripeCustomerDetailsResponse.cardExpirationMonthYear.length == 7)
              {
                  var cardExpirationMonth = data.responseInfo.orgStripeCustomerDetailsResponse.cardExpirationMonthYear.split("/")[0];
                  var cardExpirationYear = data.responseInfo.orgStripeCustomerDetailsResponse.cardExpirationMonthYear.split("/")[1];
                  var lastDateOfMonth = new Date(Number(cardExpirationYear), Number(cardExpirationMonth), 0);
                  let diffTime = (lastDateOfMonth.getTime() - (new Date()).getTime());
                  let differenceInDays = Math.round(diffTime / (1000 * 3600 * 24));
                  this.cardExpiryDays = differenceInDays;
                  
                  if (differenceInDays == 90 || differenceInDays == 60 || differenceInDays == 45 || differenceInDays == 30 || differenceInDays == 15 || differenceInDays <= 7)
                  {
                      this.showCardExpiryNotification = true;
                  }
              }


            if (
              !this.orgGoogleInvoiceFailedRecord &&
              !this.orgSubscriptionInvoiceFailedRecord &&
              !this.showCardExpiryNotification &&
              !this.orgTransactionDownloadInvoiceFailedRecord
            ) {
              this.claims.isStripPaymentFailed = false;
              this.claimsService.updateClaims(this.claims);
            }
          }
        },
        (error) => {
          this.toastr.error(error);
        }
      );
  }

  fnGetOrganisationPaymentCurrency() {
    let request = new ApiRequest<FunctionDetail>(
      "SoloGetOrganisationPaymentCurrency",
      environment.applicationVersion,
      environment.requestId
    );
    request.requestInfo = new FunctionDetail(
      0,
      this.claims.CurrentOrganisationCurrencyId != null
        ? this.claims.CurrentOrganisationCurrencyId
        : Number(this.claims.SelectedCurrency)
    );
    this.orgSubscriptionFunctionService
      .getOrganisationPaymentCurrency(request)
      .subscribe((data) => {
        if (data.statusCode == 0) {
          this.paymentCurrency = data.responseInfo.currencyName;
        }
      });
  }

  fnLoadStripe() {
    var stripeImport = document.createElement("script");
    stripeImport.type = "text/javascript";
    stripeImport.src = "https://js.stripe.com/v3/";
    this.elRef.nativeElement.appendChild(stripeImport);
  }

  fnResetUrl() {
    let myUrl = parse(window.location.href);

    if (!(!myUrl || null) && !(!myUrl.pathname || null)) {
      let urlPath = myUrl.pathname;
      this.location.go(urlPath);
    } else {
      this.location.go("/");
    }
  }

  fnGetStripeCustomerDetails() {
    this.saveOrderCartDetailsService
      .GetStripeCustomerDetailsByOrgId(this.claims.OrgId)
      .subscribe((response) => {
        if (response.statusCode == 0 && response.responseInfo) {
          this.customerId = response.responseInfo.paymentCustomerId;
        }
      });
  }

  fnShowStripeCheckoutWindow() {
    var stripe = Stripe(environment.StripePublicKey);
    stripe
      .redirectToCheckout({
        sessionId: this.stripeSessionId,
      })
      .then(function (result) {
        this.toastr.error(result.error.message);
      });
  }

  fnPaySubscription() {
    this.submitted = true;
    this.fnCreateSubscriptionPayObject();
  }

  fnCreateSubscriptionPayObject() {
    localStorage.setItem(
      "orgSubscriptionInvoiceFailedRecord",
      JSON.stringify(this.orgSubscriptionInvoiceFailedRecord)
    );
    this.fnLoadStripeSessionForSubscriptionInstantPayment();
  }

  fnLoadStripeSessionForSubscriptionInstantPayment() {
    let userEmail =
      this.claims.EmailId && this.claims.EmailId != ""
        ? this.claims.EmailId
        : this.claims.LoginId;
    let invoiceItem = new StripeInstantPaymentInvoiceItem();
    invoiceItem.Name = "Subscription Pay";

    invoiceItem.Amount =
      this.orgSubscriptionInvoiceFailedRecord.invoiceAmount * 100;
    invoiceItem.Currency = this.paymentCurrency.toLowerCase();
    invoiceItem.Quantity = 1;

    let invoiceItemList = [];
    invoiceItemList.push(invoiceItem);

    let sessionRequestData = new StripeInstantPaymentSessionRequest(
      environment.AppUrl+'ManageSubscription',
      this.customerId,
      userEmail,
      this.claims.CurrentOrganisationCurrencyCode,
      invoiceItemList
    );
    let sessionRequest = new ApiRequest<IStripeInstantPaymentSessionRequest>(
      "CreateStripeSessionForInstantPayment",
      environment.applicationVersion,
      environment.requestId
    );

    sessionRequest.requestInfo = sessionRequestData;

    //Set Stripe Type
    localStorage.setItem("StripeSessionType", JSON.stringify(3));

    if (invoiceItem.Amount > 0) {
      this.stripeService
        .CreateStripeSessionForInstantPayment(sessionRequest)
        .subscribe(
          (sessionData) => {
            if (sessionData.statusCode == 0 && sessionData.responseInfo) {
              this.stripeSessionId = sessionData.responseInfo;
              this.fnShowStripeCheckoutWindow();
            }
          },
          (error) => {
            this.submitted = false;
          }
        );
    }
  }

  fnPayGooglePackage() {
    this.submitted = true;
    this.fnCreateGooglePackagePayObject();
  }

  fnCreateGooglePackagePayObject() {
    localStorage.setItem(
      "orgGoogleInvoiceFailedRecord",
      JSON.stringify(this.orgGoogleInvoiceFailedRecord)
    );
    this.fnLoadStripeSessionForGooglePackageInstantPayment();
  }

  fnLoadStripeSessionForGooglePackageInstantPayment() {
    let userEmail =
      this.claims.EmailId && this.claims.EmailId != ""
        ? this.claims.EmailId
        : this.claims.LoginId;
    let invoiceItem = new StripeInstantPaymentInvoiceItem();
    invoiceItem.Name = "Google Package Pay";

    invoiceItem.Amount = this.orgGoogleInvoiceFailedRecord.amountPaid * 100;
    invoiceItem.Currency = this.paymentCurrency.toLowerCase();
    invoiceItem.Quantity = this.orgGoogleInvoiceFailedRecord.unitsPurchased;

    let invoiceItemList = [];
    invoiceItemList.push(invoiceItem);

    let sessionRequestData = new StripeInstantPaymentSessionRequest(
      environment.AppUrl+'ManageSubscription',
      this.customerId,
      userEmail,
      this.claims.CurrentOrganisationCurrencyCode,
      invoiceItemList
    );
    let sessionRequest = new ApiRequest<IStripeInstantPaymentSessionRequest>(
      "CreateStripeSessionForInstantPayment",
      environment.applicationVersion,
      environment.requestId
    );

    sessionRequest.requestInfo = sessionRequestData;

    //Set Stripe Type
    localStorage.setItem("StripeSessionType", JSON.stringify(4));

    if (invoiceItem.Amount > 0) {
      this.stripeService
        .CreateStripeSessionForInstantPayment(sessionRequest)
        .subscribe(
          (sessionData) => {
            if (sessionData.statusCode == 0 && sessionData.responseInfo) {
              this.stripeSessionId = sessionData.responseInfo;
              this.fnShowStripeCheckoutWindow();
            }
          },
          (error) => {
            this.submitted = false;
          }
        );
    }
  }

  fnGetStripeCustomerDetailsFromSessionId() {
    this.stripeService
      .GetStripeCustomerDetailsForInstantPaymentCaptured(
        this.stripeSessionIdFromUrl
      )
      .subscribe(
        (registerCardData) => {
          if (
            registerCardData.statusCode == 0 &&
            registerCardData.responseInfo
          ) {
            this.customerId = registerCardData.responseInfo.customerId;
            let stripSessionType = localStorage.getItem("StripeSessionType");
            localStorage.removeItem("StripeSessionType");
            if (parseInt(stripSessionType) == 1) {
              this.fnUpdateOrganisationSubscriptionInvoiceStatus();
            } else if (parseInt(stripSessionType) == 2) {
              this.fnUpdateOrganisationGooglePackagePaymentDetailStatus();
            }
          } else {
            this.toastr.error(this.translate.instant("failed"));
          }
        },
        (error) => {
          alert(error);
        }
      );
  }

  fnUpdateOrganisationSubscriptionInvoiceStatus() {
    let subscriptionDetails = JSON.parse(
      localStorage.getItem("orgSubscriptionInvoiceFailedRecord")
    );
    if (subscriptionDetails) {
      let request =
        new ApiRequest<IUpdateOrganisationSubscriptionInvoiceStatusRequest>(
          "SoloCreateStripeSessionForInstantPayment",
          environment.applicationVersion,
          environment.requestId
        );
      let requestInfo =
        new UpdateOrganisationSubscriptionInvoiceStatusRequest();

      requestInfo.organisationSubscriptionInvoiceID =
        subscriptionDetails.organisationSubscriptionInvoiceID;
      requestInfo.status = 1;
      requestInfo.userId = this.claims.UserId;

      request.requestInfo = requestInfo;

      this.organisationSubscriptionInvoiceService
        .UpdateOrganisationSubscriptionInvoiceStatus(request)
        .subscribe(
          (data) => {
            if (data.statusCode == 200) {
              this.toastr.success(this.translate.instant("success"));
              this.fnGetOrganisationInvoiceFailedRecords();
              localStorage.removeItem("orgSubscriptionInvoiceFailedRecord");
              this.submitted = false;
            }
          },
          (error) => {
            this.toastr.error(error);
          }
        );
    }
  }

  fnUpdateOrganisationGooglePackagePaymentDetailStatus() {
    let googlePackageDetails = JSON.parse(
      localStorage.getItem("orgGoogleInvoiceFailedRecord")
    );
    if (googlePackageDetails) {
      let request =
        new ApiRequest<IUpdateOrganisationGooglePackagePaymentDetailStatusRequest>(
          "UpdateOrganisationGooglePackagePaymentDetailStatus",
          environment.applicationVersion,
          environment.requestId
        );
      let requestInfo =
        new UpdateOrganisationGooglePackagePaymentDetailStatusRequest();

      requestInfo.organisationGooglePackagePaymentDetailId =
        googlePackageDetails.organisationGooglePackagePaymentDetailId;
      requestInfo.status = 1;
      requestInfo.userId = this.claims.UserId;

      request.requestInfo = requestInfo;

      this.googleMappingService
        .UpdateOrganisationGooglePackagePaymentDetailStatus(request)
        .subscribe(
          (data) => {
            if (data.statusCode == 200) {
              this.toastr.success(this.translate.instant("success"));
              this.fnGetOrganisationInvoiceFailedRecords();
              localStorage.removeItem("orgGoogleInvoiceFailedRecord");
              this.submitted = false;
            }
          },
          (error) => {
            this.toastr.error(error);
          }
        );
    }
  }

  goToCardUpdate() {
    this.loadStripeSessionForExistingCustomer();
  }

  showCardExpiryNotification = false;
  userEmailId = "";
  loginId = "";

  // Stripe Change Card - Start
  loadStripeSessionForExistingCustomer() {
    this.loginId = this.claims.LoginId;
    this.userEmailId = this.claims.EmailId;

    var userEmail =
      this.userEmailId && this.userEmailId != ""
        ? this.userEmailId
        : this.loginId;

    let sessionRequestData = new StripeSessionRequest(
      environment.AppUrl+'ManageSubscription',
      userEmail,
      this.claims.CurrentOrganisationCurrencyCode
    );
    let sessionRequest = new ApiRequest<IStripeSessionRequest>(
      "SoloCreateStripeSessionForExistingCustomer",
      1.0,
      "111"
    );

    sessionRequest.requestInfo = sessionRequestData;

    this.stripeService
      .CreateStripeSessionForExistingCustomer(sessionRequest)
      .pipe(first())
      .subscribe(
        (sessionData) => {
          if (sessionData.statusCode == 0 && sessionData.responseInfo) {
            this.stripeSessionId = sessionData.responseInfo;
            this.showStripeCheckoutWindow();
          }
        },
        (error) => {}
      );
  }

  showStripeCheckoutWindow() {
    var stripe = Stripe(environment.StripePublicKey);

    stripe
      .redirectToCheckout({
        sessionId: this.stripeSessionId,
      })
      .then(function (result) {
        this.toastr.error(result.error.message);
      });
  }

  paymentTypeResponse: PaymentMethodList[] = [];
  OrgPaymentTypeRequest: IApiRequest<OrgSubscription>;
  UniqueId:Guid;
  cardExpiryDays = 0;

  getPaymentType() {

    this.UniqueId = Guid.create();
    this.OrgPaymentTypeRequest = new ApiRequest<OrgSubscription>(
      "SoloOrganisationPaymentMethod",
      0,
      this.UniqueId.toString()
    );
    this.OrgPaymentTypeRequest.requestInfo = new OrgSubscription(
      this.claims.OrgId
    );
    this.paymentMethodService
      .getPaymentTypeList(this.OrgPaymentTypeRequest)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data.statusCode == 0) {
            this.paymentTypeResponse = data.responseInfo;
          } else {
            this.toastr.error(data.errorInfo[0].message);
          }
        },
        (error) => {
          this.toastr.error(error);
        }
      );
  }


  fnPayAutomaticTransactionDownload(){
    this.submitted = true;
    this.fnCreateTransactionDownloadPackagePayObject();
  }

  
  fnCreateTransactionDownloadPackagePayObject() {
    localStorage.setItem(
      "orgTransactionDownloadInvoiceFailedRecord",
      JSON.stringify(this.orgTransactionDownloadInvoiceFailedRecord)
    );
    this.fnLoadStripeSessionForTransactionDownloadInstantPayment();
  }

  fnLoadStripeSessionForTransactionDownloadInstantPayment() {
    let userEmail =
      this.claims.EmailId && this.claims.EmailId != ""
        ? this.claims.EmailId
        : this.claims.LoginId;
    let invoiceItem = new StripeInstantPaymentInvoiceItem();
    invoiceItem.Name = "Automatic Transaction download";

    invoiceItem.Amount = this.orgTransactionDownloadInvoiceFailedRecord.amountPaid * 100;
    invoiceItem.Currency = this.paymentCurrency.toLowerCase();
    invoiceItem.Quantity = this.orgTransactionDownloadInvoiceFailedRecord.unitsPurchased;

    let invoiceItemList = [];
    invoiceItemList.push(invoiceItem);

    let sessionRequestData = new StripeInstantPaymentSessionRequest(
      environment.AppUrl+'ManageSubscription',
      this.customerId,
      userEmail,
      this.claims.CurrentOrganisationCurrencyCode,
      invoiceItemList
    );
    let sessionRequest = new ApiRequest<IStripeInstantPaymentSessionRequest>(
      "CreateStripeSessionForInstantPayment",
      environment.applicationVersion,
      environment.requestId
    );

    sessionRequest.requestInfo = sessionRequestData;

    //Set Stripe Type
    localStorage.setItem("StripeSessionType", JSON.stringify(4));

    if (invoiceItem.Amount > 0) {
      this.stripeService
        .CreateStripeSessionForInstantPayment(sessionRequest)
        .subscribe(
          (sessionData) => {
            if (sessionData.statusCode == 0 && sessionData.responseInfo) {
              this.stripeSessionId = sessionData.responseInfo;
              this.fnShowStripeCheckoutWindow();
            }
          },
          (error) => {
            this.submitted = false;
          }
        );
    }
  }
}

