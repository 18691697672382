import { OnInit, Component, ViewChild, Output, EventEmitter, Input, OnChanges,ChangeDetectorRef } from "@angular/core";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { TranslateService } from "@ngx-translate/core";
import { Claims } from "src/app/_models/common/claims";
import { environment } from "src/environments/environment";
import { NgOption } from "@ng-select/ng-select";
import { ApiRequest, IApiresponse,LogObjectActionTemplateEnum } from "src/app/_models";
import { ContainerFormModel,ContainerRequest,AddContainerInputModel,AddContainerOutputModel,
    ContainerResponse,SaveContainerResponse} from "src/app/_models/container/container";
import {ContainerService} from "src/app/_services/container/container.service";
import { AlertService } from "src/app/alert-panel/alert.service";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { DynamicFormComponent } from "src/app/shared/forms/dynamic-form/dynamic-form.component";
import { DynamicFormService } from "src/app/shared/forms/dynamic-form/dynamic-form.service";
import { ToastrService } from "ngx-toastr";
import { LoggingPanelModel } from "src/app/_models/common/LoggingPanelModel";
import { LoggingPanelService } from "src/app/_services/logging-panel/logging-panel.service";

@Component({
    selector: "add-container-panel",
    templateUrl: "./add-container-panel.component.html"
})

export class AddContainerPanelComponent implements OnInit {

    @ViewChild(DynamicFormComponent) containerForm: DynamicFormComponent;

    @Input()
    inputData: AddContainerInputModel;
    
    @Output() closePanelEvent = new EventEmitter<boolean>();
    @Output() submitPanelEvent = new EventEmitter<AddContainerOutputModel>();

    claimsSubscription: Subscription;
    userActionEventSubscription: Subscription;
    claims: Claims;
    panelHeading: string;
    disableSaveButton: boolean = false;

    containerFormModel: ContainerFormModel;
    containerFormFields: FormlyFieldConfig[] = [];
    containerDetails: ContainerResponse;

    constructor(
        private router: Router,
        private claimsService: ClaimsService,
        private translate: TranslateService,
        private containerService: ContainerService,
        private cdr: ChangeDetectorRef,
        private alertService: AlertService,
        private toastr: ToastrService,
        private dynamicFormService: DynamicFormService,
        private loggingPanelService: LoggingPanelService,
    ) {
        this.claimsSubscription = this.claimsService.currentClaims.subscribe((claims) => {
            this.claims = claims;
        });

        this.fnObserveUnsavedChangesAlerts();
    }

    ngOnInit(): void {

        if (this.inputData) {
            this.fnSetPanelHeading();

            if (!this.inputData.isEditMode) {
                this.fnInitializeForm();
            }

            this.fnGetContainerById();
        }
    }

    fnSetPanelHeading() {
        if (this.inputData) {
            this.panelHeading = this.inputData.isEditMode
                ? this.translate.instant('text_edit_container')
                : this.translate.instant('create_new_report');
        }
    }

    canDeactivate() {
        return !this.containerForm.form.dirty;
    }

    fnObserveUnsavedChangesAlerts() {
        this.userActionEventSubscription = this.alertService.getUserActionEvent().subscribe(action => {
            if (action) {
                this.closePanelEvent.emit(true);
            }
        });
    }

    fnClosePanel() {
        if (this.canDeactivate()) {
            this.closePanelEvent.emit(true);
        }
        else {
            this.alertService.onFormClosed();
        }
    }

    fnInitializeForm() {

        if (!this.inputData?.isEditMode) {
            this.fnGenerateContainerFormModel();
        }

        let fields: FormlyFieldConfig[] = [];

        fields.push({
            fieldGroupClassName: 'block row mt-2',
            fieldGroup: [
                {
                    key: 'name',
                    type: 'text-input',
                    className: 'col-xl-12 col-lg-12 col-md-12 col-sm-12 padding-left-3',
                    templateOptions: {
                        label: this.translate.instant('text_report_name'),
                        placeholder: '',
                        required: true,
                        omitSpecialChar: false,
                        maxLength: 30,
                        customErrors: [],
                        maxLengthCount: 30,
                        change: (field, $event) => {}
                    },
                    validators: {
                        validation: ['whitespace']
                    }
                },
                {
                    key: 'description',
                    type: 'text-input',
                    className: 'col-xl-12 col-lg-12 col-md-12 col-sm-12 padding-left-3',
                    templateOptions: {
                        label: this.translate.instant('text_description'),
                        placeholder: '',
                        required: false,
                        omitSpecialChar: false,
                        maxLength: 30,
                        maxLengthCount: 30,
                        change: (field, $event) => { }
                    },
                    validators: {}
                }
            ]
        });
        this.containerFormFields = fields;
        
        setTimeout(() => {
            this.cdr.detectChanges();
        }, 100);

       
    }

    fnGenerateContainerFormModel() {
        this.containerFormModel = new ContainerFormModel()
        this.containerFormModel.containerId = 0;
        this.containerFormModel.name = "";
        this.containerFormModel.description = "";

        //mark form as pristine
        this.fnMarkFormAsPristine();
    }

    fnMarkFormAsPristine() {
        if (this.containerForm && this.containerForm.form) {
            this.containerForm.form.markAsPristine();
        }
    }

    fnMarkFormAsDirty() {
        if (this.containerForm && this.containerForm.form) {
            this.containerForm.form.markAsDirty();
        }
    }

    fnSubmitContainer() {
        this.dynamicFormService.onFormSubmitted(true);
        this.disableSaveButton = true;

        if (this.containerForm.form.invalid) {
            this.disableSaveButton = false;
            return;
        }

        if (!this.inputData.isEditMode) {
            this.fnAddContainer();
        } else {
           this.fnUpdateContainer();
        }
    }

    fnAddContainer() {
        let request = new ApiRequest<ContainerRequest>("AddContainer", environment.applicationVersion, environment.requestId);

        let container = new ContainerRequest();
        container.name = this.containerFormModel.name;
        container.description = this.containerFormModel.description;
        container.userId = this.inputData.claimantId;
        container.orgId = this.claims.OrgId;
        container.createdBy = this.claims.UserId;
        container.updatedBy = this.claims.UserId;
        container.userName = this.claims.FinanceManager;
        request.requestInfo = container;

        localStorage.setItem("addContainerDateTime" + this.claims.OrgId, JSON.stringify(new Date().toUTCString()));

        this.containerService.AddContainer(request)
            .subscribe((response: IApiresponse<SaveContainerResponse>) => {
                if (response && response.statusCode == 0) {
                    this.toastr.success(this.translate.instant("success"));
                    this.fnEmitSubmitEvent(response.responseInfo.containerId);
                }
                else {
                    this.toastr.error(this.translate.instant("failed"));
                }
                this.disableSaveButton = false;
            });
    }
    fnEmitSubmitEvent(containerId) {
        let outputModel = new AddContainerOutputModel();
        outputModel.containerId = containerId;
        outputModel.containerName = this.containerFormModel.name;
        outputModel.isEdited = this.inputData.isEditMode;
        this.submitPanelEvent.emit(outputModel);
    }

    fnShowContainerLoggingPanel() {
        const model = new LoggingPanelModel(
            this.claims.OrgId.toString(),
            LogObjectActionTemplateEnum.SetupContainer.toString(),
            this.inputData.containerId.toString()
        );
        this.loggingPanelService.onShowLogPanel(model);
    }

    fnGetContainerById() {
        if (!this.inputData.isEditMode) {
            return;
        }
        this.containerService.GetContainerById(this.inputData.containerId)
            .subscribe((response: IApiresponse<ContainerResponse>) => {
                if (response.responseInfo != null) {
                    this.containerDetails = response.responseInfo;
                    this.containerFormModel = new ContainerFormModel();
                    this.containerFormModel.containerId = this.containerDetails.containerId;
                    this.containerFormModel.name = this.containerDetails.name;
                    this.containerFormModel.description = this.containerDetails.description;
                }
                this.fnInitializeForm();
            });
    }

    fnUpdateContainer() {

        let request = new ApiRequest<ContainerRequest>("UpdateContainer", environment.applicationVersion, environment.requestId);

        let container = new ContainerRequest();
        container.containerId = this.inputData.containerId;
        container.orgId = this.claims.OrgId;
        container.name = this.containerFormModel.name;
        container.description = this.containerFormModel.description;
        container.updatedBy = this.claims.UserId;
        container.userName = this.claims.FinanceManager;
        request.requestInfo = container;

        localStorage.setItem("addContainerDateTime" + this.claims.OrgId, JSON.stringify(new Date().toUTCString()));

        this.containerService.UpdateContainer(request)
        .subscribe((response: IApiresponse<SaveContainerResponse>) => {
            if (response && response.statusCode == 0) {
                this.toastr.success(this.translate.instant("success"));
                this.fnEmitSubmitEvent(response.responseInfo.containerId);
            }
            else {
                this.toastr.error(this.translate.instant("failed"));
            }
            this.disableSaveButton = false;
        });
    }


      
}
