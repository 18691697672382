import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { FunctionPackageEnum, ApiRequest, IMileageWizardRequestDto, MileageWizardRequestDto, MileageRateTypeEnum,FMRoleType } from "src/app/_models";
import { map, mergeMap } from "rxjs/operators";
import { FunctionPrivilegeService } from "../secondary-fm/function-privilege.service";
import { FunctionRouteMapping } from "../../_models/secondary-fm/function-privilege";
import { MileageWizardService } from "../advance-mileage/mileage-wizard-service";
import { OrganisationSettingService } from "./organisation-setting.service";
import { ClaimsService } from "../common/claims.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: "root",
})


export class OrganisationFunctionService {

    IsLeaveManagementAllowed: boolean;
    isUniversalImportAllowed: boolean;
    IsAPAutomationAllow:boolean;
    activeUserRoleType: number;
    isContainerAllowed: boolean;
    
    constructor(
        private organisationSettingService: OrganisationSettingService,
        private functionPrivilegeService: FunctionPrivilegeService,
        private mileageWizardService: MileageWizardService,
        private claimsService: ClaimsService, private translate: TranslateService
    ) {


    }

    getAvailableFunctionsForOrganisation(organisationId: number, filterActive: boolean): Observable<any> {
        this.claimsService.currentClaims.subscribe((claims) => {
            this.IsLeaveManagementAllowed = claims.IsLeaveManagementAllowed;
            this.isUniversalImportAllowed = claims.isUniversalImportAllowed;
            this.IsAPAutomationAllow = claims.IsAPAutomationAllow;
            this.activeUserRoleType = claims.FMRoleType;
            this.isContainerAllowed = claims.IsContainerAllowed;
        });
        return this.organisationSettingService.GetOrganisationSettingList().pipe(mergeMap((data) => {
            if (data.statusCode == 0 && data.responseInfo) {
                let allFunctionCategories: any = data.responseInfo.functionCategoryDetail;
                this.hideSpecialHandledFunctionPackages(allFunctionCategories);
                return this.organisationSettingService.getSubscribedFunctionsForOrganisation(organisationId).pipe(map((subsData) => {
                    if (subsData.statusCode == 0 && subsData.responseInfo) {
                        //set functionStatus for Purchased or InTrial functionPackages
                        subsData.responseInfo.forEach((sdFunc) => {
                            allFunctionCategories.forEach((a) => {
                                a.functionPackageDetail.forEach((b) => {
                                    if (b.functionPackageId == FunctionPackageEnum.LeaveManagement) {
                                        b.showPackage = this.IsLeaveManagementAllowed;
                                    }
                                    if (b.functionPackageId == FunctionPackageEnum.UniversalImport) {
                                        b.showPackage = this.isUniversalImportAllowed;
                                    }
                                    if (b.functionPackageId == FunctionPackageEnum.APAutomation) {
                                        b.showPackage = this.IsAPAutomationAllow;
                                    }
                                    if(b.functionPackageId == FunctionPackageEnum.Container){
                                        b.showPackage = this.isContainerAllowed;
                                    }
                                    if (b.functionPackageId == sdFunc.functionID) {
                                        switch (sdFunc.functionID) {
                                            case FunctionPackageEnum.AccountingIntegration:
                                                this.managePackagesForAccountingIntegrationSubscribedCondition(
                                                    allFunctionCategories,
                                                    sdFunc.subscriptionStatus,
                                                    sdFunc.remainingTrialDays,
                                                    sdFunc.startDate,
                                                    sdFunc.trialEndDate
                                                );
                                                break;
                                            case FunctionPackageEnum.AssignDeputyToApprovers:
                                                this.managePackagesForDeputyForApprovers(
                                                    allFunctionCategories,
                                                    sdFunc.subscriptionStatus,
                                                    sdFunc.remainingTrialDays,
                                                    sdFunc.startDate,
                                                    sdFunc.trialEndDate
                                                );
                                                break;
                                            case FunctionPackageEnum.DutyofCare:
                                                this.managePackagesForDutyOfCare(
                                                    allFunctionCategories,
                                                    sdFunc.subscriptionStatus,
                                                    sdFunc.remainingTrialDays,
                                                    sdFunc.startDate,
                                                    sdFunc.trialEndDate
                                                );
                                                break;
                                            case FunctionPackageEnum.APAutomation:
                                                this.managePackagesForAPAutomation(
                                                    allFunctionCategories,
                                                    sdFunc.subscriptionStatus,
                                                    sdFunc.remainingTrialDays,
                                                    sdFunc.startDate,
                                                    sdFunc.trialEndDate
                                                );    
                                                break;
                                            case FunctionPackageEnum.DataTransferXERO:
                                                this.managePackagesForDutyOfCare(
                                                    allFunctionCategories,
                                                    sdFunc.subscriptionStatus,
                                                    sdFunc.remainingTrialDays,
                                                    sdFunc.startDate,
                                                    sdFunc.trialEndDate
                                                );
                                                break;
                                        }
                                        //Function Status
                                        //1 = Trial
                                        //2 = Purchased
                                        b.functionStatus = sdFunc.subscriptionStatus;
                                        b.remainingTrialDays = sdFunc.remainingTrialDays;
                                        b.startDate = sdFunc.startDate;
                                        b.trialEndDate = sdFunc.trialEndDate;
                                    }
                                });
                            });
                        });
                        return this.filterFunctionCategory(allFunctionCategories, filterActive);
                    }
                }));
            }
        }));
    }

    getAvailableFunctionPrivilegesForOrganisation(organisationid: number): Observable<any> {
        return this.getAvailableFunctionsForOrganisation(organisationid, true).pipe(mergeMap(functionCategories => {

            functionCategories.forEach(cat => {
                cat.functionPackageDetail = cat.functionPackageDetail.filter(function (f) {
                    return f.functionPackageId !== FunctionPackageEnum.ConfigureAdministratorPrivilege;
                });
            });

            return this.functionPrivilegeService.getFunctionPrivilegeMappings().pipe(map(privilegeList => {

                //add additional privileges to function category list
                for (let i = 0; i < privilegeList.responseInfo.length; i++) {
                    const privilegeFunctions = privilegeList.responseInfo[i].routes.map(r => r.functionID).filter(function (f) {
                        return f > 0;
                    });
                    if (privilegeFunctions.length === 0) {
                        const existingCategory = functionCategories.filter((cat) => {
                            return cat.functionCategoryName === privilegeList.responseInfo[i].privilegeCategory;
                        });
                        let pack = {
                            functionPackageId: 10000 + i,
                            functionPackageSequence: 10000 + i,
                            functionPackageName: privilegeList.responseInfo[i].privilegeName,
                            iconUrl: privilegeList.responseInfo[i].privilegeIconUrl,
                        };

                        privilegeList.responseInfo[i].routes.push(new FunctionRouteMapping(pack.functionPackageId, ''));

                        if (existingCategory.length > 0) {
                            existingCategory[0].functionPackageDetail.push(pack);
                        }
                        else {
                            functionCategories.push({
                                functionCategoryId: 1000 + i,
                                functionCategorySequence: 1000 + i,
                                functionCategoryName: privilegeList.responseInfo[i].privilegeCategory,
                                functionPackageDetail: [pack]
                            });
                        }
                    }
                }

                functionCategories.forEach(category => {
                    category.functionPackageDetail.forEach(pack => {
                        const privilege = privilegeList.responseInfo.filter((p) => {
                            return p.routes.filter(r => r.functionID === pack.functionPackageId).length > 0;
                        });
                        if (privilege && privilege.length > 0) {
                            pack.PrivilegeID = privilege[0].privilegeID;
                        }
                    });
                });

                return functionCategories;
            }));
        }));
    }

    filterFunctionCategory(origFunctionsList, filterActive) {

        const filterFunc = filterActive ? this.filterActiveFunction : this.filterFunction;

        const availableFunctionCategories = origFunctionsList
            .filter((el) => {
                return el.functionPackageDetail.some(filterFunc);
            })
            .map((a) => {
                a.functionPackageDetail = a.functionPackageDetail
                    .filter(filterFunc)
                    .sort(function (a, b) {
                        return a.functionPackageSequence - b.functionPackageSequence;
                    });
                return a;
            });

        return availableFunctionCategories;
    }

    filterFunction(f) {
        return (
            f.comingSoon == false &&
            (typeof f.showPackage == undefined ||
                f.showPackage == null ||
                (f.showPackage && f.showPackage === true))
        );
    }

    filterActiveFunction(f) {
        return (
            ((f.comingSoon === false && f.isFree === true &&
                (typeof f.showPackage == undefined ||
                    f.showPackage == null ||
                    (f.showPackage && f.showPackage === true))) ||
                (f.functionStatus &&
                    (f.functionStatus == 1 || f.functionStatus == 2) &&
                    (typeof f.showPackage == undefined ||
                        f.showPackage == null ||
                        (f.showPackage && f.showPackage === true))))
        );
    }


    checkFunctionRouteSubscribed(route: string, organisationId: number): Observable<boolean> {
        return this.functionPrivilegeService.getFunctionPrivilegeMappings().pipe(mergeMap((privileges: any) => {
            let functionIds = [];
            for (let i = 0; i < privileges.responseInfo.length; i++) {
                const privilegeRouteMap = privileges.responseInfo[i].routes.filter(function (r) {
                    return route.includes(r.route);
                });
                if (privilegeRouteMap && privilegeRouteMap.length > 0) {
                    functionIds = functionIds.concat(privilegeRouteMap.map(r => r.functionID).filter(f => f > 0));
                    break;
                }
            }
            return this.checkFunctionsSubscribed(functionIds, organisationId).pipe(map(subscription => {
                return subscription && subscription.length > 0;
            }));
        }));
    }

    checkFunctionsSubscribed(functionIds: number[], organisationId: number): Observable<number[]> {
        if (functionIds && functionIds.length > 0) {
            return this.getAvailableFunctionsForOrganisation(organisationId, true).pipe(map((functionCategories: any) => {
                const category = functionCategories.filter(function (cat) {
                    return cat.functionPackageDetail.filter(function (pack) {
                        return functionIds.indexOf(pack.functionPackageId) > -1;
                    }).length > 0;
                });
                var result = [];
                category.forEach(cat => {
                    cat.functionPackageDetail.forEach(pack => {
                        result.push(pack.functionPackageId);
                    });
                });
                return result;
            }));
        }
        else {
            return of([0]);
        }
    }

    hideSpecialHandledFunctionPackages(allFunctionCategories) {
        const specialHandledPackageIds: number[] =
            [FunctionPackageEnum.EnterOutofOffice,
            FunctionPackageEnum.DutyofCareApproval,FunctionPackageEnum.ApproveKYCDocuments, FunctionPackageEnum.ReceiptsTransferXERO];

        allFunctionCategories.forEach((a) => {
            a.functionPackageDetail.forEach((b) => {
                if (specialHandledPackageIds.indexOf(b.functionPackageId) > -1) {
                    b.showPackage = false;
                }
                if (b.functionPackageId == FunctionPackageEnum.SetupAdvancedMileage) {
                    b.showPackage = true;
                    b.isFree = true;
                    //b.functionPackageName = this.translate.instant('select_mileage');
                }

                if (b.functionPackageId == FunctionPackageEnum.LeaveManagement) {
                    b.showPackage = this.IsLeaveManagementAllowed;
                }

                if (b.functionPackageId == FunctionPackageEnum.UniversalImport) {
                    b.showPackage = this.isUniversalImportAllowed;
                }
                if (b.functionPackageId == FunctionPackageEnum.APAutomation) {
                    b.showPackage = this.IsAPAutomationAllow;
                }

                if(b.functionPackageId == FunctionPackageEnum.Container){
                    b.showPackage = this.isContainerAllowed;
                }

                if (b.functionPackageId == FunctionPackageEnum.ConfigureAdministratorPrivilege && this.activeUserRoleType !== FMRoleType.AllPrivilege) {
                    b.showPackage = false;
                }

            });
        });
    }

    managePackagesForAccountingIntegrationSubscribedCondition(
        allFunctionCategories,
        functionStatus,
        remainingTrialDays,
        startDate,
        trialEndDate
    ) {
        allFunctionCategories.forEach((a) => {
            a.functionPackageDetail.forEach((b) => {
                if (
                    b.functionPackageId == FunctionPackageEnum.DataTransferXERO ||
                    b.functionPackageId == FunctionPackageEnum.DataTransferQUICKBOOKS ||
                    b.functionPackageId == FunctionPackageEnum.DataTransferSAGE ||
                    b.functionPackageId == FunctionPackageEnum.DataTransferTALLY ||
                    b.functionPackageId == FunctionPackageEnum.DataTransferSAGE200
                ) {
                    b.functionStatus = functionStatus;
                    b.remainingTrialDays = remainingTrialDays;
                    b.startDate = startDate;
                    b.trialEndDate = trialEndDate;
                }
            });
        });
    }
    managePackagesForDeputyForApprovers(
        allFunctionCategories,
        functionStatus,
        remainingTrialDays,
        startDate,
        trialEndDate
    ) {
        allFunctionCategories.forEach((a) => {
            a.functionPackageDetail.forEach((b) => {
                if (
                    b.functionPackageId == FunctionPackageEnum.EnterOutofOffice
                ) {
                    b.functionStatus = functionStatus;
                    b.remainingTrialDays = remainingTrialDays;
                    b.startDate = startDate;
                    b.trialEndDate = trialEndDate;
                    b.showPackage = true;
                }
            });
        });
    }

    managePackagesForXeroDataTransfer(
        allFunctionCategories,
        functionStatus,
        remainingTrialDays,
        startDate,
        trialEndDate
    ) {
        allFunctionCategories.forEach((a) => {
            a.functionPackageDetail.forEach((b) => {
                if (
                    b.functionPackageId == FunctionPackageEnum.DataTransferXERO
                ) {
                    b.functionStatus = functionStatus;
                    b.remainingTrialDays = remainingTrialDays;
                    b.startDate = startDate;
                    b.trialEndDate = trialEndDate;
                    b.showPackage = true;
                }
            });
        });
    }

    managePackagesForDutyOfCare(
        allFunctionCategories,
        functionStatus,
        remainingTrialDays,
        startDate,
        trialEndDate
    ) {
        allFunctionCategories.forEach((a) => {
            a.functionPackageDetail.forEach((b) => {
                if (
                    b.functionPackageId == FunctionPackageEnum.DutyofCareApproval
                ) {
                    b.functionStatus = functionStatus;
                    b.remainingTrialDays = remainingTrialDays;
                    b.startDate = startDate;
                    b.trialEndDate = trialEndDate;
                    b.showPackage = true;
                }
            });
        });
    }

    managePackagesForAPAutomation(
        allFunctionCategories,
        functionStatus,
        remainingTrialDays,
        startDate,
        trialEndDate
    ) {
        allFunctionCategories.forEach((a) => {
            a.functionPackageDetail.forEach((b) => {
                if (
                    b.functionPackageId == FunctionPackageEnum.ApproveKYCDocuments
                ) {
                    b.functionStatus = functionStatus;
                    b.remainingTrialDays = remainingTrialDays;
                    b.startDate = startDate;
                    b.trialEndDate = trialEndDate;
                    b.showPackage = true;
                }
            });
        });
    }
    fnGetOrgWizardDetails(organisationId): Observable<any> {
        let mileagereq = new ApiRequest<IMileageWizardRequestDto>(
            "GetOrgMileageWizardDetails",
            1.0,
            "111"
        );
        let mileagereqinfo = new MileageWizardRequestDto(
            organisationId.toString()
        );
        mileagereq.requestInfo = mileagereqinfo;

        return this.mileageWizardService
            .GetOrgWizardDetails(mileagereq)
            .pipe(map((res: any) => {
                let result = {
                    isTrangulationOn: res.responseInfo.triangulationRule > 0,
                    selectedRateType: res.responseInfo.rateType
                }
                return result;
            }));
    }

}
