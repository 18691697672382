<section class="searchTotal"
         #searchTotalRow
         [hidden]="!gridConfigItems || gridConfigItems.hideFilters">
    <div class="col-md-12 no-padding-right-left"
         [hidden]="!gridOptions">
        <div class="d-flex">
            <div class="padding-left-0 {{searchClass}}">
                <div class="input-group" [ngStyle]="{'display': searchClass == 'col-md-0' ? 'none' : ''}">
                    <input type="text"
                           class="form-control searchField padding-right-20"
                           required=""
                           #searchField
                           [ngClass]="{'has-value': searchField.value}"
                           (input)="onGridSearchTextChange(searchField.value)"
                           name="searchText"
                           [(ngModel)]="searchText">
                    <label for="searchText">{{ 'label_search_criterion' | translate:param }}</label>
                    <span class="icons"><a><i class="fas fa-search"
                               aria-hidden="true"></i></a></span>
                </div>
            </div>
            <div class="text-right" *ngIf="this.gridFilterItems && this.gridFilterItems.length > 0"
                 [ngClass]="dropDownClass">
                <div class="input-group"
                     *ngIf="gridConfigItems"
                     [ngStyle]="{'display': gridFilterItems && gridFilterItems.length > 0 ? '' : 'none'}">
                    <ng-select class="solo-dropdown filterDropdown"
                               [items]="gridFilterItems"
                               bindLabel="name"
                               bindValue="id"
                               placeholder="{{ 'label_filter' | translate:param }}"
                               [clearable]="false"
                               [selectOnTab]="true"
                               [(ngModel)]="gridConfigItems.statusValue"
                               (change)="statusChange(gridConfigItems.statusValue,'')"
                               notFoundText="{{ 'no_data_available' | translate: param }}">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-5" *ngIf ="buttonFilterItem" style="margin-top: -14px;">
                <small class="d-block mb-1">{{"filter_by"| translate }}</small>
                <div class="d-flex">
                    <button class="btn btn-sm eod-btn-outline-primary mr-2" [ngClass]="{'active': selectedFilter == 8}" (click)="onDataFilterChange(8, '')">{{"all"| translate }}</button>
                    <button class="btn btn-sm eod-btn-outline-primary mr-2 " [ngClass]="{'active': selectedFilter == 6}" (click)="onDataFilterChange(6, '')">{{"corporate_credit_card"| translate }}</button>
                    <button class="btn btn-sm eod-btn-outline-primary" [ngClass]="{'active': selectedFilter == 1}" (click)="onDataFilterChange(1, '')">{{"cash_personalcredit_debitcard"| translate }}</button>
                </div>
            </div>
            <div class="text-right" *ngIf="this.dataFilterItems && this.dataFilterItems.length > 0"
                [ngClass]="dropDownClass">
               <div class="input-group"
                    *ngIf="dataFilterItems"
                    [ngStyle]="{'display': dataFilterItems && dataFilterItems.length > 0 ? '' : 'none'}">
                   <ng-select class="solo-dropdown filterDropdown"
                              [items]="dataFilterItems"
                              bindLabel="name"
                              bindValue="id"
                              [placeholder]="gridConfigItems.dataFilterTitle"
                              [clearable]="false"
                              [selectOnTab]="true"
                              [(ngModel)]="gridConfigItems.dataFilterValue"
                              (change)="onDataFilterChange(gridConfigItems.dataFilterValue,'')"
                              notFoundText="{{ 'no_data_available' | translate: param }}">
                   </ng-select>
               </div>
           </div>
            <div [ngClass]="calendarClass" *ngIf="fromDateFilter">

                <div class="input-group"
                     [ngStyle]="{'display': fromDateFilter ? '' : 'none'}">
                    <input class="form-control has-value"
                           #fFrmDate
                           [(ngModel)]="this.nrfcConfigModel.fromDate"
                           (input)="onFromDateChange($event.target.value)" />
                    <label for="fromSetDate">
                        {{ 'start_date' | translate:param }}
                    </label>
                    <app-calendar-icon></app-calendar-icon>
                </div>

            </div>
            <div class="col-lg-3 col-md-3 col-sm-3" *ngIf="showAdvancedSearch" >

                <p class="padding-left-10  padding-top-10 text-underline cursor-pointer text-logo-blue" (click)="showAdvanceSearchPanel()">
                    {{"advance_search" | translate}} </p>
                    <p class="position-relative">
                    
                    <small *ngIf="advanceSearchAlphabet!=''" class="color-grey padding-left-10 cursor-default" >({{"letter_filter" | translate}} "{{advanceSearchAlphabet}}" - <a  style="color: #29b7e9; cursor: pointer;text-decoration: underline;" class="text-underline" (click)="clearAdvanceSearch()">{{"clear_filter" | translate}}</a>)</small>
                </p>



            </div>
            <div class="text-right table-total"
                 [ngClass]="counterDivClass"
                 *ngIf="gridConfigItems">
                <div [ngStyle]="{'display': gridConfigItems.countByStatus > 0 ? '' : 'none'}"
                     *ngFor="let item of gridConfigItems.countByStatus"
                     [ngClass]="item.className">
                    <span (click)="statusChange('',item)">{{item.label | translate:param}}<span
                              *ngIf="item.count!= null">:</span>
                        {{item.count}}</span>
                </div>
          
            </div>
        </div>
    </div>
</section>
<div *ngIf="gridConfigItems && gridConfigItems.gridTitle">
    <h6 class="fw-600" [ngClass]="gridConfigItems.gridTitleClass">{{gridConfigItems.gridTitle | translate:param}}</h6>
</div>
<!-- <h5 *ngIf ="buttonFilterItem" class="fw-600">Items for Review (2 / 4)</h5> -->
<div class="outer-div">
    <div class="grid-wrapper {{gridWrapperClass}}"
         [ngStyle]="gridstyle"
         *ngIf="gridOptions">
        <ag-grid-angular #agGrid
                         [ngStyle]="gridWrapperstyle"
                         id="myGrid"
                         class="ag-theme-alpine"
                         [ngClass]="{'custom-hover': gridConfigItems.isHoverRowColumnHighlight}"
                         (gridReady)="onGridReady($event)"
                         (columnResized)="oncolumnResized($event)"
                         (cellClicked)="onCellClicked($event)"
                         [gridOptions]="gridOptions"
                         [suppressDragLeaveHidesColumns]="true"
                         [suppressMakeColumnVisibleAfterUnGroup]="true"
                         [rowGroupPanelShow]="rowGroupPanelShow"
                         [autoGroupColumnDef]="autoGroupColumnDef"
                         [enableRangeSelection]="true"
                         [groupHideOpenParents]="true"
                         [animateRows]="true"
                         [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                         [overlayLoadingTemplate]="overlayLoadingTemplate"
                         [pinnedBottomRowData]="pinnedBottomRowData"
                         [suppressRowHoverHighlight]="gridConfigItems.suppressRowHoverHighlight"
                         [columnHoverHighlight]="false">
        </ag-grid-angular>
    </div>
</div>




<div class="overLayDiv active" *ngIf="showAdvancedFilters"></div>
<div id="rightSideBar" style="z-index: 9999999;">
    <div class="rightSidePanel active" *ngIf="showAdvancedFilters">
        <header>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-right-left">
                    <div class="row">
                        <div class="col-lg-9 padding-left-0">
                            <h2>{{"advance_search" | translate}}</h2>
                        </div>
                        <div class="col-lg-3 padding-right-0 header-icons text-right">
                            <ul class="d-flex justify-content-end w-100pc">

                                <li><button (click)="closeAdvanceSearchPanel()"
                                            class="btn btn-outline-secondary border-color-warning text-mustard height-38px">{{ 'label_close' | translate}}</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="clear"></div>
        <div class="block heightCalc-6 overflow-hidden">
            <div class="col-md-12 col-sm-12 no-padding-right-left ">
                <section class="searchTotal mt-0">
                    <div class="col-md-12 no-padding-right-left">
                        <div class="row">

                            <div class="border-bottom padding-bottom-10">
                                <h6 class="color-orange mb-0 lH-normal">{{"name_search_text" | translate}} </h6><small
                                       class="d-block">({{"contacts_count_text" | translate}})</small>
                            </div>

                            <div class="xeroAdvFilter box-shadow">

                                <div class="d-flex justify-content-between">
                                    <div class="w-100pc">
                                        <ul class="letterList-A-F">
                                            <li (click)="startsWith_A>0&&filterWithAdvanceFilter('A')">A <small>({{startsWith_A}})</small></li>
                                            <li (click)="startsWith_B>0&&filterWithAdvanceFilter('B')">B <small>({{startsWith_B}})</small></li>
                                            <li (click)="startsWith_C>0&&filterWithAdvanceFilter('C')">C <small>({{startsWith_C}})</small></li>
                                            <li (click)="startsWith_D>0&&filterWithAdvanceFilter('D')">D <small>({{startsWith_D}})</small></li>
                                            <li (click)="startsWith_E>0&&filterWithAdvanceFilter('E')">E <small>({{startsWith_E}})</small></li>
                                            <li (click)="startsWith_F>0&&filterWithAdvanceFilter('F')">F <small>({{startsWith_F}})</small></li>
                                        </ul>
                                        <ul class="letterList-G-L">
                                            <li (click)="startsWith_G>0&&filterWithAdvanceFilter('G')">G <small>({{startsWith_G}})</small></li>
                                            <li (click)="startsWith_H>0&&filterWithAdvanceFilter('H')">H <small>({{startsWith_H}})</small></li>
                                            <li (click)="startsWith_I>0&&filterWithAdvanceFilter('I')">I <small>({{startsWith_I}})</small></li>
                                            <li (click)="startsWith_J>0&&filterWithAdvanceFilter('J')">J <small>({{startsWith_J}})</small></li>
                                            <li (click)="startsWith_K>0&&filterWithAdvanceFilter('K')">K <small>({{startsWith_K}})</small></li>
                                            <li (click)="startsWith_L>0&&filterWithAdvanceFilter('L')">L <small>({{startsWith_L}})</small></li>
                                        </ul>
                                        <ul class="letterList-M-R">
                                            <li (click)="startsWith_M>0&&filterWithAdvanceFilter('M')">M <small>({{startsWith_M}})</small></li>
                                            <li (click)="startsWith_N>0&&filterWithAdvanceFilter('N')">N <small>({{startsWith_N}})</small></li>
                                            <li (click)="startsWith_O>0&&filterWithAdvanceFilter('O')">O <small>({{startsWith_O}})</small></li>
                                            <li (click)="startsWith_P>0&&filterWithAdvanceFilter('P')">P <small>({{startsWith_P}})</small></li>
                                            <li (click)="startsWith_Q>0&&filterWithAdvanceFilter('Q')">Q <small>({{startsWith_Q}})</small></li>
                                            <li (click)="startsWith_R>0&&filterWithAdvanceFilter('R')">R <small>({{startsWith_R}})</small></li>
                                        </ul>
                                        <ul class="letterList-S-X">
                                            <li (click)="startsWith_S>0&&filterWithAdvanceFilter('S')">S <small>({{startsWith_S}})</small></li>
                                            <li (click)="startsWith_T>0&&filterWithAdvanceFilter('T')">T <small>({{startsWith_T}})</small></li>
                                            <li (click)="startsWith_U>0&&filterWithAdvanceFilter('U')">U <small>({{startsWith_U}})</small></li>
                                            <li (click)="startsWith_V>0&&filterWithAdvanceFilter('V')">V <small>({{startsWith_V}})</small></li>
                                            <li (click)="startsWith_W>0&&filterWithAdvanceFilter('W')">W <small>({{startsWith_W}})</small></li>
                                            <li (click)="startsWith_X>0&&filterWithAdvanceFilter('X')">X <small>({{startsWith_X}})</small></li>
                                        </ul>
                                        <ul class="letterList-Y-Z">
                                            <li (click)="startsWith_Y>0&&filterWithAdvanceFilter('Y')">Y <small>({{startsWith_Y}})</small></li>
                                            <li (click)="startsWith_Z>0&&filterWithAdvanceFilter('Z')">Z <small>({{startsWith_Z}})</small></li>
                                            <li class="color-white disabled pointer-events-none">&nbsp;<small
                                                       class="color-white">(20)</small></li>
                                            <li class="color-white disabled pointer-events-none">&nbsp;<small
                                                       class="color-white">(20)</small></li>
                                            <li class="color-white disabled pointer-events-none">&nbsp;<small
                                                       class="color-white">(20)</small></li>
                                            <li class="color-white disabled pointer-events-none">&nbsp;<small
                                                       class="color-white">(20)</small></li>
                                        </ul>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>

            </div>
        </div>
    </div>
</div>
