import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { fromEvent, of, Subscription } from "rxjs";
import { ColumnApi, GridApi, GridOptions, IGetRowsParams, IDatasource } from "ag-grid-community";
import { LogIconRendererComponent } from "../controls/log-icon-renderer/log-icon-renderer.component";
import { CheckBoxRendererComponent } from "../controls/checkbox-renderer/checkbox-renderer.component";
import { ActionsRendererComponent } from "../controls/actions-renderer/actions-renderer.component";
import { CustomHeaderComponent } from "../controls/header-component/custom-header.component";
import { StatusRenderComponent } from "../controls/status-renderer/status-renderer.component";
import { GridConfig } from "src/app/_models/common/grid/grid-config";
import { ChevronRendererComponent } from "../controls/chevron-renderer/chevron-renderer.component";
import { PreApprovalRendererComponent } from "../controls/pre-approval-renderer/pre-approval-renderer.component";
import { ReceiptIconRendererComponent } from "../controls/receipt-icon-renderer/receipt-icon-renderer.component";
import { FieldValueRendererComponent } from "../controls/field-value-renderer/field-value-renderer.component";
import { TranslateService } from "@ngx-translate/core";
import { DateRendererComponent } from "../controls/date-renderer/date-renderer.component";
import { DateTimeRendererComponent  } from "../controls/date-time-renderer/date-time-renderer.component";
import { AmountRendererComponent } from "../controls/amount-renderer/amount-renderer.component";
import { PolicyNameRendererComponent } from "../controls/policy-name-renderer/policy-name-renderer.component";
import { PolicyAmountRendererComponent } from "../controls/policy-amount-renderer/policy-amount-renderer.component";
import { CheckIconRendererComponent } from "../controls/check-icon-renderer/check-icon-renderer.component";
import { PricingTextRendererComponent } from "../controls/pricing-text-renderer/pricing-text-renderer.component";
import { ReceiptsIconRendererComponent } from "../controls/receipts-icon-renderer/receipts-icon-renderer.component";
import { SafeTextRendererComponent } from "../controls/safe-text-renderer/safe-text-renderer.component";
import { StarterMobileSupportIconRendererComponent } from "../controls/starter-mobile-support-icon-renderer/starter-mobile-support-icon-renderer.component";
import { StarterWebSupportIconRendererComponent } from "../controls/starter-web-support-icon-renderer/starter-web-support-icon-renderer.component";
import { NgSelectRendererComponent } from "../controls/ng-select-renderer/ng-select-renderer.component";
import { TooltipRenderComponent } from "../controls/tooltip-renderer/tooltip-renderer.component";
import { TextWithTooltipRightRendererComponent } from "../controls/text-tooltip-right-renderer/text-tooltip-right-renderer.component";
import { CardNumberRendererComponent } from "../controls/card-number-renderer/card-number-renderer.component";
import { CardAssignedUsernameRendererComponent } from "../controls/card-assigned-username-renderer/card-assigned-username-renderer.component";
import { TextWithTooltipRendererComponent } from "../controls/text-tooltip-renderer/text-tooltip-renderer.component";
import { TextWithTooltipRenderer2Component } from "../controls/text-tooltip-renderer2/text-tooltip-renderer2.component";
import { ClickToSetupRendererComponent } from "../controls/click-to-setup-renderer/click-to-setup-renderer.component";
import { ToggleRendererComponent } from "../controls/toggle-renderer/toggle-renderer.component";
import { TextboxRendererComponent } from "../controls/textbox-renderer/textbox-renderer.component";
import { TextRendererComponent } from "../controls/text-renderer/text-renderer.component";
import { CheckboxIngridRendererComponent } from "../controls/checkbox-ingrid-renderer/checkbox-ingrid-renderer.component";
import { ButtonsRendererComponent } from "../controls/buttons-renderer/buttons-renderer.component";
import { ImportErrorsCountRendererComponent } from "../controls/import-errors-count-renderer/import-errors-count-renderer.component";
import { FormattedTextRenderer } from "../controls/formatted-text-renderer/formatted-text-renderer.component";
import { ApproverRenderer } from "../controls/approver-renderer/approver-renderer.component";
import { DefaultMessageRendererComponent } from "../controls/default-message-renderer/default-message-renderer.component";
import { SuspensionMessageRendererComponent } from "../controls/suspension-message-renderer/suspension-message-renderer.component";
import { TextWithDescriptionRendererComponent } from "../controls/text-with-description-renderer/text-with-description-renderer.component";
import { ApprovalDataRendererComponent } from "../controls/approval-data-renderer/approval-data-renderer.component";
import { InfoStepRendererComponent } from "../controls/info-step-renderer/info-step-renderer.component";
import { PrivilegeCountRendererComponent } from "../controls/privilege-count-renderer/privilege-count-renderer.component";
import { StatusDataRenderComponent } from "src/app/shared/grid/controls/status-data-renderer/status-data-renderer.component";
import { GridNgSelectOrTextRendererComponent } from "src/app/shared/grid/controls/grid-ng-select-or-text-renderer/grid-ng-select-or-text-renderer.component";
import { GridTextBoxOrTextRendererComponent } from "src/app/shared/grid/controls/grid-textbox-or-text-renderer/grid-textbox-or-text-renderer.component";
import { CheckBoxwithTextRenderComponent } from "src/app/shared/grid/controls/check-box-with-text/check-box-with-text.component";
import { PercentageRendererComponent } from "src/app/shared/grid/controls/percentage-renderer/percentage-renderer.component";
import { DiscountRendererComponent } from "src/app/shared/grid/controls/discount-renderer/discount-renderer.component";
import { MultiColorTextRendererComponent } from "../controls/multi-color-text-renderer/multi-color-text-renderer.component";
import { TextTwoDecimalNumberRendererComponent } from "../controls/text-two-decimal-number-renderer/text-two-decimal-number-renderer.component";
import { GridTextBoxRendererComponent } from "src/app/shared/grid/controls/grid-textbox-renderer/grid-textbox-renderer.component";
import { ToggleRendererSecondComponent } from "../controls/toggle-renderer-second/toggle-renderer-second.component";
import { NRFCConfigModel } from "src/app/_models/NrFc/nrfcconfig-model";
import { formatDate } from "@angular/common";
import { ProgressBarRendererComponent } from "src/app/shared/grid/controls/progress-bar-renderer/progress-bar-renderer.component";
import { CountWithCheckBoxRendererComponent } from "src/app/shared/grid/controls/count-with-checkbox-renderer/count-with-checkbox-renderer.component";
import { NgSelectDescriptionRendrerComponent } from "../controls/ng-select-description-rendrer/ng-select-description-rendrer.component";
import { ResultRendererComponent } from "../controls/result-renderer/result-renderer.component";
import { GridDropdownRendererComponent } from "src/app/shared/grid/controls/grid-dropdown-renderer/grid-dropdown-renderer.component";
import { ReceiptsTextRendererComponent } from "../controls/receipts-text-renderer/receipts-text-renderer.component";
import { TwoDecimalNumberInputRendererComponent } from "src/app/shared/grid/controls/two-decimal-number-input-renderer/two-decimal-number-input-renderer.component";
import {ActionButtonsRendererComponent} from "src/app/shared/grid/controls/action-buttons-renderer/action-buttons-renderer.component";
import { RateRendererComponent } from "../controls/rate-renderer/rate-renderer.component";
import {CountLinkRendererComponent} from "src/app/shared/grid/controls/count-link-renderer/count-link-renderer.component";
import {TranslatedTextTendererComponent} from "src/app/shared/grid/controls/translated-text-renderer/translated-text-renderer.component";
import {RadioRendererComponent} from "src/app/shared/grid/controls/radio-renderer/radio-renderer.component";
import {MultipleButtonRendererComponent} from "src/app/shared/grid/controls/multiple-button-renderer/multiple-button-renderer.component";
import {StatusSupportingTextRendererComponent} from "src/app/shared/grid/controls/status-supporting-text-renderer/status-supporting-text-renderer.component";
import {CustomActionsRendererComponent} from "src/app/shared/grid/controls/custom-actions-renderer/custom-actions-renderer.component";

import {GridToggleRendererComponent} from "src/app/shared/grid/controls/grid-toggle-renderer/grid-toggle-renderer.component";
import {MultipleCardNumberRendererComponent} from "src/app/shared/grid/controls/multiple-card-number-renderer/multiple-card-number-renderer.component";
import {ViewRendererComponent} from "src/app/shared/grid/controls/view-renderer/view-renderer.component";
import {DayPolicyRenderer} from "src/app/shared/grid/controls/day-policy-renderer/day-policy-renderer.component";

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppGridComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input("columns")
  columnDefs: any[];

  @Input("data")
  allGridData: any;

  @Input("gridFilterItems")
  gridFilterItems: string;

  @Input("gridConfigItems")
  gridConfigItems: GridConfig;

  @Input("pinnedBottomRowData")
  pinnedBottomRowData: any;
  @Input("fromDateFilter")
  fromDateFilter: any;

  @Input("showAdvancedSearch")
  showAdvancedSearch = false;

  @Input("dataFilterItems")
  dataFilterItems: string;

  @Input("buttonFilterItem")
  buttonFilterItem: boolean = false;

  @Output()
  onCellClick = new EventEmitter<any>();

  @Output()
  onCheckAll = new EventEmitter<boolean>();

  @Output()
  filterChanged = new EventEmitter<any>();

  @Output()
  advanceSearchChanged = new EventEmitter<any>();

  @Output()
  dataFilterChanged = new EventEmitter<any>();

  @ViewChild('searchTotalRow', { read: ElementRef }) searchElement: ElementRef<any>;
  @ViewChild("fFrmDate") fFrmDate: any;

  gridData: any;
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  gridstyle: any;
  gridWrapperstyle: any = {
    width: '100%',
    height: '100%',
  };
  rowClassRules;
  gridlastRowIndex: number;
  private gridSortColumn: string;
  private gridSortOrder: string;
  private group_MIN_HEIGHT = 40;
  private header_MIN_HEIGHT = 40;
  private isAllChecked: boolean = false;
  searchText: string;
  filters: any = null;
  param: string;
  resizeSubscription$: Subscription

  overlayLoadingTemplate: string;
  overlayNoRowsTemplate: string;
  isDataLoading: boolean = false;
  isRefresh: boolean = false;
  searchClass: string = 'col-lg-2 col-md-2 col-sm-2';
  dropDownClass: string = 'col-lg-2 col-md-2 col-sm-2';
  calendarClass: string = 'col-lg-2 col-md-2 col-sm-2';
  counterDivClass: string = 'col-lg-10 col-md-10 col-sm-10';

  gridWrapperClass: string = '';
  nrfcConfigModel: NRFCConfigModel = new NRFCConfigModel();
  constructor(private translate: TranslateService) {
    this.overlayNoRowsTemplate = `
    <div class="text-center noDataAvailable w-100pc f-16 position-relative">
        ${this.translate.instant("no_data_availabe")}
      </div>
    `;
    this.overlayLoadingTemplate = `
    <div class="text-center noDataAvailable w-100pc f-16 position-relative">
        ${this.translate.instant("data_loading")}
      </div>
    `;
  }

  selectedFilter: number = 8;

  ngOnInit() {
    const resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = resizeObservable$.subscribe(evt => {
      this.setWidthAndHeight();
    });
    console.log(this.buttonFilterItem);
  }
  ngAfterViewInit() {
    //Initialize datePicker
    if (this.fFrmDate) {
      this.fFrmDate.nativeElement.flatpickr(this.nrfcConfigModel.datePickerOptionsFromDate);
    }

  }
  
  onFromDateChange(name: string) {
    this.nrfcConfigModel.fromDate = new Date(name);
    this.nrfcConfigModel.pageNumber = 1;
    this.nrfcConfigModel.datePickerOptionsFromDate = {
      mode: "single",
      defaultDate: this.nrfcConfigModel.fromDate,
      minDate: this.nrfcConfigModel.fromDate,
      maxDate: new Date(),
      altInput: true,
      altFormat: "d-M-Y",
      dateFormat: "d-M-Y",
    };
    this.nrfcConfigModel.isDateFilterApplied = true;
    this.nrfcConfigModel.compliantType = 0;
    let model = {
      type: 'calendar',
      value: this.nrfcConfigModel.fromDate.toISOString()
    }
    this.filterChanged.emit(model);
  }
  fnSetDateCalender(dateFilter) {
    let effdateShort = formatDate(dateFilter, 'dd-MMM-yyyy', 'en-US');
    let effDateFormat = new Date(effdateShort);
    this.fFrmDate.nativeElement
      .flatpickr({
        mode: "single",
        dateFormat: "d-M-Y",
        defaultDate: effDateFormat,
        altInput: true,
        altFormat: "d-M-Y"
      });
  }
  ngOnDestroy() {
    if (this.resizeSubscription$) {
      this.resizeSubscription$.unsubscribe();
    }
  }

  private setWidthAndHeight() {
    setTimeout(() => {
      if (this.gridFilterItems && this.gridFilterItems.length > 0) {
        this.dropDownClass = 'col-lg-2 col-md-2 col-sm-2';
      } else {
        this.dropDownClass = 'col-md-0';
      }

      if (this.fromDateFilter) {
        this.calendarClass = 'col-lg-2 col-md-2 col-sm-2';
      } else {
        this.calendarClass = 'col-md-0';
      }


      if (this.gridFilterItems && this.gridFilterItems.length > 0 && this.fromDateFilter) {
        if (this.showAdvancedSearch) {
          this.counterDivClass = 'col-lg-5 col-md-5 col-sm-5';
        }
        else {
          this.counterDivClass = 'col-lg-6 col-md-6 col-sm-6';
        }
      }
      else if ((this.gridFilterItems && this.gridFilterItems.length > 0 && !this.fromDateFilter)
        || (this.gridFilterItems && this.gridFilterItems.length == 0 && this.fromDateFilter)
      ) {
        if (this.showAdvancedSearch) { 
          this.counterDivClass = 'col-lg-5 col-md-5 col-sm-5'; 
        } 
        else { 
          this.counterDivClass = 'col-lg-8 col-md-8 col-sm-8'; 
        } 
      }
      else if (this.gridFilterItems && this.gridFilterItems.length == 0 && !this.fromDateFilter) {
        this.counterDivClass = 'col-lg-10 col-md-10 col-sm-10';
      }

     if(this.dataFilterItems && this.dataFilterItems.length > 0){
         this.counterDivClass = 'col-lg-6 col-md-6 col-sm-6';
      }

      const searchRowRect = this.searchElement.nativeElement.getBoundingClientRect();
      let height = parseInt(((window.innerHeight - searchRowRect.bottom) * 95 / 100).toString());
      if (height < 400) {
        height = 400;
      }
      if (this.gridConfigItems && this.gridConfigItems.gridTitle) {
        height = height - 20;
      }
      this.gridWrapperClass = this.gridConfigItems?.gridWrapperClass ?? this.gridWrapperClass;

      this.gridstyle = {
        marginTop: '0px',
        width: '100%',
        height: (height) + 'px',
        boxSizing: 'border-box'
      };
      if (this.pinnedBottomRowData) {

        this.gridWrapperstyle = {
          width: '100%',
          height: this.pinnedBottomRowData[0].gridHeight + '%',
        };
      }
      if (this.gridConfigItems.searchBarClass) {
        this.searchClass = this.gridConfigItems.searchBarClass.searchClass;
        this.dropDownClass = this.gridConfigItems.searchBarClass.dropDownClass;
        this.calendarClass = this.gridConfigItems.searchBarClass.calendarClass;
        this.counterDivClass = this.gridConfigItems.searchBarClass.counterDivClass;
      }

    }, 100);
  }

  private sortGrid(sortColumn, sortDirection) {
    // Sort column and clear sort on all other columns
    this.gridColumnApi.applyColumnState({
      state: [
        {
          colId: sortColumn,
          sort: sortDirection
        }
      ],
      defaultState: {
        // important to say 'null' as undefined means 'do nothing'
        sort: null
      }
    });
  }

  public setDataLoading(isDataLoading) {
    this.isDataLoading = isDataLoading;
    if (this.gridlastRowIndex > 0) {
      this.isDataLoading = false;
      this.gridApi.hideOverlay();
    }
    else if (this.isDataLoading) {
      this.gridApi.showLoadingOverlay();
    }
    else {
      this.gridApi.showNoRowsOverlay();
    }
  }

  public initializeGrid(isDataLoading = true) {
    let self = this;
    this.isDataLoading = isDataLoading;
    if (this.columnDefs && this.columnDefs.length > 0) {
      this.fnAddCheckboxColumn(this.columnDefs);

      this.overlayNoRowsTemplate = `
      <div class="text-center noDataAvailable w-100pc f-16 position-relative">
          ${this.translate.instant(this.gridConfigItems.noDataMessage)}
        </div>
      `;

      this.columnDefs.forEach(columnDef => {
        columnDef.tooltipComponent = 'customTooltip';
        columnDef.tooltipField = columnDef.field;
        if (!columnDef.headerComponentParams) {
          columnDef.headerComponentParams = {};
        }
        columnDef.headerComponentParams.rowCount = this.allGridData.length;
      });

      this.gridOptions = {
        rowSelection: 'multiple',
        rowHeight: self.gridConfigItems.rowHeight,
        headerHeight: 0,
        groupHeaderHeight: 0,
        cacheBlockSize: 100,
        maxBlocksInCache: 3,
        rowModelType: 'infinite',
        pagination: false,
        paginationAutoPageSize: true,
        components: {
          cbxRenderer: CheckBoxRendererComponent,
          logRenderer: LogIconRendererComponent,
          actionRenderer: ActionsRendererComponent,
          agColumnHeader: CustomHeaderComponent,
          statusRenderer: StatusRenderComponent,
          ChevronRenderer: ChevronRendererComponent,
          PreApprovalRenderer: PreApprovalRendererComponent,
          PolicyNameRenderer: PolicyNameRendererComponent,
          PolicyAmountRenderer: PolicyAmountRendererComponent,
          ReceiptIconRender: ReceiptIconRendererComponent,
          fieldValueRenderer: FieldValueRendererComponent,
          dateRenderer: DateRendererComponent,
          dateTimeRenderer: DateTimeRendererComponent,
          amountRenderer: AmountRendererComponent,
          checkboxIconRenderer: CheckIconRendererComponent,
          pricingTextRendererComponent: PricingTextRendererComponent,
          receiptsIconRenderer: ReceiptsIconRendererComponent,
          receiptsTextRenderer: ReceiptsTextRendererComponent,
          safeTextRendererComponent: SafeTextRendererComponent,
          starterMobileSupportIconRendererComponent: StarterMobileSupportIconRendererComponent,
          starterWebSupportIconRendererComponent: StarterWebSupportIconRendererComponent,
          customTooltip: TooltipRenderComponent,
          cardNumberRenderer: CardNumberRendererComponent,
          cardAssignedUsernameRenderer: CardAssignedUsernameRendererComponent,
          textWithTooltipRenderer: TextWithTooltipRendererComponent,
          textWithTooltipRightRenderer: TextWithTooltipRightRendererComponent,
          multiColorTextRenderer: MultiColorTextRendererComponent,
          textTwoDecimalNumber: TextTwoDecimalNumberRendererComponent,
          clickToSetupRenderer: ClickToSetupRendererComponent,
          toggleRenderer: ToggleRendererComponent,
          toggleRendererSecond: ToggleRendererSecondComponent,
          textBoxRenderer: TextboxRendererComponent,
          textRenderer: TextRendererComponent,
          checkboxInGrid: CheckboxIngridRendererComponent,
          textWithTooltipRenderer2: TextWithTooltipRenderer2Component,
          ngSelectRenderer: NgSelectRendererComponent,
          buttonRenderer: ButtonsRendererComponent,
          errorsCountRenderer: ImportErrorsCountRendererComponent,
          approverRenderer: ApproverRenderer,
          formattedTextRenderer: FormattedTextRenderer,
          defaultMsgRenderer: DefaultMessageRendererComponent,
          suspensionMsgRenderer: SuspensionMessageRendererComponent,
          textWithDescriptionRenderer: TextWithDescriptionRendererComponent,
          approvalWithDataRenderer: ApprovalDataRendererComponent,
          infoStepRenderer: InfoStepRendererComponent,
          privilegeRendererComponent: PrivilegeCountRendererComponent,
          statusDataRender: StatusDataRenderComponent,
          gridNgSelectOrTextRender: GridNgSelectOrTextRendererComponent,
          gridTextBoxOrTextRender: GridTextBoxOrTextRendererComponent,
          checkBoxwithTextRender: CheckBoxwithTextRenderComponent,
          percentageRenderer: PercentageRendererComponent,
          discountRenderer: DiscountRendererComponent,
          gridTextBoxRenderer: GridTextBoxRendererComponent,
          progressBarRenderer: ProgressBarRendererComponent,
          countWithCheckBoxRenderer: CountWithCheckBoxRendererComponent,
          ngSelectDescriptionRenderer: NgSelectDescriptionRendrerComponent,
          resultRenderer: ResultRendererComponent,
          gridDropdownRenderer: GridDropdownRendererComponent,
          twoDecimalNumberInputRenderer: TwoDecimalNumberInputRendererComponent,
          actionButtonsRenderer: ActionButtonsRendererComponent,
          fxRateRenderer : RateRendererComponent,
          countLinkRenderer: CountLinkRendererComponent,
          translatedTextTenderer: TranslatedTextTendererComponent,
          radioRenderer: RadioRendererComponent,
          multipleButtonRenderer:MultipleButtonRendererComponent,
          statusSupportingTextRenderer: StatusSupportingTextRendererComponent,
          customActionsRenderer: CustomActionsRendererComponent,
          gridToggleRenderer: GridToggleRendererComponent,
          multipleCardNumberRenderer: MultipleCardNumberRendererComponent,
          viewRenderer: ViewRendererComponent,
          dayPolicyRenderer: DayPolicyRenderer
        },
        suppressCellFocus: self.gridConfigItems.suppressCellSelection,
        suppressRowClickSelection: self.gridConfigItems.suppressRowClickSelection,
        defaultColDef: {
          flex: 1,
          sortable: true,
          resizable: true,
          filter: 'agTextColumnFilter',
          autoHeight: true

        },
        //tooltipMouseTrack: true,
        tooltipShowDelay: 0,
        suppressRowTransform: true,
        // getRowNodeId: (params) => {
        //   if (params) {
        //     return params[self.gridConfigItems.rowKey].toString();
        //   }
        //   else {
        //     return '';
        //   }
        // },
        rowClassRules: {
          'disabled': function (params) { return params && params.data && params.data.isDisabled; },

          'cursor-pointer': function (params) {
            return !self.gridConfigItems.suppressRowClickSelection;
          },
          'row-highlight': function (params) {
            return params && params.data && params.data.isRowHighlighted;
          },
          'ag-row-highlight-even': function (params) {
            if (params && params.data && params.data.isRowHighlighted) {
              return false;
            }
            else {
              if (params && params.data && !params.data.customeRowClass)
                return params.node.rowIndex % 2 === 0;
            }
          },
          'ag-row-highlight-odd': function (params) {
            if (params && params.data && params.data.isRowHighlighted) {
              return false;
            }
            else {
              if (params && params.data && !params.data.customeRowClass)
                return params.node.rowIndex % 2 != 0;
            }
          },
          ...self.gridConfigItems.rowClassRules,
        },
        columnDefs: this.columnDefs
      };

      this.setWidthAndHeight();
    }
  }

  public updateGridColumnDefinitions(columnDefs) {
    if (this.gridApi) {
      if (columnDefs && columnDefs.length > 0) {
        this.columnDefs = columnDefs;
        this.fnAddCheckboxColumn(columnDefs);
      }

      this.columnDefs.forEach(columnDef => {
        columnDef.tooltipComponent = 'customTooltip';
        columnDef.tooltipField = columnDef.field;
        if (!columnDef.headerComponentParams) {
          columnDef.headerComponentParams = {};
        }
        columnDef.headerComponentParams.rowCount = this.allGridData.length;
      });

      this.gridApi.setColumnDefs([]);
      this.gridApi.setColumnDefs(this.columnDefs);

    }
  }

  public fnAddCheckboxColumn(columnDefs) {
    let self = this;
    if (self.gridConfigItems.enableRowSelection) {
      if (!this.columnDefs.find(c => c.field == 'selected')) {
        const cbxColumn = {
          headerName: "",
          field: "selected",
          cellRenderer: 'cbxRenderer',
          sortable: false,
          resizable: false,
          maxWidth: 60,
          pinned: true,
          headerComponentParams: {
            isCheckbox: true,
            isAllChecked: false,
            isCheckAllAllowed: self.gridConfigItems.isCheckAllAllowed,
            onCheckAll: (event) => this.onAllChecked(event.target.checked)
          },
          suppressMovable: true,
          lockPosition: true,
          lockPinned: true,
          tooltipField: 'selected',
        };
        this.columnDefs.unshift(cbxColumn);
      }
    }
  }


  public refreshGridData() {
    this.overlayNoRowsTemplate = `
    <div class="text-center noDataAvailable w-100pc f-16 position-relative">
        ${this.translate.instant(this.gridConfigItems.noDataMessage)}
      </div>
    `;
    if (this.gridApi) {
      this.isDataLoading = false;
      this.isRefresh = true;
      this.resetSortModel();
      this.resetDataSource();

      this.columnDefs.forEach(columnDef => {
        if (!columnDef.headerComponentParams) {
          columnDef.headerComponentParams = {};
        }
        columnDef.headerComponentParams.rowCount = this.allGridData.length;
      });
      this.updateGridColumnDefinitions(null);
    }
    else {
      this.initializeGrid();
    }
    if(this.gridConfigItems.headerHeight)
    {
      this.header_MIN_HEIGHT = this.gridConfigItems.headerHeight;
      this.setHeaderHeight(this.header_MIN_HEIGHT);
    }
  }

  public resetSortModel() {
    this.sortGrid(this.gridConfigItems.defaultSortColumn, this.gridConfigItems.defaultSortOrder);
  }

  public setSortModel(sortColumn, sortDirection) {
    this.sortGrid(sortColumn, sortDirection);
  }

  public unsetRowHighlighted(data) {
    let self = this;
    if (data) {
      data.forEach(element => {
        const row = self.gridApi.getRowNode(element[self.gridConfigItems.rowKey].toString());
        if (row) {
          row.setData(element);
        }
      });
    }
  }

  public applyFilters(filters) {
    if (filters && Object.keys(filters).length > 0) {
      this.filters = filters;
    }
    else {
      this.filters = null;
    }
    this.refreshData();
  }

  public fnAppendAllDataInGrid(gridDataForAppend) {
    this.allGridData = this.allGridData.concat(gridDataForAppend);
    this.allGridData = this.sortByKey(this.allGridData, this.gridSortColumn, this.gridSortOrder);
    this.refreshData();
  }

  public removeRow(data) {
    const rowNode = this.gridApi.getRowNode(data[this.gridConfigItems.rowKey].toString());
    this.gridData.splice(rowNode.rowIndex, 0, rowNode);
    this.gridApi.refreshInfiniteCache();
  }

  public uncheckItems(checkedItemIds) {
    if (this.gridData && this.gridData.length > 0) {
      const checkedRows = this.gridData.filter(g => checkedItemIds.indexOf(g[this.gridConfigItems.rowKey]) > -1);
      for (let i = 0; i < checkedRows.length; i++) {
        checkedRows[i].selected = false;
      }
      this.checkUncheckAll();
    }
  }

  public checkItems(checkedItemIds) {
    if (this.gridData && this.gridData.length > 0) {
      const checkedRows = this.gridData.filter(g => checkedItemIds.indexOf(g[this.gridConfigItems.rowKey]) > -1);
      for (let i = 0; i < checkedRows.length; i++) {
        checkedRows[i].selected = true;
      }
      this.checkUncheckAll();
    }
  }

  checkUncheckAll() {
    if (this.gridApi && this.gridConfigItems.enableRowSelection) {
      if (this.gridData && this.gridData.length > 0) {
        let checkedcount = this.gridData.filter(r => r.selected).length;
        if (checkedcount == this.gridData.length) {
          this.isAllChecked = true;
        }
        else {
          this.isAllChecked = false;
        }
        // this.gridApi.refreshHeader();
        this.refreshCheckbox();
      }
    }
  }

  public refreshCheckbox() {
    if (this.gridApi) {
      if (this.gridConfigItems.enableRowSelection) {
        var cbxColumn = this.columnDefs.find(c => c.field == 'selected');
        if (cbxColumn) {
          cbxColumn.headerComponentParams.isCheckAllAllowed = this.gridConfigItems.isCheckAllAllowed;
          cbxColumn.headerComponentParams.isAllChecked = this.isAllChecked;
        }
        this.updateGridColumnDefinitions(null);
      }
    }
  }

  public uncheckAll() {
    if (this.gridData) {
      for (let i = 0; i < this.gridData.length; i++) {
        this.gridData[i].selected = false;
      }
      this.checkUncheckAll();
    }
  }

  setDataInGrid(data) {
    if (data) {
      const rowNode = this.gridApi.getRowNode(data[this.gridConfigItems.rowKey].toString());
      const newData = data;
      rowNode.setData(newData);
      if (data.IsRowOnTop) {
        this.allGridData.splice(rowNode.rowIndex, 1);
        this.allGridData.splice(0, 0, rowNode.data);
      }
      else {
        this.allGridData.splice(rowNode.rowIndex, 1, rowNode.data);
      }
      this.refreshData();
    }
  }

  reorderUpdatedRecords(dataArray: any[]) {
    dataArray.forEach(data => {
      const rowNode = this.gridApi.getRowNode(data[this.gridConfigItems.rowKey].toString());
      const newData = data;
      rowNode.setData(newData);
      this.allGridData.splice(rowNode.rowIndex, 1);
      this.allGridData.splice(0, 0, rowNode.data);
    });
    this.refreshData();
  }

  onGridSearchTextChange(searchText) {
    this.searchText = searchText;
    this.refreshData();
  }

  statusChange(value, item) {
    if (value !== '') {
      this.filterChanged.emit(value);
    }
    else {
      if (item.isClickEvent) {
        this.filterChanged.emit(item.value);
      }
    }
  }

  oncolumnResized(params) {
    this.autosizeHeaders(params);
  }

  onCellClicked(params) {
    if (params.column.colId === 'selected') {
      if (params.data.isCheckboxDisabled) {
        return;
      }
      params.data.selected = params.event.target.checked;
      this.checkUncheckAll();
    }
    this.onCellClick.emit(params);
  }

  onAllChecked(isChecked) {
    for (let i = 0; i < this.allGridData.length; i++) {
      if (!this.searchText && !this.filters) {
        if (!this.allGridData[i].isEditDisabled && !this.allGridData[i].isCheckboxDisabled) {
          this.allGridData[i]['selected'] = isChecked;
        }
      }
      if (this.gridData.length > i) {
        if (!this.gridData[i].isEditDisabled && !this.gridData[i].isCheckboxDisabled) {
          this.gridData[i]['selected'] = isChecked;
        }
      }
    }
    this.isAllChecked = isChecked;
    this.checkUncheckAll();
    this.onCheckAll.emit(isChecked);
  }

  refreshData() {
    if (this.gridApi) {
      this.isDataLoading = false;
      if (this.gridlastRowIndex > 0) {
        this.gridApi.refreshInfiniteCache();
      }
      else {
        this.resetDataSource();
      }
    }
  }

  resetDataSource() {
    this.gridApi.setDatasource(this.getDataSource());
  }

  onDataLoadStart() {
    this.gridApi.showLoadingOverlay();
  }

  onGridReady(params: GridOptions) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.resetSortModel();

    this.gridApi.setDatasource(this.getDataSource());
  }

  private getDataSource(): IDatasource {
    return {
      getRows: (params: IGetRowsParams) => {
        this.getRowData(params).subscribe(data => {
          this.gridlastRowIndex = 0;
          if (this.searchText || this.filters || this.advanceSearchAlphabet) {
            this.gridlastRowIndex = this.gridData.length;
          }
          else {
            this.gridlastRowIndex = this.allGridData.length;
          }

          if (this.gridlastRowIndex > 0) {
            this.isDataLoading = false;
            this.gridApi.hideOverlay();
          }
          else if (this.isDataLoading) {
            this.gridApi.showLoadingOverlay();
          }
          else {
            this.gridApi.showNoRowsOverlay();
          }
          params.successCallback(data, this.gridlastRowIndex)
        });
      }
    };
  }

  private getRowData(params: IGetRowsParams) {
    this.sortAllRows(params);
    this.gridData = [];
    if (this.searchText || this.filters || this.advanceSearchAlphabet != '') {
      this.gridData = this.filterRows();
    }
    else {
      this.gridData = this.allGridData;
    }
    const rowsForBlock = this.gridData.slice(params.startRow, params.endRow);
    return of(rowsForBlock);
  }

  private sortAllRows(params) {
    if (params.sortModel && params.sortModel.length > 0) {
      if (this.gridSortColumn != params.sortModel[0].colId
        || this.gridSortOrder != params.sortModel[0].sort
        || this.isRefresh) {
        params.startRow = 0;
        params.endRow = this.gridOptions.cacheBlockSize;
        this.gridSortColumn = params.sortModel[0].colId;
        this.gridSortOrder = params.sortModel[0].sort;
        this.allGridData = this.sortByKey(this.allGridData, this.gridSortColumn, this.gridSortOrder);
      }
    }
    else {
      if (!this.gridSortColumn || !this.gridSortOrder || this.isRefresh) {
        params.startRow = 0;
        params.endRow = this.gridOptions.cacheBlockSize;
        this.gridSortColumn = this.gridConfigItems.defaultSortColumn;
        this.gridSortOrder = this.gridConfigItems.defaultSortOrder;
        this.allGridData = this.sortByKey(this.allGridData, this.gridSortColumn, this.gridSortOrder);
      }
    }
    this.isRefresh = false;
  }

  private filterRows() {
    const rows = [];
    for (let i = 0; i < this.allGridData.length; i++) {
      for (let j = 0; j < this.columnDefs.length; j++) {
        const key = this.columnDefs[j].field;
        if (this.allGridData[i][key]) {
          let isRowVisible = false;
          if (this.searchText) {
            if (this.allGridData[i][key].toString().toLowerCase().indexOf(this.searchText.toLowerCase()) > -1) {
              isRowVisible = true;
            }
          }
          if (this.filters && this.filters[key]) {
            if (this.allGridData[i][key] == this.filters[key]) {
              isRowVisible = true;
            }
            else {
              isRowVisible = false;
            }
          }

          if (this.advanceSearchAlphabet != '') {
            if (this.allGridData[i]["contactName"].toString().toLowerCase().startsWith(this.advanceSearchAlphabet.toLowerCase())) {
              isRowVisible = true;
            }
            else {
              isRowVisible = false;
            }
          }

          if (isRowVisible) {
            rows.push(this.allGridData[i]);
            break;
          }
        }
      }


    }
    return rows;
  }

  private sortByKey(array, key, order) {
    let result;

    if (order == 'asc') {
      result = array.sort((a, b) => {
        let x = a[key]; let y = b[key];
        if (typeof x === 'string' || x instanceof String) {
          x = x ? x.toString().toLowerCase() : null;
          y = y ? y.toString().toLowerCase() : null;
        }
        return !x ? -1 : ((x < y) ? -1 : ((x > y) ? 1 : 0));
      });
    }
    else {
      result = array.sort((a, b) => {
        let x = a[key]; let y = b[key];
        if (typeof x === 'string' || x instanceof String) {
          x = x ? x.toString().toLowerCase() : null;
          y = y ? y.toString().toLowerCase() : null;
        }
        return !y ? -1 : ((x > y) ? -1 : ((x < y) ? 1 : 0));
      });
    }
    return result;
  }

  private autosizeHeaders(event) {
    if (event.finished !== false) {
      event.api.setHeaderHeight(this.header_MIN_HEIGHT);
      const headerCells = document.querySelectorAll('#myGrid .ag-header-cell-label');
      let minHeight = this.header_MIN_HEIGHT;
      headerCells.forEach(cell => {
        minHeight = Math.max(minHeight, cell.scrollHeight);
      });
      event.api.setHeaderHeight(minHeight);
      event.api.setGroupHeaderHeight(this.group_MIN_HEIGHT);
    }
  }
  public setHeaderHeight(value?: number) {
    this.gridApi.setHeaderHeight(value);
  }

  public refreshGridCells(columnList: string[]) {
    if (columnList) {
      this.gridApi.refreshCells({
        columns: columnList,
        force: true,
        suppressFlash: true
      });
    }
  }

  public redrawGridRows() {
    this.gridApi.redrawRows();
  }

  showAdvancedFilters = false;

  showAdvanceSearchPanel() {
    this.bindAdvanceFilterData();
    this.showAdvancedFilters = true;

  }

  closeAdvanceSearchPanel() {

    this.showAdvancedFilters = false;

  }


  startsWith_A = 0;
  startsWith_B = 0;
  startsWith_C = 0;
  startsWith_D = 0;
  startsWith_E = 0;
  startsWith_F = 0;
  startsWith_G = 0;
  startsWith_H = 0;
  startsWith_I = 0;
  startsWith_J = 0;
  startsWith_K = 0;
  startsWith_L = 0;
  startsWith_M = 0;
  startsWith_N = 0;
  startsWith_O = 0;
  startsWith_P = 0;
  startsWith_Q = 0;
  startsWith_R = 0;
  startsWith_S = 0;
  startsWith_T = 0;
  startsWith_U = 0;
  startsWith_V = 0;
  startsWith_W = 0;
  startsWith_X = 0;
  startsWith_Y = 0;
  startsWith_Z = 0;

  bindAdvanceFilterData() {
    this.startsWith_A = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("a")).length;
    this.startsWith_B = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("b")).length;
    this.startsWith_C = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("c")).length;
    this.startsWith_D = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("d")).length;
    this.startsWith_E = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("e")).length;
    this.startsWith_F = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("f")).length;
    this.startsWith_G = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("g")).length;
    this.startsWith_H = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("h")).length;
    this.startsWith_I = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("i")).length;
    this.startsWith_J = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("j")).length;
    this.startsWith_K = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("k")).length;
    this.startsWith_L = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("l")).length;
    this.startsWith_M = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("m")).length;
    this.startsWith_N = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("n")).length;
    this.startsWith_O = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("o")).length;
    this.startsWith_P = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("p")).length;
    this.startsWith_Q = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("q")).length;
    this.startsWith_R = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("r")).length;
    this.startsWith_S = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("s")).length;
    this.startsWith_T = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("t")).length;
    this.startsWith_U = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("u")).length;
    this.startsWith_V = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("v")).length;
    this.startsWith_W = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("w")).length;
    this.startsWith_X = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("x")).length;
    this.startsWith_Y = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("y")).length;
    this.startsWith_Z = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith("z")).length;
  }


  advanceSearchAlphabet: string = '';
  filterWithAdvanceFilter(alphabet) {
    //this.allGridData = this.allGridData.filter((x) => x.contactName.toLowerCase().startsWith(alphabet.toLowerCase()));
    //this.filterRowsWithAdvancedSearch(alphabet);
    this.advanceSearchAlphabet = alphabet;
    this.showAdvancedFilters = false;
    this.refreshData();
    //this.advanceSearchChanged.emit(alphabet);

  }


  clearAdvanceSearch() {
    this.advanceSearchAlphabet = '';
    this.showAdvancedFilters = false;
    this.refreshData();
  }

  onDataFilterChange(value, item) {
    if (value !== '') {
      this.selectedFilter = value;
      this.dataFilterChanged.emit(value);
    }
    else {
      if (item.isClickEvent) {
        this.dataFilterChanged.emit(item.value);
      }
    }
  }

}

function setIdText(id: string, value: string | number | undefined) {
  if(document.getElementById(id))
  {
    document.getElementById(id)!.innerHTML =
    value == undefined ? 'undefined' : value + '';
  }
  
}
