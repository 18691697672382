import { OnInit, Component, ViewChild, Output, EventEmitter, Input } from "@angular/core";
import { Subscription } from "rxjs";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { TranslateService } from "@ngx-translate/core";
import { Claims } from "src/app/_models/common/claims";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { AlertService } from "src/app/alert-panel/alert.service";
import { ApiRequest, IApiresponse, MileageUOMEnum, JourneyTypeEnum } from "src/app/_models";
import { NgxUiLoaderService } from "ngx-ui-loader";
import {
  CalculateMileageResponse, MultipleLegsPopupModel, MileageAllowance,
  IResourceMileageDetailsResponse, MileageData, CalculateMileageRequest,
  Mileage
} from "src/app/_models/claimant-on-web/claimant-on-web";
import { AlertPanelModel } from 'src/app/_models/common/alertPanelModel';
import { ClaimantOnWebService } from "src/app/_services/claimant-on-web/claimant-on-web.service";
import { AppGridComponent } from "src/app/shared/grid/grid-component/grid.component";
import { ColDef } from "ag-grid-community";
import { GridConfig } from "src/app/_models/common/grid/grid-config";
import { ViewReceiptComponent } from "src/app/shared/panels/view-receipt/view-receipt.component";
import { BaseCategoryTypeEnum } from "../../../../_models";
import { KeyObject } from "crypto";

@Component({
  selector: "multiple-legs-panel",
  templateUrl: "./multiple-legs-panel.component.html"
})
export class MultipleLegsPanelComponent implements OnInit {
  @ViewChild(AppGridComponent) gridComponent: AppGridComponent;
  @ViewChild('viewReceiptComponent') viewReceiptComponent: ViewReceiptComponent;

  @Input() inputModel: MultipleLegsPopupModel;
  @Input() mileageAllowance: MileageAllowance[];
  @Input() resourceMileageDetails: IResourceMileageDetailsResponse;
  @Input() vehicleResponse: any;
  @Input() expenseModel: any;
  @Input() isCarbonFootprintReporting : boolean;

  @Output() closePanelEvent = new EventEmitter<boolean>();
  @Output() submitEvent = new EventEmitter<any>();

  claimsSubscription: Subscription;
  userActionEventSubscription: Subscription;
  claims: Claims;
  hasFormChanges: boolean;
  isMileageCategory : boolean;
  gridData: any[] = [];
  gridColumnDefs: ColDef[];
  gridConfigItems: GridConfig;
  gridFilterStatus: number = 0;
  defaultSortColumn: string = 'sequence';
  defaultSortOrder: string = 'asc';

  disableSaveButton: boolean = false;
  mileageUOMText: string;
  selectedMileageUniqueId: number;
  calculateMileageDetails: CalculateMileageResponse;

  showAddJourneyPanel: boolean = false;
  addJourneyInputModel: MultipleLegsPopupModel;

  showDeleteConfirmationAlert: boolean = false;
  deleteConfirmationAlertModel: AlertPanelModel;

  showOneAddJourneyAlert: boolean = false;
  oneAddJourneyAlertModel: AlertPanelModel;

  //View Receipts
  milesTravelled: any;
  triangulationMiles: any;
  detourMiles: any;
  receiptAmount: number;
  distance: any;
  mileageRate: string;
  mileageArray: any;
  panelHeader : string;
  legsCount:number;
  carbonEmissionText:string;
  isNotMileageCategory: boolean;
  constructor(
    private claimsService: ClaimsService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private alertService: AlertService,
    private ngxService: NgxUiLoaderService,
    private claimantOnWebService: ClaimantOnWebService,
  ) {
    this.claimsSubscription = this.claimsService.currentClaims.subscribe((claims) => {
      this.claims = claims;
    });
    this.fnObserveUnsavedChangesAlerts();
  }

  ngOnInit(): void {
    this.fnGetPanelHeader(this.isCarbonFootprintReporting);
    if (this.inputModel) {
      this.mileageUOMText = this.inputModel.mileageUOM == MileageUOMEnum.Kilometre ? this.translate.instant("text_km") : this.translate.instant("text_m");
      this.carbonEmissionText = this.translate.instant('text_carbonemission');
      this.isMileageCategory = this.inputModel.baseCategoryId == BaseCategoryTypeEnum.MileageGoogle;
      this.isNotMileageCategory = !(this.inputModel.baseCategoryId == BaseCategoryTypeEnum.MileageGoogle
        || this.inputModel.baseCategoryId == BaseCategoryTypeEnum.Mileage ||
        this.inputModel.baseCategoryId == BaseCategoryTypeEnum.MileageGPS)
      this.fnInitialiseGrid();
      this.gridData = this.inputModel.multipleLegList;
    }
    this.fnRefreshMultipleLegsDetails();
  }

  fnGetPanelHeader(isCarbonFootprintReporting)
  {
    switch(isCarbonFootprintReporting)
    {
      case false : this.panelHeader = this.translate.instant('multiple_legs');
                   this.legsCount = 12;
                   break;
      case true : this.panelHeader = this.translate.instant('carbon_emission_footprint_details');
                  this.legsCount = 5;
                  break;
    }   

    if(this.inputModel.baseCategoryId == BaseCategoryTypeEnum.MileageGoogle
      || this.inputModel.baseCategoryId == BaseCategoryTypeEnum.Mileage ||
      this.inputModel.baseCategoryId == BaseCategoryTypeEnum.MileageGPS)
    {  this.panelHeader = this.translate.instant('multiple_legs');
       this.legsCount = 12;
    }
  }

  fnObserveUnsavedChangesAlerts() {
    this.userActionEventSubscription = this.alertService.getUserActionEvent().subscribe(action => {
      if (action) {
        this.closePanelEvent.emit(true);
      }
    });
  }

  canDeactivate(): boolean {
    return !this.hasFormChanges;
  }

  fnClosePanel() {
    if (this.canDeactivate()) {
      this.closePanelEvent.emit(true);
    }
    else {
      this.alertService.onFormClosed();
    }
  }

  fnInitialiseGrid() {
    this.fnCreateColumnDefs();
    this.fnGetGridConfigItems();
    setTimeout(() => {
      this.gridComponent.initializeGrid();
    }, 100);
  }

  fnCreateColumnDefs() {
    this.gridColumnDefs = [
      {
        headerName: this.translate.instant('text_leg'),
        field: 'sequence',
        resizable: true,
        suppressMenu: true,
        minWidth: 25
      },
      {
        headerName: this.translate.instant('from_location'),
        field: 'fromAddress',
        resizable: true,
        suppressMenu: true,
        minWidth: 250
      },
      {
        headerName: this.translate.instant('to_location'),
        field: 'toAddress',
        resizable: true,
        suppressMenu: true,
        minWidth: 250
      },
      {
        headerName: `${this.translate.instant('text_distance')} (${this.mileageUOMText})`,
        field: 'distance',
        resizable: true,
        suppressMenu: true,
        minWidth: 100,
        headerClass: 'text-right',
        cellClass: 'text-right',
        cellRenderer: 'amountRenderer'
      }]
      if(this.isCarbonFootprintReporting)
      {
        this.gridColumnDefs.push(
        {
          headerName: `${this.translate.instant('carbon_emission')} (${this.carbonEmissionText})`,
          field: 'carbonFootprint',
          resizable: true,
          suppressMenu: true,
          minWidth: 100,
          headerClass: 'text-right',
          cellClass: 'text-right',
          cellRenderer: 'amountRenderer'
        });
      }
      if(this.expenseModel.baseCategoryId == BaseCategoryTypeEnum.MileageGoogle)
      {
        this.gridColumnDefs.push(
          {
            headerName: `${this.translate.instant('detour')} (${this.mileageUOMText})`,
            field: 'detour',
            resizable: true,
            suppressMenu: true,
            minWidth: 100,
            headerClass: 'text-right',
            cellClass: 'text-right',
            cellRenderer: 'amountRenderer'
          },
          {
            headerName: this.translate.instant('text_passenger_load'),
            field: 'allowanceDescription',
            resizable: true,
            suppressMenu: true,
            minWidth: 150
          },
          {
            headerName: this.translate.instant('label_notes'),
            field: 'notes',
            resizable: true,
            suppressMenu: true,
            minWidth: 100
          });
        }

        this.gridColumnDefs.push({
            headerName: "",
            valueGetter: 'data.sequence',
            cellRenderer: 'actionRenderer',
            field: 'userActionField',
            resizable: true,
            suppressMenu: true,
            minWidth: 100,
            headerComponentParams:
              [
                {
                  buttonClass: 'fas fa-level-down-alt fa-rotate-90  top-2px position-relative text-logo-blue',
                  buttonTooltip: this.translate.instant('text_return'),
                  userAction: "return",
                  hideProperty: "hideJourney",
                },
                {
                  buttonClass: 'fas fa-edit color-green',
                  buttonTooltip: this.translate.instant('label_edit'),
                  userAction: "edit"
                },
                {
                  buttonClass: 'fal fa-trash-alt color-red',
                  buttonTooltip: this.translate.instant('text_delete'),
                  userAction: "delete",
                },

              ]
          });         
  }

  fnGetGridConfigItems() {
    this.gridConfigItems = new GridConfig(
      true,
      true,
      this.defaultSortColumn,
      "sequence",
      this.gridFilterStatus,
      false,
      [],
      true,
      true,
      false,
      null,
      this.defaultSortOrder,
      40,
      this.translate.instant('no_data_availabe'),
      null,
      null,
      false,
      false,
      '',
      '',
      false
    );
  }

  fnMapGridList() {
    this.gridData = this.gridData.map(item => {

      //show return only in last
      if (item.sequence == this.gridData.length && item.sequence < this.legsCount) {
        item.hideJourney = false;
      }
      else {
        item.hideJourney = true;
      }
      //map some objects
      item.allowanceDescription = item.mileageAllowance?.allowanceDescription;
      item.fromAddress = item.fromLocation?.location;
      item.toAddress = item.toLocation?.location;
      return item;
    });
  }

  fnOnGridRefresh() {
    this.fnMapGridList();

    this.fnGetGridConfigItems();

    setTimeout(() => {
      this.gridComponent.refreshGridData();
    }, 100);
  }

  fnRefreshMultipleLegsDetails() {
    //step 1
    this.fnRearranceLegsSequence();
    //step 2
    this.fnRearrangeLegsAddress();
    //step 3
    this.fnCalculateMileageMultipleLeg();
  }

  fnRearranceLegsSequence() {
    let sequence = 1;
    this.gridData.forEach((row) => {
      if (row) {
        row.sequence = sequence;
        sequence = sequence + 1;
      }
    });
  }

  fnRearrangeLegsAddress() {
    if (this.gridData && this.gridData.length > 1) {
      this.gridData.forEach((row, index) => {
        if (row && index > 0) {
          let previousAddress = this.gridData.find(item => item.sequence == row.sequence - 1);
          let nextAddress = this.gridData.find(item => item.sequence == row.sequence + 1);
          row.fromLocation = previousAddress ? previousAddress.toLocation : row.fromLocation;
          row.toLocation = nextAddress ? nextAddress.fromLocation : row.toLocation;
        }
      });
    }
  }

  fnCalculateMileageMultipleLeg() {

    // Start Loader

    this.ngxService.start();

    let vehicleId = 0;

    let defaultVehicle = this.vehicleResponse.find(it => it.isDefault);
    if (defaultVehicle) {
      vehicleId = defaultVehicle.vehicleId
    }

    let request = new ApiRequest<CalculateMileageRequest>("CalculateMileageMultipleLeg", environment.applicationVersion, environment.requestId);
    let requestInfo = new CalculateMileageRequest();
    requestInfo.baseCategoryId = this.expenseModel.baseCategoryId;
    requestInfo.orgExpenseCategoryId = this.expenseModel.orgExpenseCategoryId;
    requestInfo.journeyType = JourneyTypeEnum.Multiple;
    let vehicleDetail = this.vehicleResponse.find(it => it.vehicleId == this.expenseModel.mileage.vehicleId);
    requestInfo.orgVehicleTypeMapId = (this.inputModel.isMultipleVehiclePurchase
      ? (vehicleDetail ? vehicleDetail.orgVehicleTypeMapId : 0)
       : this.resourceMileageDetails ? this.resourceMileageDetails.vehicleDetailResponse.orgVehicleTypeMapId : 0);

    requestInfo.vehicleTypeId = (this.inputModel.isMultipleVehiclePurchase
      ? (vehicleDetail ? vehicleDetail.vehicleTypeId : 0)
       : this.resourceMileageDetails ? this.resourceMileageDetails.vehicleDetailResponse.vehicleTypeId : 0);

    requestInfo.vehicleId = (this.inputModel.isMultipleVehiclePurchase ? this.expenseModel.mileage.vehicleId : vehicleId);
    requestInfo.mileageDateDetails = this.fnCalculateMileageMultipleLegRequest();
    requestInfo.userId = this.inputModel.claimantId;
    requestInfo.orgID = this.claims.OrgId;
    request.requestInfo = requestInfo;
    this.claimantOnWebService.CalculateMileageMultipleLeg(request).subscribe((response: IApiresponse<CalculateMileageResponse>) => {
      //Stop Loader
      this.ngxService.stop();

        if (response.responseInfo != null) {
            if (response.errorInfo && response.errorInfo.length > 0) {
                this.expenseModel.disabledCalculateBtn = false;
                this.ShowGoogleServiceErrorPanel();
            }
            else {

                this.calculateMileageDetails = response.responseInfo;
                if (this.calculateMileageDetails && this.calculateMileageDetails.mileageDetailList
                    && this.calculateMileageDetails.mileageDetailList.length > 0 &&
                    this.calculateMileageDetails.mileageDetailList[0].totalTriangulatedDistance) {
                      for (let index = 0; index < this.calculateMileageDetails.mileageDetailList[0].sourceDestinationInformation.length; index++) {
                        let journey = this.calculateMileageDetails.mileageDetailList[0].sourceDestinationInformation[index];
                        journey.totalTriangulatedDistanceText = this.translate.instant("passenger_load_step_10_msg")
                            + '<span class="padding-left-0 f-12 color-red fw-700">' + this.translate.instant("passenger_load_step_11_msg")
                            + journey.triangulatedDistance.toFixed(2).toString()
                            + ' ' + (this.expenseModel.mileage.mileageUOM == MileageUOMEnum.Kilometre ? 'km' : 'm')
                            + '</span>'
                            + this.translate.instant("passenger_load_step_12_msg");
        
                    }
                }
                this.fnBindCalculatedLegsDetails();
                this.fnOnGridRefresh();
            }
        }

    });
  }

  fnCalculateMileageMultipleLegRequest() {

    let vehicleId = 0;

    let defaultVehicle = this.vehicleResponse.find(it => it.isDefault);
    if (defaultVehicle) {
      vehicleId = defaultVehicle.vehicleId
    }
    let journeyType = JourneyTypeEnum.Multiple;
    let mileage = new MileageData();
    mileage.journeyType = journeyType;
    mileage.mileageDate = new Date(this.expenseModel.expenseDate).valueOf();
    mileage.operationalInformation = null;
    mileage.vehicleId = (this.inputModel.isMultipleVehiclePurchase ? this.expenseModel.mileage.vehicleId : vehicleId);
    mileage.customMileageRate = this.resourceMileageDetails ? this.resourceMileageDetails.customMileageRate : null;
    mileage.sourceDestinationInformation = this.gridData;
    let mileageData = [];
    mileageData.push(mileage);
    return mileageData;
  }

  fnBindCalculatedLegsDetails() {
    let sourceDestinationInformation = this.calculateMileageDetails.mileageDetailList[0].sourceDestinationInformation;
    if (sourceDestinationInformation && sourceDestinationInformation.length > 0) {
      this.gridData.forEach((row, index) => {
        if (row) {
          let calculatedDetails = sourceDestinationInformation[index];
          if (calculatedDetails) {
            row.distance = calculatedDetails.distance;
            row.totalDistance = calculatedDetails.totalDistance;
            row.carbonFootprint = calculatedDetails.carbonFootprint;
          }
        }
      });
    }
  }

  fnGetTotalDistanceOflegs() {
    let totalDistance = 0;
    if (this.gridData && this.gridData.length > 0) {
      this.gridData.forEach((row) => {
        if (row) {
          if (row.totalDistance) {
            totalDistance = Number(totalDistance) + Number(row.totalDistance);
          }
        }
      });
    }
    return Number(totalDistance).toFixed(2);
  }

  fnGetTotalCarbonEmission()
  {
    let carbonFootprint = 0;
    if (this.gridData && this.gridData.length > 0) {
      this.gridData.forEach((row) => {
        if (row) {
          if (row.carbonFootprint) {
            carbonFootprint = Number(carbonFootprint) + Number(row.carbonFootprint);
          }
        }
      });
    }
    return Number(carbonFootprint).toFixed(2);
  }

  fnOnGridCellClick(params) {
    if (params.column.colId && params.column.colId == 'userActionField') {
      switch (params.data.userAction) {
        case "edit":
          this.fnEditLegDetails(params.data);
          break;
        case "delete":
          this.fnShowDeleteConfirmationAlert(params.data);
          break;
        case "return":
          this.fnOnReturnJourney(params.data);
          break;
      }
    }
  }

  fnShowDeleteConfirmationAlert(data) {
    this.deleteConfirmationAlertModel = new AlertPanelModel(
      true,
      this.translate.instant("label_alert"),
      [
        this.translate.instant("leg_delete_confirmation_note"),
      ],
      this.translate.instant("label_cancel"),
      this.translate.instant("label_confirm"),
      true,
      this.translate.instant("leg_delete_confirmation_note")
    );
    this.selectedMileageUniqueId = data.sequence;
    this.showDeleteConfirmationAlert = true;
  }

  fnDeleteConfirmationAlertReceiver(returnValue) {
    if (returnValue) {
      this.fnDeleteLeg(this.selectedMileageUniqueId)
    }
    this.selectedMileageUniqueId = 0;
    this.deleteConfirmationAlertModel = null;
    this.showDeleteConfirmationAlert = false;
  }

  fnDeleteLeg(selectedItemId) {
    this.hasFormChanges = true;
    if (selectedItemId) {
      this.gridData = this.gridData.filter(item => Number(item.sequence) != Number(selectedItemId));
      this.fnRefreshMultipleLegsDetails()
    }
  }

  fnDeleteAddJourneyPanel(selectedItemId) {
    this.fnDeleteLeg(selectedItemId);
    this.addJourneyInputModel = null;
    this.selectedMileageUniqueId = 0;
    this.showAddJourneyPanel = false;
    this.hasFormChanges = true;
  }

  fnShowAddJourneyPanel() {
    this.addJourneyInputModel = new MultipleLegsPopupModel();
    this.addJourneyInputModel = this.inputModel;
    this.addJourneyInputModel.multipleLegList = this.gridData;
    this.addJourneyInputModel.calculateMileageDetails = this.calculateMileageDetails;
    if (this.calculateMileageDetails && this.calculateMileageDetails.mileageDetailList
      && this.calculateMileageDetails.mileageDetailList.length > 0 &&
      this.calculateMileageDetails.mileageDetailList[0].totalTriangulatedDistance) {
        for (let index = 0; index < this.addJourneyInputModel.multipleLegList.length; index++) {
          let element = this.addJourneyInputModel.multipleLegList[index];
          let journey = this.calculateMileageDetails.mileageDetailList[0].sourceDestinationInformation[index];
          element.totalTriangulatedDistanceText = this.translate.instant("passenger_load_step_10_msg")
              + '<span class="padding-left-0 f-12 color-red fw-700">' + this.translate.instant("passenger_load_step_11_msg")
              + journey.triangulatedDistance.toFixed(2).toString()
              + ' ' + (this.expenseModel.mileage.mileageUOM == MileageUOMEnum.Kilometre ? 'km' : 'm')
              + '</span>'
              + this.translate.instant("passenger_load_step_12_msg");

      }
  }
    this.addJourneyInputModel.isEditMode = false;
    this.addJourneyInputModel.selectedSequence = 0;
    this.addJourneyInputModel.isReturnJourney = false;
    this.showAddJourneyPanel = true;
  }

  fnCloseAddJourneyPanel(isClose: boolean) {
    if (isClose) {
      this.addJourneyInputModel = null;
      this.selectedMileageUniqueId = 0;
      this.showAddJourneyPanel = false;
    }
  }

  fnSubmitAddJourneyPanel(data) {
    this.hasFormChanges = true;
    if (data) {
      let isAlreadyAvailable = this.gridData.find(l => l.sequence == data.sequence);
      if (isAlreadyAvailable) {
        this.gridData.map(item => {
          if (item.sequence == data.sequence) {
            item.fromLocation = data.fromLocation;
            item.toLocation = data.toLocation;
            item.notes = data.notes;
            item.sequence = data.sequence;
            item.detour = data.detour ? Number(data.detour) : 0;
            item.detourJustificationNotes = data.detourJustificationNotes;
            item.mileageAllowance = data.mileageAllowance;
            item.tagList = data.tagList;
            item.carbonFootprint = data.carbonFootprint;
          }
        });
      }
      else {
        this.gridData.push(data);
      }
    }
    this.addJourneyInputModel = null;
    this.selectedMileageUniqueId = 0;
    this.showAddJourneyPanel = false;
    this.fnRefreshMultipleLegsDetails();
  }

  fnSaveMultiplLegDetails() {
    if (this.gridData.length == 0) {
      this.fnShowOneAddJourneyAlert();
    }
    else {
      let outputModel = new MultipleLegsPopupModel();
      outputModel.multipleLegList = this.gridData;
      outputModel.calculateMileageDetails = this.calculateMileageDetails;
      this.submitEvent.emit(outputModel);
    }
  }

  fnShowOneAddJourneyAlert() {
    this.oneAddJourneyAlertModel = new AlertPanelModel(
      true,
      this.translate.instant("label_alert"),
      [],
      "",
      this.translate.instant("text_ok"),
      true,
      this.translate.instant("add_atleast_1_leg_to_save_the_expense")
    );
    this.showOneAddJourneyAlert = true;
  }

  fnOneAddJourneyAlertReceiver(returnValue) {
    this.oneAddJourneyAlertModel = null;
    this.showOneAddJourneyAlert = false;
  }

  fnEditLegDetails(selectedData) {
    this.fnShowAddJourneyPanel();
    this.addJourneyInputModel.isEditMode = true;
    this.addJourneyInputModel.isReturnJourney = false;
    this.addJourneyInputModel.selectedSequence = selectedData.sequence;
  }

  fnOnReturnJourney(selectedData) {
    this.fnShowAddJourneyPanel();
    this.addJourneyInputModel.isEditMode = false;
    this.addJourneyInputModel.isReturnJourney = true;
    this.addJourneyInputModel.selectedSequence = selectedData.sequence;
  }

  fnViewMileageMapAndCalculations() {
    if(this.calculateMileageDetails)
    {
      this.fnOpenMap();
    }
    else
    {
      if(this.expenseModel && this.expenseModel.mileage && this.expenseModel.mileage.sourceDestinationInformation
        && this.expenseModel.mileage.sourceDestinationInformation.length > 0
        && !this.expenseModel.mileage.sourceDestinationInformation[0].route)
      {
        this.fnGetMileageMapDetail();
      }
      else
      {
        this.fnOpenMap();
      }
    }
    
  }

  fnGetMileageRateString(mileageRateInfo) {
    let str = "";
    let length = 1;
    let arraylength = mileageRateInfo.length;
    mileageRateInfo.forEach(itemMileage => {
      if (length == arraylength)
        str = str + itemMileage.mileageRate.toFixed(2) + " ";
      else
        str = str + itemMileage.mileageRate.toFixed(2) + " / " + " ";
      ++length;
    });
    return str;
  }
  showGoogleServiceErrorPopup: boolean = false;
  GoogleServiceErrorPopupModel = new AlertPanelModel(false, "", [""], "", "", true, "");
  ShowGoogleServiceErrorPanel() {

    this.GoogleServiceErrorPopupModel = new AlertPanelModel(
      true,
      this.translate.instant("label_alert"),
      [],
      '',
      this.translate.instant("text_ok"),
      true
      , this.translate.instant("google_service_down_msg")
    );
    this.showGoogleServiceErrorPopup = true;
  }
  fnGoogleServiceErrorPopupReceiver() {
    this.showGoogleServiceErrorPopup = false;
    this.GoogleServiceErrorPopupModel = new AlertPanelModel(false, "", [""], "", "", true, this.translate.instant("please_review_or_confirm"));
  }
  fnGetMileageMapDetail()
  {
    this.ngxService.start();
    let request = new ApiRequest<Mileage>("GetMileageMapDetail", environment.applicationVersion, environment.requestId);
    request.requestInfo = this.expenseModel.mileage;
    this.claimantOnWebService.GetMileageMapDetail(request)
    .subscribe((response: IApiresponse<Mileage>) => {
        if (response.responseInfo != null) {
          this.expenseModel.mileage = response.responseInfo
          this.ngxService.stop();
          this.fnOpenMap();
        }

    });
  }
  fnOpenMap()
  {
    if (this.calculateMileageDetails) {
      let mileageMultipleDateDetails = this.calculateMileageDetails.mileageDetailList[0];

      let mileageLabel = this.mileageUOMText;
      this.milesTravelled = mileageMultipleDateDetails.totalMilesTraveled.toFixed(2) + " " + mileageLabel;
      this.triangulationMiles = mileageMultipleDateDetails.totalTriangulatedDistance.toFixed(2) + " " + mileageLabel;
      this.detourMiles = mileageMultipleDateDetails.detour.toFixed(2) + " " + mileageLabel;
      this.receiptAmount = mileageMultipleDateDetails.amount;
      this.distance = mileageMultipleDateDetails.totalDistance.toFixed(2) + " " + mileageLabel;
      this.mileageRate = mileageMultipleDateDetails.mileageRate.toString() + " " + this.inputModel.claimantHomeCurrencyCode + " / " + mileageLabel;
      this.mileageArray = mileageMultipleDateDetails.sourceDestinationInformation;
      if (mileageMultipleDateDetails.mileageRateInfo != null) {
        this.mileageRate = this.fnGetMileageRateString(mileageMultipleDateDetails.mileageRateInfo) + this.inputModel.claimantHomeCurrencyCode + " / " + mileageLabel;
      }
      setTimeout(() => {
        this.viewReceiptComponent.fnshowMileagemap(this.mileageArray, JourneyTypeEnum.Multiple, true, this.inputModel.claimantHomeCurrencyCode);
      }, 100);
    }
  }


}
