import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';

@Component({
  selector: 'formly-date-format',
  templateUrl: "./formly-date-format.component.html"
})
export class FormlyDateFormatComponent extends FieldType implements OnInit, OnDestroy {

  isFormSubmitted: boolean = false;
  formSubmissionSubscription: Subscription;
  fieldKey: string;

  dateFormats = [
    "dd/MM/yy",
    "dd/MM/yyyy",
    "MM/dd/yy",
    "MM/dd/yyyy",
    "yy/MM/dd",
    "d/MM/yyyy",
    "yyyy/MMM",
    "yyyy-MM-dd",
    "MM-dd-yy",
    "MM-dd-yyyy",
    "dd-MM-yy",
    "dd-MM-yyyy",
    "dd-MMM-yy",
    "dd-MMM-yyyy",
    "dd.MM.yy",
    "dd.MM.yyyy",
    "yy.MM.dd",
    "MMM dd, yy",
    "MMM dd, yyyy",
    "ddMMyy",
    "yyMMdd",
    "ddMMyyyy",
    "yyyyMMdd",
    "EEEE, dd/MMM/yyyy",
  ];

  selectedDate: string ="";

  constructor(private dynamicFormService: DynamicFormService) {
    super();
  }

  ngOnInit() {
    if (this.field.key) {
      this.fieldKey = this.field.key.toString();
    }
    this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
      this.isFormSubmitted = data;
    });

    this.fnGetSelectedFormatOnTop();
  }

  ngOnDestroy() {
    if (this.formSubmissionSubscription) {
      this.formSubmissionSubscription.unsubscribe();
    }
  }

  fnSetDateFormat(item) {
    this.formControl.setValue(item);
    if (this.to.click) {
        this.to.click(this, item);
    }
  }

  fnGetSelectedFormatOnTop() {
    if (this.formControl?.value) {
      let selectedFormat = this.formControl.value;
        const sortedArr = this.dateFormats.reduce((acc, element) => {
            if (element == selectedFormat) {
                return [element, ...acc];
            }
            return [...acc, element];
        }, []);
        this.dateFormats = sortedArr;
    }
}

}