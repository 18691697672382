
export class Commonvariables {
    static Configuration = class {
        static emailPattern =
            "^[a-zA-Z0-9+]+(?:.*_*[a-zA-Z0-9+]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$";
        static emailPattern2 =
            /^(?!.*\.{2})([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+([\.][a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*)@((([\-]?[a-zA-Z0-9]){2,}[\.])*(([a-zA-Z0-9][\-]?))+).(([\.]([a-zA-Z0-9][\-]?){2,}([a-zA-Z0-9])*)+)$/;
    };
}

export const BackgroundTaskTopics = {
    Billing: "billing",
    Expense: "expense",
    CataLog:"catalog",
    Notification: "notification",
    Export: "export",
    GradeImport: "gradeimport"
};

export const BackgroundTaskKeys = {
    BulkInviteEmployees: 'BulkInviteEmployees',
    BulkApprovalAction: 'BulkApprovalAction',
    BulkUniversalImportEmployees: 'BulkUniversalImportEmployees',
    BulkExportExpensesAction: 'BulkExportExpensesAction',
    BulkUpdateExpenseApprovers: 'BulkUpdateExpenseApprovers',
    BulkCostCentreImport: 'BulkCostCentreImport',
    BulkUniversalCostCentreApprovers: 'BulkUniversalCostCentreApprovers',
    BulkUniversalAdvanceClaimantPolicy: 'BulkUniversalAdvanceClaimantPolicy',
    BulkImportXeroContacts: "BulkImportXeroContacts",
    BulkUniversalImportCustomers: "BulkUniversalImportCustomers",
    BulkUniversalImportCounterApprover:"BulkUniversalImportCounterApprover",
    BulkImportEmployees: 'BulkImportEmployees',
    BulkCreditCardExpensesImport: "BulkCreditCardExpensesImport",
    BulkCompanyAssignToEmployee: "BulkCompanyAssignToEmployee",
    BulkUniversalImportCategories:'BulkUniversalImportCategories',
    BulkUniversalImportFxRate:'BulkUniversalImportFxRate',
    XeroTokenExpiryNotification : "XeroTokenExpiryNotification",
    BulkInviteVendors: 'BulkInviteVendors',
    BulkImportAssignCreditCard: "BulkImportAssignCreditCard",
    BulkImportGrades:'BulkImportGrades',
}

export const BackgroundTaskNotificationHeaders = {
    BulkInviteEmployees: 'ManageEmployeeImportingHeader',
    BulkApprovalAction: 'FinanceApprovingExpensesHeader',
    BulkApprovalActionApprover: 'ApproverApprovingExpensesHeader',
    BulkExportExpensesAction: 'ExportExpensesHeader',
    BulkUniversalImportEmployees: 'BulkUniversalImportEmployeesHeader',
    BulkCostCentreImport: 'BulkCostCentreImportHeader',
    BulkUniversalCostCentreApprovers: 'BulkUniversalCostCentreApproversHeader',
    BulkUniversalAdvanceClaimantPolicy: 'BulkUniversalClaimantAdvancePolicyHeader',
    BulkUniversalImportCustomers: 'BulkUniversalImportCustomersHeader',    
    BulkImportEmployees: 'BulkImportEmployeesHeader',
    BulkCreditCardExpensesImport: 'BulkCreditCardExpensesImportHeader',
    BulkCompanyAssignToEmployee: 'BulkCompanyAssignToEmployeeHeader',
    BulkUniversalImportCategories: 'BulkUniversalImportCategoriesHeader',
    BulkUniversalImportCounterApprover: "BulkUniversalImportCounterApproverHeader",
    BulkUniversalImportFxRate: "BulkUniversalImportFxRateHeader",
    XeroTokenExpiryNotification : "Xero Token Expiry Notification",
    BulkInviteVendors: 'VendorImportingHeader',
    BulkImportAssignCreditCard: 'BulkImportAssignCreditCardHeader',
    BulkImportGrades: 'BulkImportGradesHeader'
}

export const BackgroundTaskNotificationBody = {
    BulkInviteEmployees: "ManageEmployeeImportingBody",
    BulkUpdateEmployees: "ManageEmployeeUpdatingBody",
    BulkApprovalAction: "FinanceApprovingExpensesBody",
    BulkApprovalActionApprover: "ApproverApprovingExpensesBody",
    BulkExportExpensesAction: "ExportExpensesBody",
    BulkUniversalImportEmployees: "BulkUniversalImportEmployeesBody",
    BulkUpdateExpenseApprovers: "BulkUpdateExpenseApproversBody",
    BulkCostCentreImport: "BulkCostCentreImportBody",
    BulkUniversalCostCentreApprovers: "BulkUniversalCostCentreApproversBody",
    BulkUniversalAdvanceClaimantPolicy: "BulkUniversalClaimantAdvancePolicyBody",
    BulkUniversalImportCustomers: "BulkUniversalImportCustomersBody",
    BulkImportEmployees: "BulkImportEmployeesBody",
    BulkCompanyAssignToEmployee: "BulkCompanyAssignToEmployeeBody",
    BulkCreditCardExpensesImport: "BulkCreditCardExpensesImportBody",
    BulkUniversalImportCategories: 'BulkUniversalImportCategoriesBody',
    BulkUniversalImportCounterApprover: "BulkUniversalImportCounterApproverBody",
    BulkUniversalImportFxRate: "BulkUniversalImportFxRateBody",
    BulkInviteVendors: "VendorsImportingBody",
    BulkUpdateVendors: "VendorsUpdatingBody",
    BulkImportAssignCreditCard: "BulkImportAssignCreditCardBody",
    BulkImportGradeBody: 'BulkImportGradeBody',
};

export const storeURLs = {
    playStore:
        "https://play.google.com/store/apps/details?id=com.expenseondemand.soloexpenses",
    appStore: "https://apps.apple.com/in/app/expenseondemand-for-smb/id643685343",
    website: "https://www.expenseondemand.com/",
};

export const socialMediaLinks = {
    facebook:
        "http://www.facebook.com/pages/Solo-Expenses/597280680311359?fref=ts",
    linkedIn: "https://www.linkedin.com/company/4830988",
    twitter: "https://twitter.com/soloexpenses",
    youtube: "https://www.youtube.com/channel/UCY3WsQPGK84vCqNjzYuRukw",
};

export const privacyPolicyURl =
    "../ExpenseOnDemand-SMB-Version-Privacy-Policy-V2.pdf";
export const termsOfServiceURl = "../ExpenseOnDemand-SMB-TOS-V2.pdf";

export const HowDoIVideoLinks: any = {
    SetupOrganisation:
        "https://www.youtube.com/embed?list=PLugzlMICZR8hcA-cqHUPsYv_BSywbq1Jj",
    CurrencySetup:
        "https://www.youtube.com/embed?list=PLugzlMICZR8hVIC09-KTCOQXyuNxjooE4",
    DeleteTestData:
        "https://www.youtube.com/embed?list=PLugzlMICZR8jCNQAw9AqnlYjbwYpnvTXp",
    ManageEmployees:
        "https://www.youtube.com/embed?list=PLugzlMICZR8iepD3e_NdmiBl8C6TjA33P",
    AssignApprovers:
        "https://www.youtube.com/embed?list=PLugzlMICZR8htXq_SlLwmdjUQpMmUgpLu",
    ConfigureApproverRules: "https://www.youtube.com/embed?list=PLugzlMICZR8jd_goUXDkTjo5kUNCUy7Nw",
    AssignApproversToClaimants: "https://www.youtube.com/embed?list=PLugzlMICZR8htXq_SlLwmdjUQpMmUgpLu",
   // AssignApproversToCostCentre: "https://www.youtube.com/embed?list=PLugzlMICZR8jd_goUXDkTjo5kUNCUy7Nw",
    SetupBasicMileage:
        "https://www.youtube.com/embed?list=PLugzlMICZR8gvmBbMWdutPPhNA-6Bsrpd",
    YesPayBizPortal:
        "https://www.youtube.com/embed?list=PLugzlMICZR8h-Psh1p9IHyPLzBr9vVUXk",
    // FMOneStepApproval: "https://player.vimeo.com/video/349615133",
    // CreateExpense: "https://player.vimeo.com/video/260244296",
    // ExpenseList: "https://player.vimeo.com/video/263002780",
    // MyExpenses: "https://player.vimeo.com/video/263001666",
    // OrganisationExpenses: "https://player.vimeo.com/video/328398856",
    PreApprovals:
        "https://www.youtube.com/embed?list=PLugzlMICZR8hgG0Xn1hSCL-gLOjaX2Nko",
    // PreApprovalOneStepApproval: "https://player.vimeo.com/video/354194593",
    // VendorOneStepApproval: "https://player.vimeo.com/video/349615323",
    // VendorTwoStepApproval: "https://player.vimeo.com/video/340645071",
    
    // AssignEmployeestoGrades: "https://player.vimeo.com/video/261474956",
    TwoStepApproval:
        "https://www.youtube.com/embed?list=PLugzlMICZR8iSnflUbVvgy7AzEOtrZZPe",
    // VendorManagement: "https://player.vimeo.com/video/331798946",
    SetupCostCentres:
        "https://www.youtube.com/embed?list=PLugzlMICZR8ilsHJDwjQu0tUjEcb5pjbV",
    // AssignCostCentres: "https://player.vimeo.com/video/261475098",
    SetupImportCc: "https://www.youtube.com/embed/videoseries?si=m9Py6nPon6oeiLtm&amp;list=PLugzlMICZR8gLwyl514FxmLgiLzs3_6Mh",
    AssignCcToClaimants: "https://www.youtube.com/embed/videoseries?si=0pur9LNzho12_Ncr&amp;list=PLugzlMICZR8ggxE-PUxvbhe0G6VTlG4TP",
    SetupDisplayNameCodeOfCc: "https://www.youtube.com/embed/videoseries?si=ZfudOeeuSayinJnd&amp;list=PLugzlMICZR8hBYghQrLcehcbv75IHnb9h",
    SetupCustomer: "https://www.youtube.com/embed/videoseries?si=mPX-lPNWWbPUikZ4&amp;list=PLugzlMICZR8j2_twdPqXebwcc5jzrF10y",
    SetupCcAccessRights: "https://www.youtube.com/embed/videoseries?si=i9Xe1P3M_hqLd4v3&amp;list=PLugzlMICZR8jUYagbgW6oc6A--wReKU3h",
    ActivateClaimantsWhoCanDelegate: "https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8hJGhS5hK7znXTgS0r7clCU",
    ActivateClaiamantsWhoWillDoTheWork: "https://www.youtube.com/embed/videoseries?si=-z9mOXtb03SlpWTs&amp;list=PLugzlMICZR8jlXOBBj0Ap7WkzSvNQxHBa",
    ManageDelegatorsAndDelegates: "https://www.youtube.com/embed/videoseries?si=V-ng4XMniKNyRlTX&amp;list=PLugzlMICZR8gP215pkqu1pXGve07YOXS8",
    ExpenseCategoryManagement:
        "https://www.youtube.com/embed?list=PLugzlMICZR8hcvfcGEtf9plQjk8_k_qNF",
    // TaxRatewithComponents: "https://www.youtube.com/embed?list=PLugzlMICZR8hPjKl1Lgzxf-xrO5HOYqxp",
    TaxRatewithoutComponents:
        "https://www.youtube.com/embed?list=PLugzlMICZR8jQf-Yv7a_ij2es0q1f2Plj",
    TaxRatewithComponents:
        "https://www.youtube.com/embed?list=PLugzlMICZR8hPjKl1Lgzxf-xrO5HOYqxp",
    // GeneralSettings: "https://player.vimeo.com/video/261764790",
    // GeneralSettingsINR: "https://player.vimeo.com/video/349615817",
    AssignTaxtoCategories:
        "https://www.youtube.com/embed?list=PLugzlMICZR8hVIObATldvjwKV4WbigeXA",
    // SetupPolicyAssigntoGrades: "https://player.vimeo.com/video/261764877",
    //SetupUKMileageRates: "https://www.youtube.com/embed?list=PLugzlMICZR8i85Rrw4e_eMmk-WED8arEj",
    //VehicleManagement: "https://player.vimeo.com/video/277604380",
    // Triangulation: "https://player.vimeo.com/video/277408514",
    // ExportDatatoOtherSystems: "https://www.youtube.com/embed?list=PLugzlMICZR8jpha9c7VwDIcZr362dlWvU",
    // ExpensesTwoStepApproval: "https://player.vimeo.com/video/356119256",
    Tally:
        "https://www.youtube.com/embed?list=PLugzlMICZR8hy4xVveaMWJUwlUmeEoytj",
    Xero:
        "https://www.youtube.com/embed?list=PLugzlMICZR8jbh5JqouUMyPiZKuuD2NuW",
    QuickBooks:
        "https://www.youtube.com/embed?list=PLugzlMICZR8jK2xFlslYF9JgAcD3vQ8E4",
    Sage50:
        "https://www.youtube.com/embed?list=PLugzlMICZR8hjHBNvbeuQegUAdrTSueZL",
    // CardDismissedTransactions: "https://player.vimeo.com/video/262785095",
    // CardImportStatement: "https://player.vimeo.com/video/262785095",
    CardSetup:
        "https://www.youtube.com/embed?list=PLugzlMICZR8iMgRqlA9_YnQs6iP5kUd1R",
    // CardAssignClaimant: "https://player.vimeo.com/video/262785095",
    // CardAssignClaimantImport: "https://player.vimeo.com/video/262785095",
    // CardImportTemplate: "https://player.vimeo.com/video/262785095",
    // CardRegister: "https://player.vimeo.com/video/262785095",
    // CardRules: "https://player.vimeo.com/video/262785095",
    ExpenseExport:
        "https://www.youtube.com/embed?list=PLugzlMICZR8jpha9c7VwDIcZr362dlWvU",
    // ExpenseExportLog: "https://player.vimeo.com/video/263490841",
    // ImportCostCentre: "https://player.vimeo.com/video/261475098",
    // ImportGrades: "https://player.vimeo.com/video/261474956",
    InvoicesSubscription:
        "https://www.youtube.com/embed?list=PLugzlMICZR8ilLjOuUJtGOj26TJV8kUJF",
    // ChangePassword: "https://player.vimeo.com/video/259328865",
    // TaxRateSetup: "https://www.youtube.com/embed?list=PLugzlMICZR8jQf-Yv7a_ij2es0q1f2Plj",
    TaxSetup:
        "https://www.youtube.com/embed?list=PLugzlMICZR8hHTK79mU4k39_bzPAt-tdG",
    CreateNewReport:
        "https://www.youtube.com/embed?list=PLugzlMICZR8h0wbNnGuG2Evba_gi4-pKz",
    Pricing:
        "https://www.youtube.com/embed?list=PLugzlMICZR8hgpmcS5TIRwAkdTAySO8nH",
    // ExistingReport:"https://player.vimeo.com/video/259328865",
    // ExportLog: "https://player.vimeo.com/video/259328865",
    // EditExistingReport:"https://player.vimeo.com/video/259328865",
    // YesBankImportEmployees:"https://player.vimeo.com/video/259328865",
    BillingPlan: "https://www.youtube.com/embed?list=PLugzlMICZR8j8LsVW8WNTvDU6ncDc-3XX",
    // YesBankRegistration:"https://player.vimeo.com/video/259328865",
    // AssignCodesToEmpoyeesTally:"https://player.vimeo.com/video/259328865",
    // AssignCodesToEmpoyeesXero:"https://player.vimeo.com/video/259328865",
    // AssignCodesToEmpoyeesQuickBooks:"https://player.vimeo.com/video/259328865",
    // AssignCodesToEmpoyeesSage50:"https://player.vimeo.com/video/259328865",
    // AssignCodesToTaxTally:"https://player.vimeo.com/video/259328865",
    // AssignCodesToTaxXero:"https://player.vimeo.com/video/259328865",
    // AssignCodesToTaxQuickBooks:"https://player.vimeo.com/video/259328865",
    // AssignCodesToTaxSage50:"https://player.vimeo.com/video/259328865",
    // AssignCodesToCategoryTally:"https://player.vimeo.com/video/259328865",
    // AssignCodesToCategoryXero:"https://player.vimeo.com/video/259328865",
    // AssignCodesToCategoryQuickBooks:"https://player.vimeo.com/video/259328865",
    // AssignCodesToCategorySage50:"https://player.vimeo.com/video/259328865",
    // AssignCodesToCostCentreTally:"https://player.vimeo.com/video/259328865",
    AdvancesSetup:
        "https://www.youtube.com/embed?list=PLugzlMICZR8jzUQtcFqq7gSmFFrSRscHx",
    TriangulationOfficeNameAddress:
        "https://www.youtube.com/embed?list=PLugzlMICZR8hVuDoN0X5tOgS6NCgkFK8m",
    SetupAdvancedMileage:
        "https://www.youtube.com/embed?list=PLugzlMICZR8iumkWoAbGrO7wxT4rXnIMi",
    // TriangulationSetupOfficeWorker: "https://www.youtube.com/embed?list=PLugzlMICZR8hDkkL8C-IvsWPkcpYdC_li",
    SetupUkHMRCCompliantRates:
        "https://www.youtube.com/embed?list=PLugzlMICZR8i85Rrw4e_eMmk-WED8arEj",
    //UkMileageQuickAssignment: "https://player.vimeo.com/video/259328865",
    AssignVehicleToClaimants:
        "https://www.youtube.com/embed?list=PLugzlMICZR8hutwJrmhzNSIz5xZQuCH3t",
    //TriangulationSetupHomeWorker: "https://player.vimeo.com/video/259328865",
    SetupUkHMRCCompliantRatesDefault:
        "https://www.youtube.com/embed?list=PLugzlMICZR8i85Rrw4e_eMmk-WED8arEj",
    // ReviewYourOrder:"https://player.vimeo.com/video/259328865",
    // ShoppingCart: "https://player.vimeo.com/video/259328865",
    // PayAllExpenses: "https://player.vimeo.com/video/259328865",
    // PayExpenses: "https://player.vimeo.com/video/259328865"
    SetupExpensePolicy:
        "https://www.youtube.com/embed?list=PLugzlMICZR8jXvAN5f1EvUB7AyJRypKMT",
    AssignVehicleToClaimantsAdvance:
        "https://www.youtube.com/embed?list=PLugzlMICZR8hSFvJixjzEBCOn7tDjUKiq",
    SetupCustomers:
        "https://www.youtube.com/embed?list=PLugzlMICZR8j2_twdPqXebwcc5jzrF10y",
    BusinessPurpose:
        "https://www.youtube.com/embed?list=PLugzlMICZR8gi5K4QXMTg3zcup8O7s67S",
    Liability:
        "https://www.youtube.com/embed?list=PLugzlMICZR8g7fNpAzhCNseWAiw9WN85_",
    SetupSingleRate:
        "https://www.youtube.com/embed?list=PLugzlMICZR8iiazDMeWcqN-Gp1wtjJ1U8",
    AuditTrail:
        "https://www.youtube.com/embed?list=PLugzlMICZR8hWc9deP5yno-atGRN3P1RG",
    ManualVat:
        "https://www.youtube.com/embed?list=PLugzlMICZR8gDd7IfhywiQ4IJmTdr8xpw",
    SetupDifferentialRate:
        "https://www.youtube.com/embed?list=PLugzlMICZR8jL2xc-rZ8xfoKARC7R0_d3",
    AdditionalFields:
        "https://www.youtube.com/embed?list=PLugzlMICZR8ggNu0tZIebglBFI6hz8-_k",
    ExpenseSubmissionTimeLimit:
        "https://www.youtube.com/embed?list=PLugzlMICZR8ic0ocSdPVI3Ieg6mVUETS3",
    vatnovat:
        "https://www.youtube.com/embed?list=PLugzlMICZR8grqvMQaqkK8a0G2q2yM_s3",
    SecondaryFM:
        "https://www.youtube.com/embed?list=PLugzlMICZR8ibsirN72ndbtQPNBFFyD2y",
    AutoApproval:
        "https://www.youtube.com/embed?list=PLugzlMICZR8hIIpqTQNgZCrB-pFHluIg8",
    DuplicateRule:
        "https://www.youtube.com/embed?list=PLugzlMICZR8hNHNJcq0tWPTJiUUt-ZvHF",
    ExpenseCappingDaily:
        "https://www.youtube.com/embed?list=PLugzlMICZR8j1MUsoIvHd6rs4xQA-2iL3",
    ExpenseCappingMonthly:
        "https://www.youtube.com/embed?list=PLugzlMICZR8gZfTQsKibY3GOcu_ydQMNh",
    ExpenseCappingYearly:
        "https://www.youtube.com/embed?list=PLugzlMICZR8ifRNxVywYpA6A1jnV8d1e0",
    OverallDailyLimit:
        "https://www.youtube.com/embed?list=PLugzlMICZR8gagB4WsSdsbDXqidgeW4zl",
    ExpenseEscalationSettings:
        "https://www.youtube.com/embed?list=PLugzlMICZR8hgvVhaDbEycVtATxBi7_6f",
    PerPersonLimit:
        "https://www.youtube.com/embed?list=PLugzlMICZR8h7x5TW0TPJAEkTb6jKcUOd",
    PerNightLimit:
        "https://www.youtube.com/embed?list=PLugzlMICZR8iUjRhUBOln0MIsMxVt4xo9",
    nr:
        "https://www.youtube.com/embed?list=PLugzlMICZR8hCLGcjE73Pt2sh7AQhXTse",
    fc:
        "https://www.youtube.com/embed?list=PLugzlMICZR8hYoJvxJJZcQl3O0cYgBgUz",
    nrfcsetting:
        "https://www.youtube.com/embed?list=PLugzlMICZR8hdnIaqpQOwFv4y5EfWp-nv",
    ReferNEarn:
        "https://www.youtube.com/embed?list=PLugzlMICZR8gCNc_58XL5QeZmq1K6gaF-",
    AssignCategoryToGrade:
        "https://www.youtube.com/embed?list=PLugzlMICZR8h8q9H4qVPdLYOL5JdD0A7s",
    ApproverDeputy:
        "https://www.youtube.com/embed?list=PLugzlMICZR8gJ8-XD14ry-rDfxLyBfane",
    OutOfOffice:
        "https://www.youtube.com/embed?list=PLugzlMICZR8gJ8-XD14ry-rDfxLyBfane",
    ProjectBudgetSummary:
        "https://www.youtube.com/embed?list=PLugzlMICZR8gFSpGLX1-oT7RneSr6PZ72",
    MultipleCompanies: "https://www.youtube.com/embed?list=PLugzlMICZR8g56SykrpJTciUP__T6Q-96",
    AssignEmployeesToCompany: "https://www.youtube.com/embed?list=PLugzlMICZR8gtJyO2wD03X2zq0jZrGgf4",
    AssignMileageRateToGrade:
        "https://www.youtube.com/embed?list=PLugzlMICZR8gjhd-lMdKJuFl9jyl7POEu",
    AssignAccountCodeToCompany: "https://www.youtube.com/embed?list=PLugzlMICZR8iWrQ-pPwslPuR0MQ64WzG3",
    OpenAPIs: "https://www.youtube.com/embed?list=PLugzlMICZR8g1C-CjtppzcDpwC3h14hJm",
    MultipleCompaniesConfigureRules: "https://www.youtube.com/embed?list=PLugzlMICZR8hYsdlb0o-WjMQkik4MpTq7",
    MultipleCompaniesConfigureRulesSetup: "https://www.youtube.com/embed?list=PLugzlMICZR8g8hCUo3gNgJsqpxXpGchaI",
    ClaimantOnWebExpenseList: "https://www.youtube.com/embed?list=PLugzlMICZR8gHDyvsNBo-lDqEgKkl987j",
    ApproverHistoricalView:
        "https://www.youtube.com/embed?list=PLugzlMICZR8jNQlcGafNmyWp2S2uwPYpq",
    SetupLocation:
        "https://www.youtube.com/embed?list=PLugzlMICZR8hsfyXao5doXvCutaDVv1e2",
    AttendeeType: "https://www.youtube.com/embed?list=PLugzlMICZR8gl-UWwAjp30Z7PoSIaSxZM",
    DutyofCare: "https://www.youtube.com/embed?list=PLugzlMICZR8hrKLHd5WitJgpPsqjb0IhB",
    ApproveDutyofCare: "https://www.youtube.com/embed?list=PLugzlMICZR8jXUrh1MNZI5Ub-r04xEgq7",
    MonthlyCappingClaimantWise: "https://www.youtube.com/embed?list=PLugzlMICZR8i3TdRLbRLz_BbcwqGB3t89",
    YearlyCappingClaimantWise: "https://www.youtube.com/embed?list=PLugzlMICZR8iRmFCFipzYgPGPY3J05uNt",
    SetupSupplier:
        "https://www.youtube.com/embed?list=PLugzlMICZR8gqxZuJA0_vBb99s_fYc1lz",
    UniversalEmployeesImportTemplate: "https://www.youtube.com/embed?list=PLugzlMICZR8gIyWPByVoc8qdAO_aAVlrT",
    LeaveManagement: "https://www.youtube.com/embed?list=PLugzlMICZR8iIDfqdeoedLBf7yAr_1ds8",
    UniversalImportTemplate: "https://www.youtube.com/embed?list=PLugzlMICZR8gIyWPByVoc8qdAO_aAVlrT",
    MultipleCountry: "https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8g56SykrpJTciUP__T6Q-96",
    // ImportCreditCardStatement: "https://www.youtube.com/embed?list=PLugzlMICZR8iMgRqlA9_YnQs6iP5kUd1R",
    ImportCreditCardStatement: "https://www.youtube.com/embed/videoseries?si=M829NqpK6KSmx-wR&amp;list=PLugzlMICZR8jfwVk3EMowX1Y5Y0LzdQko",
    DiscountContractWork: "https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8g6_yfaLnp88R6yXtE1o_Y4",
    DiscountAndCostControl: "https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8izs6rCBoX7ktGQzSX2Wkjc",
    DiscountContract: "https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8g6_yfaLnp88R6yXtE1o_Y4",
    MultipleVehicle: "https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8gk7CSlETsufM184-KC1V6b",
    AssignMultipleVehicle: "https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8im5BHhmOfLiCaSV19UIGWX",
    PassengerLoadMiles: "https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8jg_U0adcT7w4d-_dY0mpjV",
    AntiFraudMultiple: "https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8jmoXJqin8g79cpRj6u9FpH",
    ComplianceStatement: "https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8gaEGXfDSg1U5j0S0ZFCxD8",
    ImportEmployees: "https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8gdDNJa_qn32n5Kuym-9H3l",
    CashAdvance: "https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8jKrw9mx5GGb728myVg0m1w",
    Sage200:"https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8hCen_H_LOoSOLRzjLNR2wg",
    ExpenseCategorySetup: "https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8hTbum8jgz4Lcpx6zCoCyc-",
    ImportCategories: "https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8iOcwNioBvKSKWN3ybgKQ8I",
    ForgotPassword: "https://www.youtube.com/embed/clAvwbrw4B0",
    DelegateSetup: "https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8hJGhS5hK7znXTgS0r7clCU",
    CounterApprover:"https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8iV-_QBkNpzkt9-IIZhDv1F",
    DelegateAssign:"https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8i1tdCkZuEg1F1jyTLrF5CX",
    CreateDelegatorsExpense:"https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8hdKSYq1yCazGPaGjjVYd_j",
    ImportExportMileageRates:"https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8jOjaa0SDL6HOV7PIqxEZ9n",
    FxRate:"https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8j13aCPiLkMYeGm81IFU0bZ",
    YearEndRule:"https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8hoBoMeh1HyOxYILTQgGug-",
    ImportDeputies:"https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8g0aPnWtjuOAjnYlCyyud8P",
    ImportVehicles:"https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8jwauIDhq9on93g8zFWiKCY",
    ImportHomePostcodes:"https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8haaWLvWbtZZhxqIWj6Tg8q",
    ImportMileageRates: "https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8jOjaa0SDL6HOV7PIqxEZ9n",
    APAutomation: "https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8jr0IZ17rzErxaU6qDXa3fC",
    ApproverKYC: "https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8jKyry-fW4Jy_fgMT3KhDus",
    AssignApproversToCostCentre: "https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8gGLwEGCVmcpax2pR6YCS4-",
    DisappearingExpense : "https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8jtohXf2n_07Mvh5nDxDWhz",
    CarbonFootprint: "https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8j3ln8EwoEOUa8oOC8ehvls",
    SSO:"https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8h4Yl1TfJf-kgh27TBcbCeh",
    FMCanEditExpense: "https://www.youtube.com/embed/videoseries?si=4YfFxNpPJbqP1zNo&amp;list=PLugzlMICZR8gyUQ4TxywbrgsZpguxlVkC",
    AssignGradetoClaimants: "https://www.youtube.com/embed/videoseries?si=iqww7ImIQD0UspKo&amp;list=PLugzlMICZR8gzXqL-76AXQSBureYY4rc2",
    ImportGrade: "https://www.youtube.com/embed/videoseries?si=kDxwrFipr_wn18aD&amp;list=PLugzlMICZR8gShWpE9Z9GsVKYt5Kj5dlr",
    GradeWizard: "https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8i9SxQzHjyOrXHUcldv8Rwx",
    SetupGrades:"https://www.youtube.com/embed/videoseries?si=QxiOvjAsZHmgmekE&amp;list=PLugzlMICZR8i9SxQzHjyOrXHUcldv8Rwx",
    ImportGrades: "https://www.youtube.com/embed/videoseries?si=fv4mNrkiKDBxErT9&amp;list=PLugzlMICZR8gShWpE9Z9GsVKYt5Kj5dlr",
    Container: "https://www.youtube.com/embed/videoseries?si=H40sClb5HyQ6ka8A&amp;list=PLugzlMICZR8jwvUT66Fzb-oLQUF90qcfp",
    AddSetupAndManageEmployees: "https://www.youtube.com/embed/videoseries?si=5P6FlyZ-DUukv10l&amp;list=PLugzlMICZR8iepD3e_NdmiBl8C6TjA33P",
    ManageEmployeesImportEmployees: "https://www.youtube.com/embed/videoseries?si=bibEMDjgTmj2rAe1&amp;list=PLugzlMICZR8gdDNJa_qn32n5Kuym-9H3l",
    ImportExportExpenseCategories: "https://www.youtube.com/embed/videoseries?si=pRdtrQrEibRGu2Xh&amp;list=PLugzlMICZR8iOcwNioBvKSKWN3ybgKQ8I",
    SetupAdditionalField: " https://www.youtube.com/embed/videoseries?list=PLugzlMICZR8ggNu0tZIebglBFI6hz8-_k",
    Assignfieldtocategoriesstep5: "https://www.youtube.com/embed/videoseries?si=j9QYh59EIew_Rejx&amp;list=PLugzlMICZR8iy1XoCo011LA86_6Rdi7Wj",
    RollBackExportedExpenses: "https://www.youtube.com/embed/videoseries?si=HTKgk-1ffmuJhf9P&amp;list=PLugzlMICZR8jT4MgsQpZy1Pa7zOCe8rek",
    ccSetupStep1:"https://www.youtube.com/embed/videoseries?si=Axbic7gWNWPjmCQH&amp;list=PLugzlMICZR8h86IB6jr9FCO_3p3vd5REn",
    ccSetupStep2:"https://www.youtube.com/embed/videoseries?si=qw-5PtvGioEvIW4Q&amp;list=PLugzlMICZR8iT44ujSFS4sv_EtlA2JYc2",
    ccSetupStep3:"https://www.youtube.com/embed/videoseries?si=31oL5cbINB81pIGa&amp;list=PLugzlMICZR8iQsUvIswG11PJ8P9aHqsE9",
    ccSetupStep4:"https://www.youtube.com/embed/videoseries?si=rjk95M_hCL2yVhPb&amp;list=PLugzlMICZR8irf6KkH6m62OJhQbPBhW2e",
    ccSetupStep5:"https://www.youtube.com/embed/videoseries?si=byPopj3dORUSAXAl&amp;list=PLugzlMICZR8hr4PxyslEkROcLd-0eXHlZ",
    ccSetupStep6:"https://www.youtube.com/embed/videoseries?si=D1y8lAM2Jxy1hvX0&amp;list=PLugzlMICZR8j71sQPGGfT_FQK7ChGnA9t",
    ccSetupStep7:"https://www.youtube.com/embed/videoseries?si=2LQzWCTXs4pfW189&amp;list=PLugzlMICZR8gCFijjHevAChpeItdUVkDf",
    ccSetupStep8:"https://www.youtube.com/embed/videoseries?si=Ebz3TUnjey_INdD1&amp;list=PLugzlMICZR8g1WkeDZHhKrSH1ylx_VQUw",
    ConfigureNamesOfRecieptTypes: "https://www.youtube.com/embed/videoseries?si=AhYeYc4isvscyusX&amp;list=PLugzlMICZR8jz4Be6RPJCXUmZ06YbA-A1",
    TurnOnReceiptTypeDisplayToClaimant: "https://www.youtube.com/embed/videoseries?si=HjZ-6080rOPkhQ5o&amp;list=PLugzlMICZR8h_QT1WyHVmv_kNnQ6Amamz",
    ConfigurePolicyViolationTaxNoTax: "https://www.youtube.com/embed/videoseries?si=m3xokw7Otg6TlTjX&amp;list=PLugzlMICZR8ioC18HU5IRPIentcu4T9SN",
    EnableRecieptNumberCapture: "https://www.youtube.com/embed/videoseries?si=MOf9EUXQ_dXBhjc&amp;list=PLugzlMICZR8i8OJHmT27E6uFwpZ9E4Dmd",
    SpecialCatStep1: "https://www.youtube.com/embed/videoseries?si=KD23YIoIYbiJcwmK&amp;list=PLugzlMICZR8gQKUyIP3nAGieONXKPxXvE",
    SpecialCatStep2: "https://www.youtube.com/embed/videoseries?si=F7JyC9V2XeCq5K5C&amp;list=PLugzlMICZR8j7yYAUPMABV-G2zwoI9rAT",
    SpecialCatStep3: "https://www.youtube.com/embed/videoseries?si=LfWl_vP5FVyflJ7Z&amp;list=PLugzlMICZR8gYEvtjUxBDFu0bDCmLdxr9",
    SpecialCatStep4: "https://www.youtube.com/embed/videoseries?si=W8eSrJG46XbUYIQY&amp;list=PLugzlMICZR8ghrg22zUuj7ChfKEi8c0Wi"

};

export const websiteURl = "https://www.expenseondemand.com/";
export const UTCDateTime = {
    getUTCDate(date) {
        var now_utc = Date.UTC(
            date.getUTCFullYear() < 0 ? date.getUTCFullYear() * -1 : date.getUTCFullYear(),
            date.getUTCMonth() < 0 ? date.getUTCMonth() * -1 : date.getUTCMonth(),
            date.getUTCDate() < 0 ? date.getUTCDate() * -1 : date.getUTCDate(),
            0,
            0,
            0
        );

        return now_utc;
    },
    getUTCTimeStamp(date:Date) {
        let dateNow = new Date();
        let incomingDate = date;
        let now_utc = 0;
        if(dateNow.getDate() == incomingDate.getDate())
        {
            now_utc = Date.UTC(
                dateNow.getUTCFullYear(),
                dateNow.getUTCMonth(),
                dateNow.getUTCDate(),
                dateNow.getUTCHours(),
                dateNow.getUTCMinutes(),
                dateNow.getUTCSeconds(),
                dateNow.getUTCMilliseconds()
            );
        }
        else
        {
            let incomingDate1 = date.setHours(dateNow.getHours());
            incomingDate1 = date.setMinutes(dateNow.getMinutes());
            incomingDate1 = date.setSeconds(dateNow.getSeconds());
            let newDate = new Date(incomingDate1)
            now_utc = Date.UTC(
                newDate.getUTCFullYear(),
                newDate.getUTCMonth(),
                newDate.getUTCDate(),
                newDate.getUTCHours(),
                newDate.getUTCMinutes(),
                newDate.getUTCSeconds(),
                newDate.getUTCMilliseconds()
            );
        }
        

        return now_utc;
    }
};
export const ConvertNonAsciiToAscii = {
    removeAccents(input) {
        if(input)
          {
            const pattern = /[\u00e0-\u00ff\u0100-\u017fàáâãäåæÀÁÂÃÄÅÆçÇèéêëÈÉÊËìíîïÌÍÎÏðÐñÑòóôõöøÒÓÔÕÖØùúûüÙÚÛÜýÝ]/g;
          const replacements = {
              "à": "a", "á": "a", "â": "a", "ã": "a", "ä": "a", "å": "a", "æ": "ae", 
              "À": "A", "Á": "A", "Â": "A", "Ã": "A", "Ä": "A", "Å": "A", "Æ": "AE",
              "ç": "c", "Ç": "C", 
              "è": "e", "é": "e", "ê": "e", "ë": "e", 
              "È": "E", "É": "E", "Ê": "E", "Ë": "E",
              "ì": "i", "í": "i", "î": "i", "ï": "i", 
              "Ì": "I", "Í": "I", "Î": "I", "Ï": "I",
              "ð": "d", "Ð": "D",
              "ñ": "n", "Ñ": "N",
              "ò": "o", "ó": "o", "ô": "o", "õ": "o", "ö": "o", "ø": "o",
              "Ò": "O", "Ó": "O", "Ô": "O", "Õ": "O", "Ö": "O", "Ø": "O",
              "ù": "u", "ú": "u", "û": "u", "ü": "u", 
              "Ù": "U", "Ú": "U", "Û": "U", "Ü": "U",
              "ý": "y", "Ý": "Y"
          };
          
            return input.replace(pattern, match => replacements[match] || match);
          }
          else
          {
            return input;
          }
       
      }
}

export const CityConstant = [
    { id: 1, name: "Vilnius" },
    { id: 2, name: "Kaunas" },
    { id: 3, name: "Pavilnys", disabled: true },
    { id: 4, name: "Pabradė" },
    { id: 1, name: "Vilnius" },
    { id: 2, name: "Kaunas" },
    { id: 3, name: "Pavilnys", disabled: true },
    { id: 4, name: "Pabradė" },
    { id: 1, name: "Vilnius" },
    { id: 2, name: "Kaunas" },
    { id: 3, name: "Pavilnys", disabled: true },
    { id: 4, name: "Pabradė" },
    { id: 1, name: "Vilnius" },
    { id: 2, name: "Kaunas" },
    { id: 3, name: "Pavilnys", disabled: true },
    { id: 4, name: "Pabradė" },
    { id: 1, name: "Vilnius" },
    { id: 2, name: "Kaunas" },
    { id: 3, name: "Pavilnys", disabled: true },
    { id: 4, name: "Pabradė" },
    { id: 1, name: "Vilnius" },
    { id: 2, name: "Kaunas" },
    { id: 3, name: "Pavilnys", disabled: true },
    { id: 4, name: "Pabradė" },
    { id: 1, name: "Vilnius" },
    { id: 2, name: "Kaunas" },
    { id: 3, name: "Pavilnys", disabled: true },
    { id: 4, name: "Pabradė" },
    { id: 1, name: "Vilnius" },
    { id: 2, name: "Kaunas" },
    { id: 3, name: "Pavilnys", disabled: true },
    { id: 4, name: "Pabradė" },
    { id: 1, name: "Vilnius" },
    { id: 2, name: "Kaunas" },
    { id: 3, name: "Pavilnys", disabled: true },
    { id: 4, name: "Pabradė" },
    { id: 1, name: "Vilnius" },
    { id: 2, name: "Kaunas" },
    { id: 3, name: "Pavilnys", disabled: true },
    { id: 4, name: "Pabradė" },
    { id: 1, name: "Vilnius" },
    { id: 2, name: "Kaunas" },
    { id: 3, name: "Pavilnys", disabled: true },
    { id: 4, name: "Pabradė" },
    { id: 1, name: "Vilnius" },
    { id: 2, name: "Kaunas" },
    { id: 3, name: "Pavilnys", disabled: true },
    { id: 4, name: "Pabradė" },
    { id: 1, name: "Vilnius" },
    { id: 2, name: "Kaunas" },
    { id: 3, name: "Pavilnys", disabled: true },
    { id: 4, name: "Pabradė" },
    { id: 1, name: "Vilnius" },
    { id: 2, name: "Kaunas" },
    { id: 3, name: "Pavilnys", disabled: true },
    { id: 4, name: "Pabradė" },
    { id: 1, name: "Vilnius" },
    { id: 2, name: "Kaunas" },
    { id: 3, name: "Pavilnys", disabled: true },
    { id: 4, name: "Pabradė" },
    { id: 1, name: "Vilnius" },
    { id: 2, name: "Kaunas" },
    { id: 3, name: "Pavilnys", disabled: true },
    { id: 4, name: "Pabradė" },
    { id: 1, name: "Vilnius" },
    { id: 2, name: "Kaunas" },
    { id: 3, name: "Pavilnys", disabled: true },
    { id: 4, name: "Pabradė" },
    { id: 1, name: "Vilnius" },
    { id: 2, name: "Kaunas" },
    { id: 3, name: "Pavilnys", disabled: true },
    { id: 4, name: "Pabradė" },
    { id: 1, name: "Vilnius" },
    { id: 2, name: "Kaunas" },
    { id: 3, name: "Pavilnys", disabled: true },
    { id: 4, name: "Pabradė" },
    { id: 1, name: "Vilnius" },
    { id: 2, name: "Kaunas" },
    { id: 3, name: "Pavilnys", disabled: true },
    { id: 4, name: "Pabradė" },
    { id: 1, name: "Vilnius" },
    { id: 2, name: "Kaunas" },
    { id: 3, name: "Pavilnys", disabled: true },
    { id: 4, name: "Pabradė" },
    { id: 5, name: "Klaipėda" },
];
