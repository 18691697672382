<div class="input-group">
  <input id="InputPanel" type="text" class="form-control searchField padding-right-30" readonly
    [formControl]="formControl" [formlyAttributes]="field"
    [ngClass]="{'has-value': formControl.value, 'padding-left-20': field.key === 'preApprovalTag', 'color-light-grey-disabledType': to.disabled }"
    (click)="fnOpenSelectionPanel()">
  <label for="InputPanel">{{field.templateOptions.label}}<span *ngIf="field.templateOptions.required">*</span></label>
  <span class="icons z-0" *ngIf="!formControl.disabled" (click)="fnOpenSelectionPanel()">
    <a><i class="fas fa-chevron-circle-right text-logo-blue" aria-hidden="true"></i></a>
  </span>

  <span *ngIf="model.isPolicyAvailable && field.key == 'expenseCategoryName' && model.orgExpenseCategoryId > 0">
    <a (click)="fnShowExpPolicy()">
      <small class="d-inline-block w-100pc text-right cursor-pointer text-logo-blue text-underline">
        {{ "label_ViewExpensePolicy" | translate }}
      </small>
    </a>
  </span>

  <span class="position-absolute left-0px top-8px text-mustard"
    *ngIf="field.key === 'preApprovalTag' && formControl.value">
    <i class="fas fa-link"></i>
  </span>
  <p class="fw-600" *ngIf="field.key === 'preApprovalTag' && formControl.value"
    [ngClass]="{'color-red': model.preApprovalBalance < 0 }">
    {{ 'pre_approval_balance' | translate }}: {{model.preApprovalBalance| number: "1.2-2"}}
  </p>

  <p class="fw-100 f-11" *ngIf="field.key === 'derivedName' && formControl.value">
    {{ 'registration_number' | translate }}:
    {{fnReturnRegNum(model.mileage.registrationNumber)}}{{fnReturnMakeModel(model.mileage.makeModel)}}
  </p>

  <div *ngIf="formControl.errors && formControl.errors.required && isFormSubmitted" class="alert-danger">
    <span *ngIf="!formControl.value || !formControl.value.trim()">
      <b>{{ "label_Required" | translate }}</b>
    </span>
  </div>

</div>

<!-- Panels -->
<select-category-panel *ngIf="showSelectExpenseCategoryPanel" [inputData]="inputModel"
  (closePanelEvent)="closeSelectionPanel($event)" (selectItemEvent)="onSelection($event)">
</select-category-panel>

<select-pre-approval-tag-panel *ngIf="showSelectPreApprovalTagPanel" [inputData]="inputModel"
  (closePanelEvent)="closeSelectionPanel($event)" (selectItemEvent)="onSelection($event)">
</select-pre-approval-tag-panel>

<select-foreign-currency-panel *ngIf="showSelectForeignCurrencyPanel" [inputData]="inputModel"
  (closePanelEvent)="closeSelectionPanel($event)" (selectItemEvent)="onSelection($event)">
</select-foreign-currency-panel>

<select-state-panel *ngIf="showSelectStatesPanel" [inputData]="inputModel"
  (closePanelEvent)="closeSelectionPanel($event)" (selectItemEvent)="onSelection($event)">
</select-state-panel>

<select-business-purpose-panel *ngIf="showSelectBusinessPurposePanel" [inputData]="inputModel"
  (closePanelEvent)="closeSelectionPanel($event)" (selectItemEvent)="onSelection($event)">
</select-business-purpose-panel>

<select-additional-field-data-panel *ngIf="showSelectAdditionalFieldDataPanel" [inputData]="inputModel"
  (closePanelEvent)="closeSelectionPanel($event)" (selectItemEvent)="onSelection($event)">
</select-additional-field-data-panel>

<view-exp-policy-panel *ngIf="showExpensePolicyPanel" [inputData]="showPolicyInputModel"
  (closePanelEvent)="closeSelectionPanel($event)">
</view-exp-policy-panel>

<select-customer-panel *ngIf="showSelectCustomerPanel" [inputData]="inputModel"
  (closePanelEvent)="closeSelectionPanel($event)" (selectItemEvent)="onSelection($event)">
</select-customer-panel>

<select-project-panel *ngIf="showSelectProjectPanel" [inputData]="inputModel"
  (closePanelEvent)="closeSelectionPanel($event)" (selectItemEvent)="onSelection($event)">
</select-project-panel>

<select-cost-centre-panel *ngIf="showSelectCostCentrePanel" [inputData]="inputModel"
  (closePanelEvent)="closeSelectionPanel($event)" (selectItemEvent)="onSelection($event)">
</select-cost-centre-panel>

<select-receipt-type-panel *ngIf="showSelectReceiptTypePanel" [inputData]="inputModel"
  (closePanelEvent)="closeSelectionPanel($event)" (selectItemEvent)="onSelection($event)">
</select-receipt-type-panel>

<app-select-vehicle-panel *ngIf="showVehiclePanel" [inputData]="inputModel"
  (closePanelEvent)="closeSelectionPanel($event)" (selectItemEvent)="onSelection($event)">
</app-select-vehicle-panel>
<app-alert-panel [data]="alertPanelModel"
                     (callbackEvent)="alertPanelReceiver($event)">
    </app-alert-panel>

  <container-list-panel *ngIf="showContainerListPanel" [inputData]="inputModel"
  (closePanelEvent)="closeSelectionPanel($event)" (submitPanelEvent)="onSelection($event)">
 </container-list-panel>