import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { IApiRequest, IApiresponse } from "src/app/_models";
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";
import {ContainerTurnOnSettingResponse,IContainerTurnOnSettingRequest,
  IContainerListRequest,ContainerResponse, ContainerRequest,
  DeleteContainerRequest, IExpenseContainerListRequest,ExpenseContainerResponse,
  SaveContainerResponse,ContainerListResponse, IUpdateContainerRequest, UpdateContainerRequest, UpdateContainerResponse,
  MoveExpensesToContainerRequest, IApproverContainerListRequest, ApproverContainerListResponse
} from "src/app/_models/container/container";
import { map } from "rxjs/operators";
import { RSAHelper } from "src/app/_helpers/RSAHelper";

@Injectable({
  providedIn: "root",
})
export class ContainerService {

  constructor(
    private http: HttpClient,
    private claimsHeaderService: ClaimsHeaderService
  ) { }


EncryptParameters(encryptValue) {
    let rsaHelper = new RSAHelper();
    let encrypt = rsaHelper.Encrypt(encryptValue);
    return encrypt;
  }

DecryptParameters(encryptedParam): string {
    let rsaHelper = new RSAHelper();
    let decrypt = rsaHelper.Decrypt(encryptedParam);
    return decrypt;
  }

GetContainerTurnOnSetting(orgId: number): Observable<IApiresponse<ContainerTurnOnSettingResponse>> {
    let params = new HttpParams();
    params = params.append("orgId", orgId.toString());
    return this.http.get<IApiresponse<ContainerTurnOnSettingResponse>>(
      `${environment.billingApiUrl}GetContainerTurnOnSetting`, 
      { params: params, headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

SaveContainerTurnOnSetting( 
    requestInfo: IApiRequest<IContainerTurnOnSettingRequest> ): Observable<IApiresponse<any>> {
        let body = JSON.stringify(requestInfo);
        return this.http.post<IApiresponse<any>>(
            `${environment.billingApiUrl}SaveContainerTurnOnSetting`,
            body,
            { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
        );
  }
  
GetContainerList( 
    requestInfo: IApiRequest<IContainerListRequest> ): Observable<IApiresponse<ContainerListResponse>> {
        let body = JSON.stringify(requestInfo);
        return this.http.post<IApiresponse<ContainerListResponse>>(
            `${environment.expenseApiUrl}GetContainerList`,
            body,
            { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
        );
  }

  AddContainer( 
    requestInfo: IApiRequest<ContainerRequest> ): Observable<IApiresponse<SaveContainerResponse>> {
        let body = JSON.stringify(requestInfo);
        return this.http.post<IApiresponse<SaveContainerResponse>>(
            `${environment.expenseApiUrl}AddContainer`,
            body,
            { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
        );
  }

  UpdateContainer( 
    requestInfo: IApiRequest<ContainerRequest> ): Observable<IApiresponse<SaveContainerResponse>> {
        let body = JSON.stringify(requestInfo);
        return this.http.post<IApiresponse<SaveContainerResponse>>(
            `${environment.expenseApiUrl}UpdateContainer`,
            body,
            { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
        );
  }

  GetContainerById(containerId: number): Observable<IApiresponse<ContainerResponse>> {
    let params = new HttpParams();
    params = params.append("containerId", containerId.toString());
    return this.http.get<IApiresponse<ContainerResponse>>(
        `${environment.expenseApiUrl}GetContainerById`,
        {
            params: params,
            headers: this.claimsHeaderService.createLoginAuthorizationHeader()
        }
    );
}

DeleteContainer( 
  requestInfo: IApiRequest<DeleteContainerRequest> ): Observable<IApiresponse<any>> {
      let body = JSON.stringify(requestInfo);
      return this.http.post<IApiresponse<any>>(
          `${environment.expenseApiUrl}DeleteContainer`,
          body,
          { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
      );
}

GetExpenseContainerList( 
  requestInfo: IApiRequest<IExpenseContainerListRequest> ): Observable<IApiresponse<ExpenseContainerResponse>> {
      let body = JSON.stringify(requestInfo);
      return this.http.post<IApiresponse<ExpenseContainerResponse>>(
          `${environment.expenseApiUrl}GetExpenseContainerList`,
          body,
          { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
      );
}

UpdateContainerStatus( 
  requestInfo: IApiRequest<IUpdateContainerRequest> ): Observable<IApiresponse<UpdateContainerResponse>> {
      let body = JSON.stringify(requestInfo);
      return this.http.post<IApiresponse<UpdateContainerResponse>>(
          `${environment.expenseApiUrl}UpdateContainerStatus`,
          body,
          { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
      );
}

MoveExpensesToContainer( 
  requestInfo: IApiRequest<MoveExpensesToContainerRequest> ): Observable<IApiresponse<MoveExpensesToContainerRequest>> {
      let body = JSON.stringify(requestInfo);
      return this.http.post<IApiresponse<any>>(
          `${environment.expenseApiUrl}MoveExpensesToContainer`,
          body,
          { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
      );
}

  
GetClaimantContainerListForApproval( 
  requestInfo: IApiRequest<IApproverContainerListRequest> ): Observable<IApiresponse<ApproverContainerListResponse>> {
      let body = JSON.stringify(requestInfo);
      return this.http.post<IApiresponse<ApproverContainerListResponse>>(
          `${environment.expenseApiUrl}GetClaimantContainerListForApproval`,
          body,
          { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
      );
}

}
