<h6>
    <span class="padding-right-10 eod-text-danger">
        <em class="fas fa-exclamation-triangle"></em> </span>
    {{ "policy_violation_2" | translate }}
</h6>
<div class="row" id="taxRateSetup-expiredSlab">
    <div class="col-md-12 col-sm-12 no-padding-right-left">
        <div id="accordion">

            <div class="card margin-top-0" *ngIf="advanceCappingLimit">
                <div class="card-header"
                    [ngClass]="{'card-header-claimant-downArrow': advanceCappingLimit}">
                    <a class="card-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true">
                        {{ "advance_limit_policy" | translate }}
                    </a>
                </div>
                <div id="collapseOne" class="collapse" [ngClass]="{'show': advanceCappingLimit}"
                    data-bs-parent="#accordion">
                    <div class="card-body padding-10">
                        <div class="row margin-top-0">
                            <div class="col-md-12 no-padding-right-left margin-top-0">
                                <div class="row">
                                    <div class="w-100pc" style="position: relative;">
                                        <div class="row margin-top-0">
                                          <ul class="tempUL policyViolation-duplicateExp">
                                            <li>
                                                <span class="float-left w-50pc">
                                                  {{ "advance_limit" | translate }}
                                                </span>
                                                <span class="float-right w-50pc d-inline-block text-right">
                                                    {{ model.advanceLimit }}
                                                </span>
                                            </li>
                                            <li>
                                              <span class="float-left w-50pc">
                                                {{ "advance_balance" | translate }}
                                              </span>
                                              <span class="float-right w-50pc d-inline-block text-right">
                                                  {{ model.passbookBalance }}
                                              </span>
                                            </li>
                                            <li>
                                              <span class="float-left w-50pc color-red">
                                                {{ "advance_amount" | translate }}
                                              </span>
                                              <span class="float-right w-50pc d-inline-block text-right color-red">
                                                  {{ model.amount }}
                                              </span>
                                            </li>
                                            <li>
                                              <span class="fw-600 float-left w-50pc color-red">
                                                {{ "advance_above_limit" | translate }}
                                              </span>
                                              <span class="fw-600 float-right w-50pc d-inline-block text-right color-red">
                                                  {{ model.advanceOverLimit }}
                                              </span>
                                            </li>
                                          </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
