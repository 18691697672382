<div class="overLayDiv active"></div>
<div id="rightSideBar">
    <div class="rightSidePanel active" style="z-index: 99999;">
        <header class="py-1 d-flex justify-content-between align-items-center height-60px">
            <h2>{{'select_customer' | translate:param}}</h2>
            <div>
                <button type="button" class="btn eod-btn-primary height-38px mr-2" (click)="fnSelectCustomer()"
                    [ngClass]="{ 'disabled': !this.hasFormChanges}">
                    {{ 'label_save' | translate: param }}
                </button>
                <button type="button" class="btn eod-btn-outline-warning height-38px" (click)="fnClosePanel()">
                    {{ 'label_close' | translate: param }}
                </button>
            </div>
        </header>
        <div class="clear"></div>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 margin-top-10 no-padding-right-left">
                <div class="block padding-top-0 ">
                    <div class="row margin-top-25">
                        <div class="col-lg-6 padding-left-0">
                            <div class="input-group">
                                <input type="text" class="form-control searchField" #searchField
                                    [ngClass]="{ 'has-value': searchField.value }" name="searchText"
                                    [(ngModel)]="searchText" autocomplete="off">
                                <label for="ExpenseCategory">{{ "label_search_criterion" | translate: param }}</label>
                                <span class="icons"><a>
                                        <i class="fas fa-search" aria-hidden="true"></i>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block padding-top-10 heightCalc-12 overflow-auto scrollbar-visible">
                    <div class="row">
                        <div class="col-lg-12 no-padding-right-left">
                            <h6 class="border-bottom padding-bottom-10">{{'select_customer' | translate:param}}</h6>

                            <div class="text-center noDataAvailable disabled opacity-60"
                                *ngIf="(customersList | grdFilter : {customerName: searchText, customerCode1: searchText}).length === 0">
                                {{ "no_data_available" | translate: param }}
                            </div>

                            <ul class="exportReportWrite-exportFile"
                                *ngIf="(customersList | grdFilter : {customerName: searchText, customerCode1: searchText}).length>0">
                                <li class="border-bottom padding-bottom-0 lH-40">
                                    <div id="accordion"
                                        *ngFor="let item of customersList | grdFilter: { customerName: searchText, customerCode1: searchText}"
                                        (click)="fnOnChangeCustomer(item.customerId);hasFormChanges = true;">
                                        <div class="card "
                                            [ngClass]="(customersList.length > 1)? 'margin-top-10':'margin-top-0'">
                                            <div
                                                class="card-header padding-top-0 padding-left-10 padding-bottom-0 text-ellipsis cursor-pointer">
                                                <span class="card-link padding-0" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne">
                                                    <input type="checkbox" (change)="hasFormChanges = true;"
                                                        [checked]="item.customerId ==selectedCustomerId">
                                                    {{item.customerName}} - {{item.customerCode1}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
