import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { BaseCategoryTypeEnum, ExpenseStatusEnum, ProjectBudgetEntityType } from 'src/app/_models';
import { SelectBusinessPurposePopupModel, SelectCostCentrePopupModel, SelectCustomerPopupModel, SelectExpenseCategoryPopupModel, SelectForignCurrencyPopupModel, SelectPreApprovalTagPopupModel, SelectProjectPopupModel, SelectReceiptTypePopupModel, SelectStatesPopupModel } from 'src/app/_models/claimant-on-web/claimant-on-web';
import { SelectAdditionalFieldDataPopupModel } from 'src/app/_models/policy/policy-data';
import { UTCDateTime } from 'src/app/common/commonvariables';
import { Constants } from 'src/util/constants';
import { SelectVehicleModel } from '../../../../_models/claimant-on-web/claimant-on-web';
import { DynamicFormService } from '../../dynamic-form/dynamic-form.service';
import { AlertPanelModel } from 'src/app/_models/common/alertPanelModel';
import { TranslateService } from "@ngx-translate/core";
import { SelectContainerInputModel } from "src/app/_models/container/container";

@Component({
  selector: 'formly-field-input',
  templateUrl: "./formly-input-panel.component.html"
})
export class FormlyFieldInputPanelComponent extends FieldType implements OnInit, OnDestroy {

  inputModel: any;
  showSelectExpenseCategoryPanel: boolean;
  showSelectPreApprovalTagPanel: boolean;
  showSelectForeignCurrencyPanel: boolean;
  showSelectStatesPanel: boolean;
  showSelectBusinessPurposePanel: boolean;
  showSelectAdditionalFieldDataPanel: boolean;
  showExpensePolicyPanel: boolean;
  showSelectCustomerPanel: boolean;
  showSelectProjectPanel: boolean;
  showSelectCostCentrePanel: boolean;
  showSelectReceiptTypePanel: boolean;
  showVehiclePanel: boolean;
  isFormSubmitted: boolean = false;
  formSubmissionSubscription: Subscription;
  openPanelSubscription: Subscription;
  showContainerListPanel: boolean;

  showPolicyInputModel: SelectExpenseCategoryPopupModel;
  alertPanelModel = new AlertPanelModel(
    false,
    "",
    [""],
    "",
    "",
    true,
    this.translate.instant("please_review_or_confirm")
  );
  isCostCentreSelectionHide : boolean = false;
  constructor(private dynamicFormService: DynamicFormService, private translate: TranslateService, private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.formSubmissionSubscription = this.dynamicFormService.getFormSubmittedEvent().subscribe(data => {
      this.isFormSubmitted = data;
      this.cdr.detectChanges();
    });
    this.openPanelSubscription = this.dynamicFormService.getOpenPanelEvent().subscribe(key => {
      if (key == this.field.key) {
        this.fnOpenSelectionPanel();
      }
    });

    if (this.field && this.field.templateOptions) {
      let isVisibleOnLoad = this.field.templateOptions.isVisibleOnLoad;

      if (isVisibleOnLoad && this.field.key == 'expenseCategoryName') {
        this.fnOpenSelectionPanel();
      }

      this.isCostCentreSelectionHide = this.field.templateOptions.isCostCentreSelectionHide;
    }
  }

  ngOnDestroy() {
    if (this.formSubmissionSubscription) {
      this.formSubmissionSubscription.unsubscribe();
    }
    if (this.openPanelSubscription) {
      this.openPanelSubscription.unsubscribe();
    }
  }

  fnOpenSelectionPanel() {
    if (this.field.key.toString().startsWith(Constants.ADDITIONAL_FIELD_DYNAMIC_KEY)) {
      let additionalFieldId = parseInt(this.field.key.toString().replace(Constants.ADDITIONAL_FIELD_DYNAMIC_KEY, ''));
      let additionalFieldValueId = this.model[this.field.key.toString() + Constants.ADDITIONAL_FIELD_DYNAMIC_ID_KEY];
      this.inputModel = new SelectAdditionalFieldDataPopupModel();
      this.inputModel.additionalFieldDataList = this.field.templateOptions.attributes.data;
      this.inputModel.additionalFieldId = additionalFieldId;
      this.inputModel.fieldValueId = additionalFieldValueId;
      this.showSelectAdditionalFieldDataPanel = true;
    }
    else {
      switch (this.field.key.toString()) {
        case 'preApprovalTag':
          this.inputModel = new SelectPreApprovalTagPopupModel();
          this.inputModel.preApprovalId = this.model.preApprovalId;
          this.inputModel.claimantId = this.model.userId;
          this.inputModel.claimantName = this.model.userName;
          this.showSelectPreApprovalTagPanel = true;
          break;
        case 'expenseCategoryName':
          this.inputModel = new SelectExpenseCategoryPopupModel();
          this.inputModel.orgExpenseCategoryId = this.model.orgExpenseCategoryId;
          this.inputModel.expenseDate = this.model.expenseDate;

          let disableBaseCategoryIds = [];
          if (this.model.isEditMode && this.model.saveStatus == ExpenseStatusEnum.IncompleteCardExpense) {
            disableBaseCategoryIds.push(BaseCategoryTypeEnum.Mileage);
            disableBaseCategoryIds.push(BaseCategoryTypeEnum.MileageGoogle);
            disableBaseCategoryIds.push(BaseCategoryTypeEnum.MileageGPS);
            if (this.model.amount < 0) {
              disableBaseCategoryIds.push(BaseCategoryTypeEnum.Entertainment);
            }

            this.inputModel.isIncompleteCreditCardExpense = true;
          }
          this.inputModel.disableBaseCategoryIds = disableBaseCategoryIds;
          this.inputModel.claimantId = this.model.userId;
          this.inputModel.claimantName = this.model.userName;
          this.inputModel.baseCategoryId = this.model.baseCategoryId;
          this.inputModel.isFmEditingExpense = this.model.isFmEditingExpense;
          this.inputModel.nrfcStatus = this.model.nrfcStatus;
          this.inputModel.receiptsInfo = this.model.receiptsInfo;

          this.showSelectExpenseCategoryPanel = true;
          break;
        case 'spentCurrencyCode':
        case 'currencyCode':
          if (!this.model.spentCurrencyId) {
            this.model.spentAmount = this.model.amount;
          }
          this.inputModel = new SelectForignCurrencyPopupModel();
          this.inputModel.expenseDate = this.model.expenseDate;
          this.inputModel.currencyId = this.model.currencyId;
          this.inputModel.currencyCode = this.model.currencyCode;
          this.inputModel.spentCurrencyId = this.model.spentCurrencyId;
          this.inputModel.spentCurrencyCode = this.model.spentCurrencyCode;
          this.inputModel.conversionRate = this.model.conversionRate;
          this.inputModel.baseCurrencyIcon = this.model.baseCurrencyIcon;
          this.showSelectForeignCurrencyPanel = true;
          break;
        case 'stateName':
          this.inputModel = new SelectStatesPopupModel();
          this.inputModel.stateId = this.model.stateId;
          this.inputModel.stateName = this.model.stateName;
          this.showSelectStatesPanel = true;
          break;
        case 'bpValue':
          this.inputModel = new SelectBusinessPurposePopupModel();
          this.inputModel.bpId = this.model.bpId;
          this.inputModel.bpValue = this.model.bpValue;
          this.inputModel.businessPurposeCaption = this.field.templateOptions.label.replace('Select ', '');
          this.showSelectBusinessPurposePanel = true;
          break;
        case 'vatNoVatReceiptType':
          this.inputModel = new SelectReceiptTypePopupModel();
          this.inputModel.vatNoVatReceiptTypeId = this.model.vatNoVatReceiptTypeId;
          this.inputModel.options = this.field.templateOptions.options;
          this.showSelectReceiptTypePanel = true;
          break;
        case 'derivedName':
          this.inputModel = new SelectVehicleModel();
          this.inputModel.vehicleId = this.model.mileage.vehicleId;
          this.inputModel.derivedName = this.model.mileage.derivedName;
          this.inputModel.registrationNumber = this.fnReturnRegNum(this.model.mileage.registrationNumber);
          this.inputModel.makeModel = this.fnReturnMakeModel(this.model.mileage.makeModel);
          this.inputModel.claimantId = this.model.userId;
          this.inputModel.claimantName = this.model.userName;
          
          this.inputModel.options = this.field.templateOptions.options;
          let todaysDate = this.getUTCTimeStamp(new Date());
          let expenseDateUnix = this.getUTCTimeStamp(new Date(this.model.expenseDate));
          if (expenseDateUnix < todaysDate) {
            this.fnDisplayAlertPanel()
          }
          else {
            this.showVehiclePanel = true;
          }

          break;
        case 'customerName':
          this.inputModel = new SelectCustomerPopupModel();
          this.inputModel.customerId = this.model.customerId;
          this.showSelectCustomerPanel = true;
          break;
        case 'newCostCentreName':
          this.inputModel = new SelectCostCentrePopupModel();
          this.inputModel.costCentreId = this.model.newCostCentreId;
          this.inputModel.isSelectionHide = this.isCostCentreSelectionHide;
          this.showSelectCostCentrePanel = true;
          break;
        case 'entityName':
        case 'projectName':
          this.inputModel = new SelectProjectPopupModel();
          this.inputModel.entityId = this.model.entityId;
          this.inputModel.projectDetailsId = this.model.projectDetailsId;

          this.inputModel.isCostCentrePackagePurchasedOrTrial = this.model.isCostCentrePackagePurchasedOrTrial;
          this.inputModel.isCustomerPackagePurchasedOrTrial = this.model.isCustomerPackagePurchasedOrTrial;
          this.inputModel.isProjectBudgetPurchasedOrTrial = this.model.isProjectBudgetPurchasedOrTrial;
          this.inputModel.claimantId = this.model.userId;
          this.inputModel.claimantName = this.model.userName;

          this.showSelectProjectPanel = true;
          break;
          case 'containerName':
            this.inputModel = new SelectContainerInputModel();       
            this.inputModel.claimantId = this.model.userId;
            this.inputModel.containerId = this.model.containerId;
            this.inputModel.containerName = this.model.containerName;
            this.showContainerListPanel = true;
           break;
      }
    }
  }
  getUTCTimeStamp(date) {
    let now_utc = Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      0,
      0
    );

    return now_utc;
  }
  fnDisplayAlertPanel() {
    this.alertPanelModel = new AlertPanelModel(
      true,
      this.translate.instant("label_alert"),
      [this.translate.instant("past_date_selected_veh_msg1")],
      "",
      this.translate.instant("oK_text"),
      true,
      ""
    );
  }
  alertPanelReceiver(returnValue) {
    this.alertPanelModel = new AlertPanelModel(
      false,
      "",
      [""],
      "",
      "",
      true,
      this.translate.instant("please_review_or_confirm")
    );
  }
  closeSelectionPanel(isClose) {
    if (isClose) {
      this.hidePanel();
    }
  }

  onSelection(data) {
    let value = data.value;

    if (this.field.key == 'preApprovalTag') {
      this.model.preApprovalId = data.preApprovalId;
      this.model.preApprovalTag = data.preApprovalTag;
      this.model.preApprovalBalance = data.preApprovalBalance;
      value = data.preApprovalTag;
    }
    else if (this.field.key == 'expenseCategoryName') {
      value = data.expenseCategoryName;
      this.model.customFields = data.customFields;
      this.model.categoryAdditionalFieldData = data.categoryAdditionalFieldData;
      this.model.isNotesMandatory = data.isNotesMandatory;
      this.model.isCFPReporting = data.isCFPReporting;
      this.model.baseCategoryId = data.baseCategoryId;
      this.model.orgSubCategoryMappings = data.orgSubCategoryMappings;
      data.amount = this.model.amount;
      data.receiptsInfo = this.model.receiptsInfo;
      data.expenseDate = this.model.expenseDate;
      data.supplier = this.model.supplier;
    }
    else if (this.field.key === 'spentCurrencyCode') {
      value = data.spentCurrencyCode;
    }
    else if (this.field.key === 'currencyCode') {
      value = data.currencyCode;
    }
    else if (this.field.key == 'bpValue') {
      value = data.bpValue;
      this.model.bpId = data.bpId;
    }
    else if (this.field.key == 'stateName') {
      value = data.stateName;
      this.model.stateId = data.stateId;
      this.model.stateName = data.stateName;
    }
    else if (this.field.key.toString().startsWith(Constants.ADDITIONAL_FIELD_DYNAMIC_KEY)) {
      value = data.fieldValue;
    }
    else if (this.field.key == 'vatNoVatReceiptType') {
      value = data.vatNoVatReceiptType;
      this.model.vatNoVatReceiptType = data.vatNoVatReceiptType;
      this.model.vatNoVatReceiptTypeId = data.vatNoVatReceiptTypeId;
    }
    else if (this.field.key == 'customerName') {
      value = data.customerName;
      this.model.customerId = data.customerId;
      this.model.customerName = data.customerName;
    }
    else if (this.field.key == 'newCostCentreName') {
      value = data.costCentreName;
      this.model.newCostCentreId = data.costCentreId;
      this.model.newCostCentreName = data.costCentreName;
    }
    else if (this.field.key == 'derivedName') {
      value = data.derivedName;
      this.model.mileage.vehicleId = data.vehicleId;
      this.model.mileage.registrationNumber = this.fnReturnRegNum(data.registrationNumber);
      this.model.mileage.makeModel = this.fnReturnMakeModel(data.makeModel);
      this.model.mileage.derivedName = data.derivedName;
      this.model.mileage.vehicleTypeId = data.vehicleTypeId;
      this.model.mileage.orgVehicleTypeMapId = data.orgVehicleTypeMapId;
      this.model.mileage.mileageAllowance = data.mileageAllowance;
      this.model.mileage.docStatus = data.docStatus;
    }
    else if (this.field.key == 'entityName' || this.field.key == 'projectName') {
      value = data[this.field.key];
      this.model.customerId = data.entityType == ProjectBudgetEntityType.Customer ? data.entityId : 0;
      this.model.customerName = data.entityType == ProjectBudgetEntityType.Customer ? data.entityName : null;
      this.model.projectDetailsId = data.projectDetailsId;
      this.model.projectName = data.projectName;
      this.model.projectBudgetDetailsId = data.projectBudgetDetailsId;
      this.model.newCostCentreId = data.entityType == ProjectBudgetEntityType.CostCentre ? data.entityId : 0;
      this.model.entityId = data.entityId;
      this.model.entityType = data.entityType;
      this.model.entityName = data.entityName;
    }
    else if(this.field.key == 'containerName'){
      value = data[this.field.key];
      this.model.containerId = data.containerId;
      this.model.containerName = data.containerName;
    }

    this.formControl.setValue(value);
    this.to.change(this.field, data);
    this.hidePanel();
  }

  hidePanel() {
    this.showSelectForeignCurrencyPanel = false;
    this.showSelectExpenseCategoryPanel = false;
    this.showSelectPreApprovalTagPanel = false;
    this.showSelectStatesPanel = false;
    this.showSelectBusinessPurposePanel = false;
    this.showSelectAdditionalFieldDataPanel = false;
    this.showExpensePolicyPanel = false;
    this.showSelectCustomerPanel = false;
    this.showSelectCostCentrePanel = false;
    this.showSelectProjectPanel = false;
    this.showSelectReceiptTypePanel = false;
    this.showVehiclePanel = false;
    this.showContainerListPanel = false;
  }

  fnShowExpPolicy() {
    if (this.model.orgExpenseCategoryId) {
      this.showPolicyInputModel = new SelectExpenseCategoryPopupModel();
      this.showPolicyInputModel.orgExpenseCategoryId = this.model.orgExpenseCategoryId;
      this.showPolicyInputModel.expenseCategoryName = this.model.expenseCategoryName;
      this.showPolicyInputModel.claimantId = this.model.userId;
      this.showPolicyInputModel.expenseDate =
        new Date(UTCDateTime.getUTCDate(new Date(this.model.expenseDate))).valueOf();
      this.showExpensePolicyPanel = true;
    }
  }
  fnReturnRegNum(registrationNumber) {
    let regNum = '';
    if (registrationNumber == '' || registrationNumber == null
      || !registrationNumber) {
      regNum = '-';
    }
    else {
      regNum = registrationNumber;
    }
    return regNum;
  }
  fnReturnMakeModel(makeModel) {

    let makeMod = '';
    if (makeModel == '' || makeModel == null
      || !makeModel) {
      makeMod = '';
    }
    else {
      makeModel = makeModel.replace('(', '');
      makeModel = makeModel.replace(' ', '');
      makeModel = makeModel.replace(')', '');
      makeMod = ' (' + makeModel + ')';
    }
    return makeMod;
  }
}
