import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Claims } from "src/app/_models/common/claims";
import { ClaimsService } from "../common/claims.service";
import { environment } from "src/environments/environment";
import { AccountingSystemMasterEnum, AccountingSystemTypeEnum, IApiRequest, IApiresponse } from "src/app/_models";
import { Observable } from "rxjs";
import { IConnectAccountingSystemRequest } from "src/app/_models/AccountingIntegration/connect-accounting-system-request";
import { IGetAllAccountingSystemRequest } from "src/app/_models/AccountingIntegration/get-all-accounting-system-request";
import { IGetAllAccountingSystemResponse } from "src/app/_models/AccountingIntegration/get-all-accounting-system-response";
import { IRequestOrganisationAccountingIntegrationSteps, OrgAccIntStepsReq } from "src/app/_models/AccountingIntegration/requestOrganisationAccountingIntegrationSteps";
import { IResponseOrganisationAccountingIntegrationStepsList } from "src/app/_models/AccountingIntegration/responseOrganisationAccountingIntegrationStepsList";
import { IRequestEnableDisableAccountingIntegrationStep } from "src/app/_models/AccountingIntegration/requestEnableDisableAccountingIntegrationStep";
import { IRequestTurnOnOffAccountingIntegration } from "src/app/_models/AccountingIntegration/requestTurnOnOffAccountingIntegration";
import { IConnectAccountingSystemResponse } from "src/app/_models/AccountingIntegration/connectAccountingSystemResponse";
import { IXeroAuthorizeRequest } from "src/app/_models/AccountingIntegration/xeroAuthorizeRequest";
import { IConnectAccountingSystemTokenResponse } from "src/app/_models/AccountingIntegration/connectAccountingSystemTokenResponse";
import { IRequestSyncMasterData } from "src/app/_models/AccountingIntegration/requestSyncMasterData";
import { ISyncMasterDataResponse } from "src/app/_models/AccountingIntegration/syncMasterDataResponse";
import { IRequestAccountingSystemTokenCheck } from "src/app/_models/AccountingIntegration/requestAccountingSystemTokenCheck";
import { IManageCategoryListRequest } from "src/app/_models/manage-category/manage-category-list-request";
import { IManageCategoryListResponse } from "src/app/_models/manage-category/manage-category-list-response";
import { IRequestSaveAccountingSystemConfigration, IUpdateConfigurationSetupRequest } from "src/app/_models/AccountingIntegration/requestSaveAccountingSystemConfigration";
import { IResponseAccountingSystemConfigurationSettings } from "src/app/_models/AccountingIntegration/responseAccountingSystemConfigurationSettings";
import { IRequestGetAccountingSystemConfigurationSettings } from "src/app/_models/AccountingIntegration/requestGetAccountingSystemConfigurationSettings";
import { IRequestGetEmployeesForAccountingIntegration } from "src/app/_models/AccountingIntegration/requestGetEmployeesForAccountingIntegration";
import { IResponseGetEmployeesForAccountingIntegration } from "src/app/_models/AccountingIntegration/responseGetEmployeesForAccountingIntegration";
import { IAccountingIntegrationModelForEmployees } from "src/app/_models/AccountingIntegration/accountingIntegrationModelForEmployees";
import { IGetAccountingSystemApiRequest } from "src/app/_models/AccountingIntegration/getAccountingSystemApiRequest";
import { IAccountingIntegrationModelForCategories } from "src/app/_models/AccountingIntegration/accounting-integration-model-for-categories";
import { IAccountingIntegrationModelForTax } from "src/app/_models/AccountingIntegration/accounting-integration-model-for-tax";
import { ITaxRateMasterAccountingResponse } from "src/app/_models/AccountingIntegration/tax-rate-master-accounting-response";
import { IRequestSaveEmployeeMappingWithAccountingIntegration } from "src/app/_models/AccountingIntegration/requestSaveEmployeeMappingWithAccountingIntegration";
import { ICategoryMappingWithAccountingIntegrationRequest } from "src/app/_models/AccountingIntegration/category-mapping-with-accounting-integration-request";
import { IRequestCheckActiveEmployeeMappedWithAccountingIntegration } from "src/app/_models/AccountingIntegration/requestCheckActiveEmployeeMappedWithAccountingIntegration";
import { IResponseCheckActiveEmployeeMappedWithAccountingIntegration } from "src/app/_models/AccountingIntegration/responseCheckActiveEmployeeMappedWithAccountingIntegration";
import { IResponseGetTaxesForAccountingIntegration } from "src/app/_models/AccountingIntegration/responseGetTaxesForAccountingIntegration";
import { IRequestGetTaxesForAccountingIntegration } from "src/app/_models/AccountingIntegration/requestGetTaxesForAccountingIntegration";
import { IRequestSaveTaxMappingWithAccountingIntegration } from "src/app/_models/AccountingIntegration/requestSaveTaxMappingWithAccountingIntegration";
import { IResponseCheckActiveTaxMappedWithAccountingIntegration } from "src/app/_models/AccountingIntegration/responseCheckActiveTaxMappedWithAccountingIntegration";
import { IRequestCheckActiveTaxMappedWithAccountingIntegration } from "src/app/_models/AccountingIntegration/requestCheckActiveTaxMappedWithAccountingIntegration";
import { IRequestCheckActiveCategoryMappedWithAccountingIntegration } from "src/app/_models/AccountingIntegration/request-check-active-category-mapped-with-accounting-integration";
import { IResponseCheckActiveCategoryMappedWithAccountingIntegration } from "src/app/_models/AccountingIntegration/response-check-active-category-mapped-with-accounting-integration";
import { IQuickBooksAuthorizeRequest } from "src/app/_models/AccountingIntegration/quick-books-authorize-request";
import { IManageCategoryAddUpdateResponse } from "src/app/_models/manage-category/manage-category-add-update-response";
import { IRequestGetColumnList } from "src/app/_models/AccountingIntegration/requestGetColumnList";
import { IResponseAccountingSysFilteredColumnList } from "src/app/_models/AccountingIntegration/responseAccountingSysFilteredColumnList";
import { IRequestSaveOrgNameAndColumnSelection } from "src/app/_models/AccountingIntegration/requestSaveOrgNameAndColumnSelection";
import { IResponseCheckColumnSettingStepsComplete } from "src/app/_models/AccountingIntegration/responseCheckColumnSettingStepsComplete";
import { IRequestCheckColumnSettingStepsComplete } from "src/app/_models/AccountingIntegration/requestCheckColumnSettingStepsComplete";
import { ITaxesForTallyAccountingIntegrationResponse } from "src/app/_models/AccountingIntegration/taxes-for-tally-accounting-integration-response";
import { ICheckTallyColumnSelectionRequest } from "src/app/_models/AccountingIntegration/check-tally-column-selection-request";
import { ISaveOrgNameAndColumnSelectionResponse } from "src/app/_models/AccountingIntegration/save-org-name-and-column-selection-response";
import { IRequestCheckActiveCostCentreMappedWithAccountingIntegration } from "src/app/_models/AccountingIntegration/requestCheckActiveCostCentreMappedWithAccountingIntegration";
import { IResponseCheckActiveCostCentreMappedWithAccountingIntegration } from "src/app/_models/AccountingIntegration/responseCheckActiveCostCentreMappedWithAccountingIntegration";
import { IRequestSaveCostCentreMappingWithAccountingIntegration } from "src/app/_models/AccountingIntegration/requestSaveCostCentreMappingWithAccountingIntegration";
import { IResponseGetCostCentreForAccountingIntegration } from "src/app/_models/AccountingIntegration/responseGetCostCentreForAccountingIntegration";
import { IRequestGetCostCentreForAccountingIntegration } from "src/app/_models/AccountingIntegration/requestGetCostCentreForAccountingIntegration";
import { IRequestCheckAllStepsComplete } from "src/app/_models/AccountingIntegration/requestCheckAllStepsComplete";
import { IResponseCheckAllStepsComplete } from "src/app/_models/AccountingIntegration/responseCheckAllStepsComplete";
import { IUpdateAccountingParameterForCCRequest } from "src/app/_models/AccountingIntegration/update-accounting-parameter-for-ccrequest";
import { ClaimsHeaderService } from "src/app/shared/claimsHeader/claims-header.service";
import { IRequestGetDataForAccountingTrackingCategory } from "src/app/_models/AccountingIntegration/requestGetDataForAccountingTrackingCategory";
import { IResponseGetDataForAccountingTrackingCategory } from "src/app/_models/AccountingIntegration/responseGetDataForAccountingTrackingCategory";
import { IRequestSaveEntityMappingWithAccountingTrackingCategories } from "src/app/_models/AccountingIntegration/requestSaveEntityMappingWithAccountingTrackingCategories";
import { IGetTrackingCategoryApiRequest } from "src/app/_models/AccountingIntegration/getTrackingCategoryApiRequest";
import { IAccountingIntegrationModelForTrackingCategories } from "src/app/_models/AccountingIntegration/accountingIntegrationModelForTrackingCategories";
import { IAccountingIntegrationAdditionalStepsModel } from "src/app/_models/AccountingIntegration/accountingIntegrationAdditionalStepsModel";
import { IRequestCheckEntityMappingWithAccountingTrackingCategories } from "src/app/_models/AccountingIntegration/requestCheckEntityMappingWithAccountingTrackingCategories";
import { IResponseCheckEntityMappedWithAccountingIntegration } from "src/app/_models/AccountingIntegration/responseCheckEntityMappedWithAccountingIntegration";
import { AccIntExpCategoryModel, AccIntRequest, AccIntWithEntityRequest, AddAccountingFieldsMappingRequest, 
   CompanyAcctIntStep, DeleteTokenRequest, GetReportFieldDataForIntegrationResponse, 
  GetReportFieldMasterForIntegrationRequest, MappingDataRequest, OrgAcctIntSteps,
  IAccSysCreditCardMappingRequest,IAccSysCreditCardMappingResponse} from "src/app/_models/AccountingIntegration/common";
import { map, mergeMap } from "rxjs/operators";
import { SuppliersForIntegrationGrid } from "src/app/_models/supplier/supplier";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root"
})
export class AccountingIntegrationService {
  orgId: number;
  userId: number;
  token: string;
  organisationCurrency: string;
  claims: Claims;
  constructor(private http: HttpClient, private claimsService: ClaimsService, private claimsHeaderService: ClaimsHeaderService, private translate: TranslateService) {
    this.claimsService.currentClaims.subscribe(
      claims => (this.claims = claims)
    );
  }

  SoloGetAccountingSystemDetail(
    requestinfo: IApiRequest<IGetAllAccountingSystemRequest>
  ): Observable<IApiresponse<IGetAllAccountingSystemResponse[]>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IGetAllAccountingSystemResponse[]>>(
      `${environment.exportApiUrl}GetAccountingSystemDetail`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetAccountingIntegrationSteps(
    requestInfo: IApiRequest<IRequestOrganisationAccountingIntegrationSteps>
  ): Observable<
    IApiresponse<IResponseOrganisationAccountingIntegrationStepsList>
  > {
    
    let body = JSON.stringify(requestInfo);
    return this.http.post<
      IApiresponse<IResponseOrganisationAccountingIntegrationStepsList>
    >(`${environment.exportApiUrl}GetAccountingIntegrationSteps`, body, {
      headers: this.claimsHeaderService.createLoginAuthorizationHeader()
    });
  }

  SoloTurnOnOffAccountingIntegration(
    requestInfo: IApiRequest<IRequestTurnOnOffAccountingIntegration>
  ): Observable<IApiresponse<any>> {
    
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.exportApiUrl}TurnOnOffAccountingIntegration`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloConnectToAccounting(
    requestInfo: IApiRequest<IConnectAccountingSystemRequest>
  ): Observable<IApiresponse<IConnectAccountingSystemResponse>> {
    
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IConnectAccountingSystemResponse>>(
      `${environment.exportApiUrl}ConnectToAccounting`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  XeroAuthorize(
    requestInfo: IApiRequest<IXeroAuthorizeRequest>
  ): Observable<IApiresponse<IConnectAccountingSystemTokenResponse>> {
    
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IConnectAccountingSystemTokenResponse>>(
      `${environment.exportApiUrl}XeroAuthorize`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }
  QuickBooksAuthorize(
    requestInfo: IApiRequest<IQuickBooksAuthorizeRequest>
  ): Observable<IApiresponse<IConnectAccountingSystemTokenResponse>> {
    
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IConnectAccountingSystemTokenResponse>>(
      `${environment.exportApiUrl}QuickBooksAuthorize`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloXeroSyncMasterData(
    requestInfo: IApiRequest<IRequestSyncMasterData>
  ): Observable<IApiresponse<ISyncMasterDataResponse>> {
    
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<ISyncMasterDataResponse>>(
      `${environment.exportApiUrl}SyncMasterData`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloSyncAccountingMasterData(
    requestInfo: IApiRequest<IRequestSyncMasterData>
  ): Observable<IApiresponse<ISyncMasterDataResponse>> {
    
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<ISyncMasterDataResponse>>(
      `${environment.exportApiUrl}SyncAccountingMasterData`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloCheckToken(
    requestInfo: IApiRequest<IRequestAccountingSystemTokenCheck>
  ): Observable<IApiresponse<IConnectAccountingSystemTokenResponse>> {
    
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IConnectAccountingSystemTokenResponse>>(
      `${environment.exportApiUrl}CheckToken`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloSaveAccountingSystemConfigurationSettings(
    requestInfo: IApiRequest<IRequestSaveAccountingSystemConfigration>
  ): Observable<IApiresponse<any>> {
    
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.exportApiUrl}SaveAccountingSystemConfigurationSettings`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetAccountingSystemConfigurationSettings(
    requestInfo: IApiRequest<IRequestGetAccountingSystemConfigurationSettings>
  ): Observable<IApiresponse<IResponseAccountingSystemConfigurationSettings>> {
    
    let body = JSON.stringify(requestInfo);
    return this.http.post<
      IApiresponse<IResponseAccountingSystemConfigurationSettings>
    >(
      `${environment.exportApiUrl}GetAccountingSystemConfigurationSettings`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }
  SoloGetOrgCategoryByOrgCategoryId(
    orgExpenseCategoryId
  ): Observable<IApiresponse<IManageCategoryListResponse>> {
    let params = new HttpParams();
    params = params.append("orgExpenseCategoryId", orgExpenseCategoryId);
    let url = `${environment.billingApiUrl}GetOrgCategoryByOrgCategoryId`;
    return this.http.get<IApiresponse<IManageCategoryListResponse>>(url,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader(), params: params });
  }

  SoloGetOrgManageCatListForAccounting(
    requestinfo: IApiRequest<IManageCategoryListRequest>
  ): Observable<IApiresponse<IManageCategoryListResponse[]>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IManageCategoryListResponse[]>>(
      `${environment.billingApiUrl}GetOrgManageCatListForAccounting`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetEmployeeForAccountingIntegration(
    requestInfo: IApiRequest<IRequestGetEmployeesForAccountingIntegration>
  ): Observable<IApiresponse<IResponseGetEmployeesForAccountingIntegration[]>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<
      IApiresponse<IResponseGetEmployeesForAccountingIntegration[]>
    >(
      `${environment.billingApiUrl}GetEmployeeForAccountingIntegration`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetEmployeesDataByAccountingSystemModelId(
    requestInfo: IApiRequest<IGetAccountingSystemApiRequest>
  ): Observable<IApiresponse<IAccountingIntegrationModelForEmployees[]>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<
      IApiresponse<IAccountingIntegrationModelForEmployees[]>
    >(
      `${environment.exportApiUrl}GetEmployeesDataByAccountingSystemModelId`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetTaxRateByAccountingSystemModelId(
    requestinfo: IApiRequest<IGetAccountingSystemApiRequest>
  ): Observable<IApiresponse<IAccountingIntegrationModelForTax[]>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IAccountingIntegrationModelForTax[]>>(
      `${environment.exportApiUrl}GetTaxRateByAccountingSystemModelId`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetProjectsByAccountingSystemModelId(
    requestinfo: IApiRequest<IGetAccountingSystemApiRequest>
  ): Observable<IApiresponse<IAccountingIntegrationModelForTax[]>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IAccountingIntegrationModelForTax[]>>(
      `${environment.exportApiUrl}GetProjectsByAccountingSystemModelId`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetTaxRateMasterAccounting(
    requestinfo: IApiRequest<IGetAccountingSystemApiRequest>
  ): Observable<IApiresponse<ITaxRateMasterAccountingResponse[]>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<ITaxRateMasterAccountingResponse[]>>(
      `${environment.exportApiUrl}GetTaxRateMasterAccounting`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }
  SoloGetAccountsByAccountingSystemModelId(
    requestinfo: IApiRequest<IGetAccountingSystemApiRequest>
  ): Observable<IApiresponse<IAccountingIntegrationModelForCategories[]>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<
      IApiresponse<IAccountingIntegrationModelForCategories[]>
    >(
      `${environment.exportApiUrl}GetAccountsByAccountingSystemModelId`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloSaveEmployeeMappingWithAccountingIntegration(
    requestinfo: IApiRequest<
      IRequestSaveEmployeeMappingWithAccountingIntegration[]
    >
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}SaveEmployeeMappingWithAccountingIntegration`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloCheckActiveEmployeeMappedWithAccountingIntegration(
    requestinfo: IApiRequest<
      IRequestCheckActiveEmployeeMappedWithAccountingIntegration
    >
  ): Observable<
    IApiresponse<IResponseCheckActiveEmployeeMappedWithAccountingIntegration>
  > {
    let body = JSON.stringify(requestinfo);
    return this.http.post<
      IApiresponse<IResponseCheckActiveEmployeeMappedWithAccountingIntegration>
    >(
      `${environment.billingApiUrl}CheckActiveEmployeeMappedWithAccountingIntegration`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }
  SoloSaveCategoryMappingWithAccountingIntegration(
    requestinfo: IApiRequest<ICategoryMappingWithAccountingIntegrationRequest[]>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}SaveCategoryMappingWithAccountingIntegration`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetTaxesForAccountingIntegration(
    requestinfo: IApiRequest<IRequestGetTaxesForAccountingIntegration>
  ): Observable<IApiresponse<IResponseGetTaxesForAccountingIntegration[]>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<
      IApiresponse<IResponseGetTaxesForAccountingIntegration[]>
    >(
      `${environment.billingApiUrl}GetTaxesForAccountingIntegration`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetTallyTaxesForAccountingIntegration(
    requestinfo: IApiRequest<IRequestGetTaxesForAccountingIntegration>
  ): Observable<IApiresponse<ITaxesForTallyAccountingIntegrationResponse[]>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<
      IApiresponse<ITaxesForTallyAccountingIntegrationResponse[]>
    >(
      `${environment.billingApiUrl}GetTallyTaxesForAccountingIntegration`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloSaveTaxMappingWithAccountingIntegration(
    requestinfo: IApiRequest<IRequestSaveTaxMappingWithAccountingIntegration[]>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}SaveTaxMappingWithAccountingIntegration`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloSaveTallyTaxMappingWithAccountingIntegration(
    requestinfo: IApiRequest<ITaxesForTallyAccountingIntegrationResponse[]>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}SaveTallyTaxMappingWithAccountingIntegration`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloCheckActiveTaxMappedWithAccountingIntegration(
    requestinfo: IApiRequest<
      IRequestCheckActiveTaxMappedWithAccountingIntegration
    >
  ): Observable<
    IApiresponse<IResponseCheckActiveTaxMappedWithAccountingIntegration>
  > {
    let body = JSON.stringify(requestinfo);
    return this.http.post<
      IApiresponse<IResponseCheckActiveTaxMappedWithAccountingIntegration>
    >(
      `${environment.billingApiUrl}CheckActiveTaxMappedWithAccountingIntegration`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloCheckActiveCategoryMappedWithAccountingIntegration(
    requestinfo: IApiRequest<
      IRequestCheckActiveCategoryMappedWithAccountingIntegration
    >
  ): Observable<
    IApiresponse<IResponseCheckActiveCategoryMappedWithAccountingIntegration>
  > {
    let body = JSON.stringify(requestinfo);
    return this.http.post<
      IApiresponse<IResponseCheckActiveCategoryMappedWithAccountingIntegration>
    >(
      `${environment.billingApiUrl}CheckActiveCategoryMappedWithAccountingIntegration`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloAddUpdateOrgCategory(
    requestinfo: IApiRequest<IManageCategoryListResponse>
  ): Observable<IApiresponse<IManageCategoryAddUpdateResponse>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IManageCategoryAddUpdateResponse>>(
      `${environment.billingApiUrl}AddUpdateOrgCategory`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetColumnList(
    requestinfo: IApiRequest<IRequestGetColumnList>
  ): Observable<IApiresponse<IResponseAccountingSysFilteredColumnList>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<
      IApiresponse<IResponseAccountingSysFilteredColumnList>
    >(`${environment.exportApiUrl}GetColumnList`, body, {
      headers: this.claimsHeaderService.createLoginAuthorizationHeader()
    });
  }

  SoloSaveOrgNameAndColumnSelection(
    requestinfo: IApiRequest<IRequestSaveOrgNameAndColumnSelection>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<
      IApiresponse<any>
    >(`${environment.exportApiUrl}SaveOrgNameAndColumnSelection`, body, {
      headers: this.claimsHeaderService.createLoginAuthorizationHeader()
    });
  }

  SoloCheckTallyColumnSelection(
    requestinfo: IApiRequest<ICheckTallyColumnSelectionRequest>
  ): Observable<IApiresponse<ISaveOrgNameAndColumnSelectionResponse[]>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<
      IApiresponse<ISaveOrgNameAndColumnSelectionResponse[]>
    >(`${environment.exportApiUrl}CheckTallyColumnSelection`, body, {
      headers: this.claimsHeaderService.createLoginAuthorizationHeader()
    });
  }

  SoloCheckColumnSettingStepsComplete(
    requestinfo: IApiRequest<IRequestCheckColumnSettingStepsComplete>
  ): Observable<IApiresponse<IResponseCheckColumnSettingStepsComplete>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<
      IApiresponse<IResponseCheckColumnSettingStepsComplete>
    >(
      `${environment.exportApiUrl}CheckColumnSettingStepsComplete`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetCostCentreForAccountingIntegration(
    requestInfo: IApiRequest<IRequestGetCostCentreForAccountingIntegration>
  ): Observable<
    IApiresponse<IResponseGetCostCentreForAccountingIntegration[]>
  > {
    let body = JSON.stringify(requestInfo);
    return this.http.post<
      IApiresponse<IResponseGetCostCentreForAccountingIntegration[]>
    >(
      `${environment.billingApiUrl}GetCostCentreForAccountingIntegration`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloSaveCostCentreMappingWithAccountingIntegration(
    requestinfo: IApiRequest<
      IRequestSaveCostCentreMappingWithAccountingIntegration[]
    >
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}SaveCostCentreMappingWithAccountingIntegration`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloCheckActiveCostCentreMappedWithAccountingIntegration(
    requestinfo: IApiRequest<
      IRequestCheckActiveCostCentreMappedWithAccountingIntegration
    >
  ): Observable<
    IApiresponse<IResponseCheckActiveCostCentreMappedWithAccountingIntegration>
  > {
    let body = JSON.stringify(requestinfo);
    return this.http.post<
      IApiresponse<
        IResponseCheckActiveCostCentreMappedWithAccountingIntegration
      >
    >(
      `${environment.billingApiUrl}CheckActiveCostCentreMappedWithAccountingIntegration`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloIntegrationAllStepsComplete(
    requestinfo: IApiRequest<IRequestCheckAllStepsComplete>
  ): Observable<IApiresponse<IResponseCheckAllStepsComplete>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IResponseCheckAllStepsComplete>>(
      `${environment.exportApiUrl}CheckAllStepsComplete`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetOrgAccountingSysSettingByOrgId(orgId: number): Observable<IApiresponse<any>> {
    let url = `${environment.exportApiUrl}GetOrgAccountingSysSettingByOrgId/` + orgId + ``;
    return this.http.get<IApiresponse<any>>(url, {
      headers: this.claimsHeaderService.createLoginAuthorizationHeader()
    });
  }

  SoloUpdateAccountingParameterForCC(
    requestinfo: IApiRequest<IUpdateAccountingParameterForCCRequest>
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.exportApiUrl}UpdateAccountingParameterForCC`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetTrackingCategoryDataByAccountingSystemModelId(
    requestInfo: IApiRequest<IGetTrackingCategoryApiRequest>
  ): Observable<
    IApiresponse<IAccountingIntegrationModelForTrackingCategories[]>
  > {
    let body = JSON.stringify(requestInfo);
    return this.http.post<
      IApiresponse<IAccountingIntegrationModelForTrackingCategories[]>
    >(
      `${environment.exportApiUrl}GetTrackingCategoryDataByAccountingSystemModelId`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetDataForAccountingTrackingCategory(
    requestInfo: IApiRequest<IRequestGetDataForAccountingTrackingCategory>
  ): Observable<
    IApiresponse<IResponseGetDataForAccountingTrackingCategory[]>
  > {
    let body = JSON.stringify(requestInfo);
    return this.http.post<
      IApiresponse<IResponseGetDataForAccountingTrackingCategory[]>
    >(
      `${environment.billingApiUrl}GetDataForAccountingTrackingCategory`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloSaveEntityMappingWithAccountingTrackingCategory(
    requestinfo: IApiRequest<
      IRequestSaveEntityMappingWithAccountingTrackingCategories
    >
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}SaveEntityMappingWithAccountingTrackingCategory`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  SoloGetAdditionalStepsByAccountingSystemId(
    accountingSystemId
  ): Observable<IApiresponse<IAccountingIntegrationAdditionalStepsModel[]>> {
    let url =
      `${environment.catalogApiUrl}GetAdditionalStepsByAccountingSystemId/` +
      accountingSystemId +
      ``;
    return this.http.get<IApiresponse<IAccountingIntegrationAdditionalStepsModel[]>>(url, {
      headers: this.claimsHeaderService.createLoginAuthorizationHeader()
    });
  }

  SoloCheckEntityMappedWithAccountingTrackingCategory(
    requestinfo: IApiRequest<
      IRequestCheckEntityMappingWithAccountingTrackingCategories
    >
  ): Observable<
    IApiresponse<IResponseCheckEntityMappedWithAccountingIntegration>
  > {
    let body = JSON.stringify(requestinfo);
    return this.http.post<
      IApiresponse<
        IResponseCheckEntityMappedWithAccountingIntegration
      >
    >(
      `${environment.billingApiUrl}CheckEntityMappedWithAccountingTrackingCategory`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetReportFieldMasterForIntegration(
    requestinfo: IApiRequest<
      GetReportFieldMasterForIntegrationRequest
    >
  ): Observable<
    IApiresponse<GetReportFieldDataForIntegrationResponse>
  > {
    let body = JSON.stringify(requestinfo);
    return this.http.post<
      IApiresponse<GetReportFieldDataForIntegrationResponse>
    >(
      `${environment.exportApiUrl}GetReportFieldMasterForIntegration`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  AddAccountingFieldsMapping(requestInfo: IApiRequest<AddAccountingFieldsMappingRequest>): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(`${environment.exportApiUrl}AddAccountingFieldsMapping`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  DeleteToken(requestInfo: IApiRequest<DeleteTokenRequest>): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(`${environment.exportApiUrl}DeleteToken`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }


  //New Methods

  GetAccSystemDetail(
    requestinfo: IApiRequest<AccIntRequest>
  ): Observable<IApiresponse<IGetAllAccountingSystemResponse[]>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IGetAllAccountingSystemResponse[]>>(
      `${environment.exportApiUrl}GetAccSystemDetail`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetAccIntSteps(
    requestInfo: IApiRequest<OrgAccIntStepsReq>
  ): Observable<
    IApiresponse<IResponseOrganisationAccountingIntegrationStepsList>
  > {

    let body = JSON.stringify(requestInfo);
    return this.http.post<
      IApiresponse<IResponseOrganisationAccountingIntegrationStepsList>
    >(`${environment.exportApiUrl}GetAccIntSteps`, body, {
      headers: this.claimsHeaderService.createLoginAuthorizationHeader()
    });
  }

  GetEmployeeForAccInt(
    requestInfo: IApiRequest<AccIntRequest>
  ): Observable<IApiresponse<IResponseGetEmployeesForAccountingIntegration[]>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IResponseGetEmployeesForAccountingIntegration[]>>(`${environment.billingApiUrl}GetEmployeeForAccIntCompany`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  SaveMappingData(
    requestinfo: IApiRequest<
      MappingDataRequest
    >
  ): Observable<IApiresponse<any>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.billingApiUrl}SaveMappingData`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  GetTaxesForAccInt(
    requestinfo: IApiRequest<AccIntRequest>
  ): Observable<IApiresponse<IResponseGetTaxesForAccountingIntegration[]>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<IResponseGetTaxesForAccountingIntegration[]>>(`${environment.billingApiUrl}GetTaxesForAccIntCompany`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  GetProjectsForAccInt(
    requestinfo: IApiRequest<AccIntRequest>
  ): Observable<IApiresponse<any[]>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<any[]>>(`${environment.billingApiUrl}GetProjectsForAccIntCompany`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  GetOrgManageCatListForAccInt(
    requestinfo: IApiRequest<AccIntRequest>
  ): Observable<IApiresponse<AccIntExpCategoryModel[]>> {
    let body = JSON.stringify(requestinfo);
    return this.http.post<IApiresponse<AccIntExpCategoryModel[]>>(`${environment.billingApiUrl}GetOrgManageCatListForAccIntCompany`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  GetTaxesForExpCatMap(taxReq: IApiRequest<AccIntRequest>, accIntMasterReq: IApiRequest<IGetAccountingSystemApiRequest>): Observable<any> {
    let response = { taxList: null, taxMasterModel: null };
    return this.GetTaxesForAccInt(taxReq).pipe(mergeMap(taxesResponse => {
      return this.SoloGetTaxRateByAccountingSystemModelId(accIntMasterReq).pipe(map((taxMasterModelResponse: IApiresponse<IAccountingIntegrationModelForTax[]>) => {

        if (taxMasterModelResponse && taxMasterModelResponse.responseInfo) {
          response.taxList = taxesResponse.responseInfo;
        }

        if (taxesResponse && taxesResponse.responseInfo) {
          response.taxMasterModel = taxMasterModelResponse.responseInfo;
        }

        return response;
      }));
    }));
  }

  GetAcctIntStepStatus(request: IApiRequest<AccIntRequest>): Observable<IApiresponse<OrgAcctIntSteps>> {
    let body = JSON.stringify(request);
    return this.http.post<IApiresponse<OrgAcctIntSteps>>(`${environment.billingApiUrl}GetAcctIntStepStatus`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  GetTrackCategoryForAccInt(requestInfo: IApiRequest<AccIntWithEntityRequest>): Observable<IApiresponse<IResponseGetDataForAccountingTrackingCategory[]>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IResponseGetDataForAccountingTrackingCategory[]>>(`${environment.billingApiUrl}GetTrackCategoryForAccInt`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  GetSupplierForAccInt(requestInfo: IApiRequest<AccIntRequest>): Observable<IApiresponse<SuppliersForIntegrationGrid[]>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<SuppliersForIntegrationGrid[]>>(`${environment.billingApiUrl}GetSupplierForAccInt`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  GetTallyTaxesForAccIntCompany(requestInfo: IApiRequest<AccIntRequest>): Observable<IApiresponse<ITaxesForTallyAccountingIntegrationResponse[]>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<ITaxesForTallyAccountingIntegrationResponse[]>>(`${environment.billingApiUrl}GetTallyTaxesForAccIntCompany`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  GetTallyCostCentersForAccIntCompany(requestInfo: IApiRequest<AccIntRequest>): Observable<IApiresponse<IResponseGetCostCentreForAccountingIntegration[]>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<IResponseGetCostCentreForAccountingIntegration[]>>(`${environment.billingApiUrl}GetTallyCostCentersForAccIntCompany`, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() });
  }

  CheckTrackingCategoryStatus(companyAcctIntStep: CompanyAcctIntStep): boolean {
    let isTrackingCategoryStepsMappedComplete = true;
    if (companyAcctIntStep && companyAcctIntStep.isLocationTrackingCategoryOn) {
      if (companyAcctIntStep.isLocationConfigured && !companyAcctIntStep.isLocationMapped) {
        isTrackingCategoryStepsMappedComplete = false;
      }
    }

    if (companyAcctIntStep && companyAcctIntStep.isCostCentreTrackingCategoryOn) {
      if (companyAcctIntStep.isCostCenterConfigured && !companyAcctIntStep.isCostCenterMapped) {
        isTrackingCategoryStepsMappedComplete = false;
      }
    }

    return isTrackingCategoryStepsMappedComplete;
  }

  GetAccSysName(accSysId: number): string {
    switch (accSysId) {
      case AccountingSystemMasterEnum.Tally:
        return this.translate.instant("tally");
      case AccountingSystemMasterEnum.Xero:
        return this.translate.instant("xero");
      case AccountingSystemMasterEnum.QuickBooks:
        return this.translate.instant("quickbooks");
      case AccountingSystemMasterEnum.Sage50:
        return this.translate.instant("sage_50");
      case AccountingSystemMasterEnum.Sage200:
        return this.translate.instant("sage_200_beta");
      default:
        return "";

    }
  }

  GetAccSysType(accSysId: number): number {
    switch (accSysId) {
      case AccountingSystemMasterEnum.Tally:
      case AccountingSystemMasterEnum.Sage50:
      case AccountingSystemMasterEnum.Sage200:
        return AccountingSystemTypeEnum.NonRealtime;
      case AccountingSystemMasterEnum.Xero:
      case AccountingSystemMasterEnum.QuickBooks:
        return AccountingSystemTypeEnum.Realtime;
      default:
        return AccountingSystemTypeEnum.NonRealtime;

    }
  }

  GetDomainListByCompanyId(
    companyId
  ): Observable<IApiresponse<any>> {

    return this.http.get<IApiresponse<any>>(
      `${environment.exportApiUrl}GetAcctIntDomainList/${companyId}`,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader()
      }
    );
  }

  GetXeroBankTransactionsListCompanyId(
    companyId
  ): Observable<IApiresponse<any>> {

    return this.http.get<IApiresponse<any>>(
      `${environment.exportApiUrl}GetXeroBankTransactions/${companyId}`,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader()
      }
    );
  }

  GetXeroBankTransactionsToImportCompanyId(
    companyId
  ): Observable<IApiresponse<any>> {

    return this.http.get<IApiresponse<any>>(
      `${environment.exportApiUrl}GetXeroBankTransactionsToImport/${companyId}`,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader()
      }
    );
  }

  GetDomainEmployeeListByCompanyId(
    companyId
  ): Observable<IApiresponse<any>> {

    return this.http.get<IApiresponse<any>>(
      `${environment.exportApiUrl}GetAcctIntEmployeeSyncList/${companyId}`,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader()
      }
    );
  }

  UpdateAutoSyncSettingWithDomainList(request: IApiRequest<any>): Observable<any> {
    let body = JSON.stringify(request);
    return this.http.post<any>(
      `${environment.exportApiUrl}UpdateAutoSyncSettingWithDomainList`,
      body,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  IgnoreSyncEmployees(request: IApiRequest<any>): Observable<any> {
    let body = JSON.stringify(request);
    return this.http.post<any>(
      `${environment.exportApiUrl}IgnoreSyncEmployees`,
      body,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  SyncEmployees(request: IApiRequest<any>): Observable<any> {
    let body = JSON.stringify(request);
    return this.http.post<any>(
      `${environment.exportApiUrl}SendAcctIntEmployeeSyncEODBackground`,
      body,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  UpdateIgnoreDomainsList(request: IApiRequest<any>): Observable<any> {
    let body = JSON.stringify(request);
    return this.http.post<any>(
      `${environment.exportApiUrl}IgnoreDomainList`,
      body,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  IgnoreBankAccountList(request: IApiRequest<any>): Observable<any> {
    let body = JSON.stringify(request);
    return this.http.post<any>(
      `${environment.exportApiUrl}IgnoreBankAccountList`,
      body,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  DomainListWithCountsByStatus(
    companyId): Observable<any> {

    return this.http.get<IApiresponse<any>>(
      `${environment.exportApiUrl}DomainListWithCountsByStatus/${companyId}`,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader()
      }
    );
  }

  GetConfigSettingsForEmployeeAutomation(
    companyId): Observable<IApiresponse<any>> {

    return this.http.get<IApiresponse<any>>(
      `${environment.exportApiUrl}GetConfigSettingsForEmployeeAutomation/${companyId}`,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader()
      }
    );
  }

  UpdateAutoSyncSettingAndHidePanel(request: IApiRequest<any>): Observable<any> {
    let body = JSON.stringify(request);
    return this.http.post<any>(
      `${environment.exportApiUrl}UpdateAutoSyncSettingAndHidePanel`,
      body,
      {
        headers: this.claimsHeaderService.createLoginAuthorizationHeader(),
      }
    );
  }

  GetAccSysCreditCardMappingList(
    requestInfo: IApiRequest<IAccSysCreditCardMappingRequest>
  ): Observable<IApiresponse<IAccSysCreditCardMappingResponse[]>> {
    let body = JSON.stringify(requestInfo);
    return this.http.post<
      IApiresponse<IAccSysCreditCardMappingResponse[]>
    >(
      `${environment.exportApiUrl}GetAccSysCreditCardMappingList`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  UpdateConfigurationSetup(
    requestInfo: IApiRequest<IUpdateConfigurationSetupRequest>
  ): Observable<IApiresponse<any>> {
    
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.exportApiUrl}UpdateConfigurationSetup`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  RevokeToken(
    requestInfo: IApiRequest<IRequestTurnOnOffAccountingIntegration>
  ): Observable<IApiresponse<any>> {
    
    let body = JSON.stringify(requestInfo);
    return this.http.post<IApiresponse<any>>(
      `${environment.exportApiUrl}RevokeToken`,
      body,
      { headers: this.claimsHeaderService.createLoginAuthorizationHeader() }
    );
  }

  refreshAccessTokenPermissions(code, request) {

      let body = JSON.stringify(request);
      return this.http
        .post<any>(`${environment.exportApiUrl}RefreshAccessTokenPermissions/`+code, body, { headers: this.claimsHeaderService.createLoginAuthorizationHeader() })
        .pipe(
          map((forgotPasswordResponse) => {
            return forgotPasswordResponse;
          })
        );
  }

}

