import { OnInit, Component, ViewChild, Output, EventEmitter, Input, OnChanges,ChangeDetectorRef } from "@angular/core";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { TranslateService } from "@ngx-translate/core";
import { Claims } from "src/app/_models/common/claims";
import { environment } from "src/environments/environment";
import { AppGridComponent } from "src/app/shared/grid/grid-component/grid.component";
import { ColDef } from "ag-grid-community";
import { CountByStatus, GridConfig } from "src/app/_models/common/grid/grid-config";
import { NgOption } from "@ng-select/ng-select";
import { ApiRequest, IApiresponse,LogObjectActionTemplateEnum } from "src/app/_models";
import { ContainerListRequest, IContainerListRequest,ContainerResponse,
    SelectContainerInputModel,SelectContainerOutputModel,AddContainerInputModel,
    AddContainerOutputModel,DeleteContainerRequest,ContainerListResponse} from "src/app/_models/container/container";
import {ContainerService} from "src/app/_services/container/container.service";
import { AlertService } from "src/app/alert-panel/alert.service";
import { AlertPanelModel } from "src/app/_models/common/alertPanelModel";
import { ToastrService } from "ngx-toastr";
import { LoggingPanelModel } from "src/app/_models/common/LoggingPanelModel";
import { LoggingPanelService } from "src/app/_services/logging-panel/logging-panel.service";

@Component({
    selector: "container-list-panel",
    templateUrl: "./container-list-panel.component.html"
})

export class ContainerListPanelComponent implements OnInit {

    @ViewChild(AppGridComponent) gridComponent: AppGridComponent;

    @Input()
    inputData: SelectContainerInputModel;
    
    @Output() closePanelEvent = new EventEmitter<boolean>();
    @Output() submitPanelEvent = new EventEmitter<SelectContainerOutputModel>();

    claimsSubscription: Subscription;
    userActionEventSubscription: Subscription;
    claims: Claims;
    isOverlayRequired: boolean = true;

    gridColumnDefs: ColDef[];
    gridConfigItems: GridConfig;
    gridData = <ContainerResponse[]>[];
    defaultSortColumn: string = 'name';
    defaultSortOrder: string = 'asc';

    containerList = <ContainerResponse[]>[];
    hasFormChanges: boolean = false;
    selectedContainerId: number = 0;
    selectedContainerName: string = "";
    selectedId: number = 0;

    showAddContainerPanel: boolean = false;
    addContainerInputModel: AddContainerInputModel;

    showDeleteConfirmationAlert: boolean = false;
    deleteConfirmationAlertModel: AlertPanelModel;

    isAddContainerPanelDirectOpen: boolean = false;
    constructor(
        private router: Router,
        private claimsService: ClaimsService,
        private translate: TranslateService,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService,
        private alertService: AlertService,
        private loggingPanelService: LoggingPanelService,
        private containerService: ContainerService
    ) {
        this.claimsSubscription = this.claimsService.currentClaims.subscribe((claims) => {
            this.claims = claims;
        });

        this.fnObserveUnsavedChangesAlerts();
    }

    ngOnInit(): void {
        if(this.inputData){
            this.selectedContainerId = this.inputData?.containerId;
            this.selectedContainerName = this.inputData?.containerName;
        }
        this.fnInitialiseGrid();
        this.fnGetContainerList();
    }

    canDeactivate() {
        return !this.hasFormChanges;
    }

    fnObserveUnsavedChangesAlerts() {
        this.userActionEventSubscription = this.alertService.getUserActionEvent().subscribe(action => {
            if (action) {
                this.closePanelEvent.emit(true);
            }
        });
    }

    fnClosePanel() {
        if (this.canDeactivate()) {
            this.closePanelEvent.emit(true);
        }
        else {
            this.alertService.onFormClosed();
        }
    }

    fnInitialiseGrid() {

        this.fnCreateColumnDefs();
        this.fnGetGridConfigItems();
        setTimeout(() => {
            this.gridComponent.initializeGrid();
            this.cdr.detectChanges();
        }, 100);

    }

    fnCreateColumnDefs() {
        this.gridColumnDefs = [
            {
                headerName: "",
                field: "selected",
                cellRenderer: 'radioRenderer',
                sortable: false,
                resizable: false,
                maxWidth: 60,
                pinned: true,
                suppressMenu: true,
                cellRendererParams: {
                    onClick: this.fnOnContainerChange.bind(this)
                  }
            },
            {
                headerName: this.translate.instant('text_report_name'),
                field: 'name',
                resizable: true,
                sortable: true,
                suppressMenu: true,
                minWidth: 100
            },
            {
                headerName: this.translate.instant('text_report_id'),
                field: 'containerId',
                resizable: true,
                sortable: true,
                suppressMenu: true,
                minWidth: 100,
            },
            {
                headerName: this.translate.instant('text_expense_count'),
                field: 'expenseCount',
                resizable: true,
                sortable: true,
                suppressMenu: true,
                minWidth: 100
            },
            {
                headerName: "",
                valueGetter: 'data.congtainerId',
                cellRenderer: 'actionRenderer',
                field: 'userActionField',
                suppressMovable: true,
                suppressMenu: true,
                sortable: false,
                minWidth: 80,
                headerComponentParams:
                    [                    
                        {
                            buttonClass: 'fas fa-trash-alt color-red',
                            buttonTooltip: this.translate.instant('label_delete'),
                            userAction: 2
                        },
                        {
                            buttonClass: 'fas fa-edit color-green',
                            buttonTooltip: this.translate.instant('label_edit'),
                            userAction: 1
                        }
                    ]
           }
        ]
      
    }

    fnGetGridConfigItems() {
        this.gridConfigItems = new GridConfig(
            true,
            true,
            this.defaultSortColumn,
            'containerId',
            0,
            true,
            [],
            true,
            true,
            true,
            null,
            this.defaultSortColumn,
            40,
            this.translate.instant('no_data_availabe'),
            null,
            null,
            false, 
            false,
            '',
            '',
            false
        );
    }

    fnGridFilterChanged() {
        this.gridData = this.containerList;

        this.fnMapSelectedContainer();

        this.fnGetGridConfigItems();
        this.fnCreateColumnDefs();

        setTimeout(() => {
            this.gridComponent.refreshGridData();
            this.cdr.detectChanges();
        }, 100);
    }

    fnOnGridCellClick(params) {
        switch (params.column.colId) {
            case 'log':
                this.fnShowContainerLoggingPanel( params.data.containerId);
                break;
            case 'userActionField':
                switch (params.data.userAction) {
                    case 1:
                       this.fnShowAddContainerPanel(params.data, true);
                      break;
                    case 2:
                        this.selectedId = params.data.containerId;
                        this.fnShowDeleteConfirmationAlert(params.data);
                      break;
                  }
                break;
          }
      }   

    fnGetContainerList() {
        let request = new ApiRequest<IContainerListRequest>("GetContainerList", environment.applicationVersion, environment.requestId);
        let requestInfo = new ContainerListRequest();
        requestInfo.orgId = this.claims.OrgId;
        requestInfo.userId = this.inputData.claimantId;
        request.requestInfo = requestInfo;

        this.containerService.GetContainerList(request)
            .subscribe((response: IApiresponse<ContainerListResponse>) => {
                if (response.responseInfo != null) {
                    this.containerList = response.responseInfo.containerList;
                    this.fnGridFilterChanged();

                    // show Create Cpontainer if not container is avilable
                    if(this.containerList?.length == 0){
                        this.isAddContainerPanelDirectOpen = true;
                        this.fnShowAddContainerPanel(null,false);
                    }
                }
            });
    }

    fnOnContainerChange(selectedData){
        if (selectedData) {
          let selectedRow = selectedData.rowData; 
          this.selectedContainerId= selectedRow.containerId;
          this.selectedContainerName = selectedRow.name;
      
          this.fnMapSelectedContainer();

          this.hasFormChanges = true;
        }
    }

    fnMapSelectedContainer(){
        this.gridData = this.gridData.map(item=>{
      
            if(item.containerId == this.selectedContainerId){
              item.selected = true;
            }
            else{
              item.selected = false;
            }
            return item;
    
        });
    }

    fnOnSubmitContainer() {
        let outputModel= new SelectContainerOutputModel();
        outputModel.containerId = this.selectedContainerId;
        outputModel.containerName = this.selectedContainerName;
        this.submitPanelEvent.emit(outputModel);
      }

    fnShowAddContainerPanel(data, isEditMode) {
       this.addContainerInputModel = new AddContainerInputModel();
       this.addContainerInputModel.claimantId = this.inputData.claimantId;
       this.addContainerInputModel.containerId = data?.containerId;
       this.addContainerInputModel.containerName = data?.name;
       this.addContainerInputModel.isEditMode = isEditMode;
       this.showAddContainerPanel = true;
       this.isOverlayRequired = false;
    }

    fnCloseAddContainerPanel() {
        this.addContainerInputModel = null;
        this.showAddContainerPanel = false;
        this.isOverlayRequired = true;
        
        if(this.isAddContainerPanelDirectOpen){
            this.fnClosePanel();
        }
    }

    fnSubmitAddContainerPanel(outputModel: AddContainerOutputModel) {
        this.addContainerInputModel = null;
        this.showAddContainerPanel = false;
        this.isOverlayRequired = true;

        if(outputModel.isEdited){
            if(outputModel.containerId == this.inputData.containerId && outputModel.containerId == this.selectedContainerId){
                this.selectedContainerId = outputModel.containerId;
                this.selectedContainerName = outputModel.containerName;
                this.fnOnSubmitContainer();
            }
            else{
                this.fnGetContainerList();
            }  
        }
        else{
            this.selectedContainerId = outputModel.containerId;
            this.selectedContainerName = outputModel.containerName;
            this.fnOnSubmitContainer();
        }
    }

    fnShowDeleteConfirmationAlert(container: ContainerResponse) {

        if(container.expenseCount > 0){
            this.deleteConfirmationAlertModel = new AlertPanelModel(
                true,
                this.translate.instant("label_alert"),
                [this.translate.instant("delete_container_alert_msg1"),
                    this.translate.instant("delete_container_alert_msg2")],
                "",
                this.translate.instant("label_ok"),
                true,
                null
            );
            this.selectedId = 0;
        }
        else{
            this.deleteConfirmationAlertModel = new AlertPanelModel(
                true,
                this.translate.instant("label_alert"),
                [this.translate.instant("delete_container_confirmation_msg")],
                this.translate.instant("cancel"),
                this.translate.instant("label_confirm"),
                true,
                null
            );
        }
       
        this.showDeleteConfirmationAlert = true;
        this.isOverlayRequired = false;
    }
    
    fnDeleteConfirmationAlertReceiver(returnValue) {
        if (returnValue && this.selectedId > 0) {
            this.fnDeleteContainer();
        }
        this.deleteConfirmationAlertModel = null;
        this.showDeleteConfirmationAlert = false;
        this.isOverlayRequired = true; 
    }
    
    fnDeleteContainer() {
        if(this.selectedId > 0){
            let request = new ApiRequest<DeleteContainerRequest>("DeleteContainer", environment.applicationVersion, environment.requestId);
    
            let container = new DeleteContainerRequest();
            container.containerId = this.selectedId;
            request.requestInfo = container;
    
            this.containerService.DeleteContainer(request)
            .subscribe((response: IApiresponse<any>) => {
                if (response) {
                    this.toastr.success(this.translate.instant("success"));
                    if(this.selectedId == this.selectedContainerId){
                        this.selectedContainerId = 0;
                        this.selectedContainerName = "";
                        this.fnOnSubmitContainer();
                    }
                    else{
                        this.fnGetContainerList();
                    }
                }
                else{
                    this.toastr.error(this.translate.instant("failed"));
                }
                this.selectedId = 0;
            });
        }
     
      } 

      
    fnShowContainerLoggingPanel(containerId) {
        const model = new LoggingPanelModel(
            this.claims.OrgId.toString(),
            LogObjectActionTemplateEnum.SetupContainer.toString(),
            containerId.toString()
        );
        this.loggingPanelService.onShowLogPanel(model);
    }
    
      
}
