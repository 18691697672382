import { Component, Inject, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { filter, first, takeUntil } from "rxjs/operators";
import { AuthenticationService } from "../../_services/common/authentication.service";
import {
  Userrequestdto,
  UserRequestBase,
} from "../../_models/user/userrequestdto";
import { ToastrService } from "ngx-toastr";
import { emailValidator } from "src/app/validators/email-validator";
import {
  storeURLs,
  socialMediaLinks,
  privacyPolicyURl,
  termsOfServiceURl,
  ConvertNonAsciiToAscii,
} from "src/app/common/commonvariables";
import { Claims } from "src/app/_models/common/claims";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { RSAHelper } from "src/app/_helpers/RSAHelper";
import { OAuthService } from "angular-oauth2-oidc";
import { Guid } from "guid-typescript";
import { CookieService } from "ngx-cookie-service";
import { UserTypeEnum } from "src/app/_models/common/manage-emp-enum.enum";
import { RecaptchaErrorParameters } from "ng-recaptcha";
import { EmailModel } from "src/app/_models/common/EmailModel";
import { EmailService } from "src/app/_services/common/email.service";
import { ApiRequest } from "src/app/_models";
import { VersionHistoryService } from "src/app/_services/common/version-history.service";
import { Meta, Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import jwt_decode from "jwt-decode";
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalModule,
  MsalService,
} from "@azure/msal-angular";
import {
  AuthenticationResult,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  PublicClientApplication,
  RedirectRequest,
} from "@azure/msal-browser";
import { Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { NgxUiLoaderService } from "ngx-ui-loader";

const GRAPH_ENDPOINT = "https://graph.microsoft.com/v1.0/me/calendarview";

type ProfileType = {
  givenName?: string;
  surname?: string;
  userPrincipalName?: string;
  id?: string;
};

@Component({
  templateUrl: "org-login.component.html",
  styleUrls: ["org-login.component.scss"],
  providers: [CookieService],
})
export class OrgLoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  public param: string;
  userrequest: UserRequestBase;
  storeURL = storeURLs;
  socialMediaLink = socialMediaLinks;
  privacyPolicy = privacyPolicyURl;
  termsOfService = termsOfServiceURl;
  claims: Claims;
  rsaHelper: RSAHelper;
  isOnboardingProcessComplete: boolean = false;
  public UniqueId: Guid;
  params: any;
  referralCode: string = "";
  impersonate: string = "";
  cookieValue: string;
  claimValue: string;
  showMobileQrCodePanel: boolean = false;

  impersonationResponse: any;
  impersonationUserClaim: any;
  emailAlreadyExists = false;

  isChrome: boolean =
    navigator.userAgent.toLowerCase().indexOf("chrome") > -1 &&
    navigator.vendor.toLowerCase().indexOf("google") > -1 &&
    !(navigator.userAgent.toLowerCase().indexOf("edg/") > -1);
  constructor(
    private renderer: Renderer2,
    private router: Router,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private claimsService: ClaimsService,
    private oauthService: OAuthService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private emailService: EmailService,
    private versionHistoryService: VersionHistoryService,
    private meta: Meta,
    private title: Title,
    private translate: TranslateService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private http: HttpClient,
    private ngxService: NgxUiLoaderService
  ) {
    

    this.ngxService.start();
    this.renderer.addClass(document.body, "bg-white");
    this.renderer.addClass(document.body, "height-auto");
    this.userrequest = new UserRequestBase();
    this.loginForm = this.createFormGroup();
    this.registerForm = this.createRegistrationFormGroup();
    this.UniqueId = Guid.create();
    this.authenticationService.hideAppLoader();
    localStorage.setItem("istokenImpersonated", "false");
  }

  showCaptcha: boolean = false;
  caprchaResult: string = "";

  public resolved(captchaResponse: string): void {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.caprchaResult = captchaResponse;
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
    this.caprchaResult = "";
  }

  createFormGroup() {
    return new FormGroup({
      username: new FormControl("", [Validators.required, emailValidator]),
      password: new FormControl("", [Validators.required]),
      rememberpassword: new FormControl(false),
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  getProfile() {
    

    this.http.get('https://graph.microsoft.com/v1.0/me').subscribe((profile) => {
      
      this.profile = profile;
      console.log(this.profile);
      

      if (localStorage.getItem("isfromorgsignup") == "true") {
        this.firstname.setValue(this.profile.givenName);
        this.lastname.setValue(this.profile.surname);
        this.email.setValue(this.profile.userPrincipalName);
        this.onRegistrationSubmit();
      }

      if (localStorage.getItem("isfromorglogin") == "true") {
        this.username.setValue(this.profile.userPrincipalName);
        this.password.setValue("Aa123456");
        this.onLoginSubmit();
      }
    });
  }

  ngOnInit() {
    
    // src/app/app.component.ts
    let authRequestConfig: PopupRequest;

    if (this.msalGuardConfig.authRequest) {
      authRequestConfig = {
        ...this.msalGuardConfig.authRequest,
      } as PopupRequest;
    }

    authRequestConfig.loginHint = "bob@contoso.com";

    this.authService.loginPopup(authRequestConfig);

    if (
      localStorage.getItem("isfromorgsignup") == "true" ||
      localStorage.getItem("isfromorglogin") == "true"
    ) {
      this.getProfile();
    }
  }
  get username() {
    return this.loginForm.get("username");
  }

  SetRememberPassword() {
    if (
      localStorage.getItem("rememberpassword") &&
      localStorage.getItem("username")
    ) {
      this.username.setValue(localStorage.getItem("username"));
      this.password.setValue(localStorage.getItem("rememberpassword"));
      this.rememberpassword.setValue(true);
    }
  }

  get password() {
    return this.loginForm.get("password");
  }

  get rememberpassword() {
    return this.loginForm.get("rememberpassword");
  }

  onLoginSubmit() {
    this.ngxService.start();
    this.claims = new Claims();
    this.submitted = true;
    this.registrationsubmitted = false;
    if (this.loginForm.invalid && !localStorage.getItem("isfromorglogin")) {
      return;
    }

    if (
      this.showCaptcha &&
      this.caprchaResult == "" &&
      !localStorage.getItem("isfromorglogin")
    ) {
      return;
    }

    this.translate.getTranslation(this.translate.getBrowserLang());
    this.disableLoginButton = true;
    this.rsaHelper = new RSAHelper();
    this.userrequest.RequestInfo = new Userrequestdto();
    this.userrequest.WebApiName = "SoloLogin";
    this.userrequest.WebApiVersion = 1;
    this.userrequest.RequestId = "111";
    this.userrequest.RequestInfo.LoginId = this.username.value;
    if (localStorage.getItem("isfromorglogin") == "true") {
      this.userrequest.RequestInfo.IsfromOrgId = true;
    }

    this.userrequest.RequestInfo.Password = this.rsaHelper.Encrypt(
      this.password.value
    );
    if (this.rememberpassword.value == true) {
      this.claims.LoginId = this.userrequest.RequestInfo.LoginId;
      this.claims.Password = this.userrequest.RequestInfo.Password;
      localStorage.setItem("rememberpassword", this.password.value);
      localStorage.setItem("username", this.username.value);
    } else {
      localStorage.removeItem("rememberpassword");
      localStorage.removeItem("username");
    }
    this.loading = true;
    this.authenticationService
      .login(this.userrequest)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data.statusCode == 0) {
            //
            this.oauthService
              .fetchTokenUsingPasswordFlowAndLoadUserProfile(
                data.responseInfo.emailId,
                this.rsaHelper.Encrypt(
                  this.password.value
                )
              )
              .then(() => {
                localStorage.removeItem("isfromorglogin");

                this.title.setTitle("ExpenseOnDemand");

                sessionStorage.removeItem("CurrentUserLoginAttempt");
                sessionStorage.removeItem("CurrentUserTriedLogin");
                localStorage.setItem("showComplianceStatement", "1");
                var tokenNew = this.oauthService.getAccessToken();
                this.claims.IsFromOrgLogin = true;
                this.claims.OrgId = data.responseInfo.organisationId;
                this.claims.EmailId = data.responseInfo.emailId;
                this.claims.UserId = data.responseInfo.userId;
                this.claims.supplierRule = data.responseInfo.supplierRule;
                this.claims.showCompliancePopup =
                  data.responseInfo.showComplianceStatement;
                this.claims.complianceHeader =
                  data.responseInfo.complianceHeader;
                this.claims.complianceStatement =
                  data.responseInfo.complianceStatement;
                this.claims.CurrentOrganisationCurrency =
                  data.responseInfo.currentOrganisationCurrency;
                this.claims.CurrentOrganisationCurrencyId =
                  data.responseInfo.currentOrganisationCurrencyId;
                if (data.responseInfo.currentOrganisationCurrency) {
                  this.claims.CurrentOrganisationCurrencyCode =
                    data.responseInfo.currentOrganisationCurrency
                      .split("-")[1]
                      .trim();
                }
                this.claims.FinanceManager =
                ConvertNonAsciiToAscii.removeAccents(data.responseInfo.firstName) +
                  " " +
                  ConvertNonAsciiToAscii.removeAccents(data.responseInfo.lastName);
                this.claims.LoginId = data.responseInfo.emailId;
                this.claims.IsFinanceManager =
                  data.responseInfo.isFinanceManager;
                this.claims.IsMarqueeOn = data.responseInfo.isMarqueeOn;
                this.claims.MarqueeText = data.responseInfo.marqueeText;
                this.isOnboardingProcessComplete =
                  data.responseInfo.isOnboardingProcessComplete;
                if (!data.responseInfo.isOnboardingProcessComplete) {
                  this.router.navigate(["/onboard/prewelcomecurrency"]);
                }
                this.claims.Token = tokenNew;
                this.claims.FMRoleType = data.responseInfo.fmRoleType;
                this.claims.isClaimOnWebAllowed =
                  data.responseInfo.isClaimOnWebAllowed;
                this.claims.orgRoleId = data.responseInfo.orgRoleId;

                this.claims.IsLeaveManagementAllowed =
                  data.responseInfo.isLeaveManagementAllowed;
                this.claims.ApprovalRuleSetting =
                  data.responseInfo.approvalRuleSetting;
                this.claims.showCostCentresWithoutApproverCount = true;
                this.claims.isUniversalImportAllowed =
                  data.responseInfo.isUniversalImportAllowed;
                this.claims.UserHomeCurrencyId =
                  data.responseInfo.userHomeCurrencyId;
                this.claims.UserHomeCurrencyCode =
                  data.responseInfo.userHomeCurrencyCode;
                this.claims.UserHomeCurrencyName =
                  data.responseInfo.userHomeCurrencyName;
                this.claims.IsDelegator = data.responseInfo.isDelegator;
                this.claims.IsDelegate = data.responseInfo.isDelegate;
                this.claims.IsFromOrgLogin = true;
                this.claims.isMultiAccSysIntAllow =
                  data &&
                  data.responseInfo &&
                  data.responseInfo.isMultiAccSysIntAllow
                    ? data.responseInfo.isMultiAccSysIntAllow
                    : false;
                this.claimsService.updateClaims(this.claims);
                this.bindVersionHistory();
                this.authenticationService
                  .organisationdetails(
                    data.responseInfo.userId.toString(),
                    data.responseInfo.emailId,
                    data.responseInfo.token
                  )
                  .pipe(first())
                  .subscribe(
                    (orgData) => {
                      if (orgData.statusCode == 0) {
                        if (orgData.responseInfo[0].icon !== null) {
                          if (orgData.responseInfo[0].icon == "") {
                            this.claims.OrgLogo = "assets/images/logo.png";
                          } else {
                            this.claims.OrgLogo =
                              "data:image/jpeg;base64," +
                              orgData.responseInfo[0].icon;
                          }
                        }
                        this.claims.RateType = orgData.responseInfo[0].rateType;
                        this.claims.nrfcStatus =
                          orgData.responseInfo[0].nrfcStatus;
                        this.claims.OrgName = orgData.responseInfo[0].name;
                        this.claims.TwoStepApproval =
                          orgData.responseInfo[0].twoStepApproval;
                        this.claims.IsCardSetupComplete =
                          orgData.responseInfo[0].isCardSetupComplete;
                        this.claims.accountingSystemId =
                          orgData.responseInfo[0].accountingSystemId;
                        this.claims.accountingSystemType =
                          orgData.responseInfo[0].accountingSystemType;
                        this.claims.showGooglePackagePurchaseReminder =
                          orgData.responseInfo[0].showGooglePackagePurchaseReminder;
                        this.claims.googlePackagePurchaseReminderThreshold =
                          orgData.responseInfo[0].googlePackagePurchaseReminderThreshold;
                        this.claims.IsOrgBasicFunctionTrialEndsReminder =
                          orgData.responseInfo[0].isOrgBasicFunctionTrialEndsReminder;
                        this.claims.IsOrgBasicFunctionTrialEndsAlert =
                          orgData.responseInfo[0].isOrgBasicFunctionTrialEndsAlert;

                        this.claims.IsLeaveManagementAllowed =
                          orgData.responseInfo[0].isLeaveManagementAllowed;
                        this.claims.ApprovalRuleSetting =
                          orgData.responseInfo[0].approvalRuleSetting;
                        this.claims.IsCostCentrePackagePurchasedOrTrial =
                          orgData.responseInfo[0].isCostCentrePackagePurchasedOrTrial;
                        this.claims.IsCustomerPackagePurchasedOrTrial =
                          orgData.responseInfo[0].isCustomerPackagePurchasedOrTrial;
                        this.claims.ReferAndEarnRole =
                          orgData.responseInfo[0].referAndEarnRole;
                        this.claims.CompanyApproverRule =
                          orgData.responseInfo[0].companyApproverRule;
                        this.claims.IsClaimantWiseMonthlyCappingEnabled =
                          orgData.responseInfo[0].isClaimantWiseMonthlyCappingEnabled;
                        this.claims.IsClaimantWiseYearlyCappingEnabled =
                          orgData.responseInfo[0].isClaimantWiseYearlyCappingEnabled;

                        this.claims.isUniversalImportAllowed =
                          orgData.responseInfo[0].isUniversalImportAllowed;

                        this.claims.IsCashAdvanceEnabled =
                          orgData.responseInfo[0].isCashAdvanceEnabled;
                        this.claims.IsCreditCardAdvanceEnabled =
                          orgData.responseInfo[0].isCreditCardAdvanceEnabled;
                        this.claims.isMultiAccSysIntAllow =
                          orgData &&
                          orgData.responseInfo[0] &&
                          orgData.responseInfo[0].isMultiAccSysIntAllow
                            ? orgData.responseInfo[0].isMultiAccSysIntAllow
                            : false;
                        this.claims.isPolicyOnVehicleChange =
                          orgData.responseInfo[0].isPolicyOnVehicleChange;
                        this.claims.triangulationRule =
                          orgData.responseInfo[0].triangulationRule;
                        this.claims.isStripPaymentFailed =
                          orgData.responseInfo[0].isStripPaymentFailed;
                        this.claims.IsDelegateFunctionPurchasedOrTrial =
                          orgData.responseInfo[0].isDelegateFunctionPurchasedOrTrial;
                          this.claims.BandId = orgData.responseInfo[0].bandId;
                          this.claims.IsPayStepEnabled = orgData.responseInfo[0].isPayStepEnabled;
                        this.claimsService.updateClaims(this.claims);
                        this.authenticationService.setloginnav();
                        this.ngxService.stop();
                        this.routeUser();
                      }
                    },
                    (error) => {
                      this.disableLoginButton = false;
                    }
                  );
              })
              .catch((err) => {
                this.disableLoginButton = false;
                console.error("error logging in", err);
              });
          } else {
            this.disableLoginButton = false;
            if (
              data.errorInfo[0] &&
              data.errorInfo[0].message &&
              data.errorInfo[0].message ==
                this.translate.instant('claimant_on_web_not_supported')
            )
              this.showMobileQrCodePanel = true;
            else this.toastr.error(data.errorInfo[0].message);
            this.loading = false;
            this.checkForCaptchaVisibility();
          }
        },
        (error) => {
          this.disableLoginButton = false;
          this.toastr.error(error);
          this.loading = false;
        }
      );
  }

  routeUser() {
    this.renderer.removeClass(document.body, "settingsW");
    this.renderer.removeClass(document.body, "login-bg");

    if (this.claims.IsFinanceManager == true)
      this.router.navigate(["dashboard/overview"]);
    else {
      if (this.claims.orgRoleId == UserTypeEnum.Approver) {
        this.router.navigate(["/approver/appclaimant"]);
      } else {
        if (
          this.claims.IsDelegateFunctionPurchasedOrTrial &&
          this.claims.IsDelegate
        ) {
          this.router.navigate(["claimant/delegatorsexpense"]);
        } else {
          this.router.navigate(["claimant/expenselist"]);
        }
      }
    }
  }

  ShowSpecialCharErrorFName: boolean = true;
  ShowSpecialCharErrorLName: boolean = true;
  omit_special_char(event) {
    var k;
    k = event.charCode;
    if (k == 13) {
      return true;
    }
    if (event.target.id === "fName") {
      this.ShowSpecialCharErrorFName = this.allowAcuteChar(event);
    } else {
      this.ShowSpecialCharErrorLName = this.allowAcuteChar(event);
    }
    return this.allowAcuteChar(event);
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
  forgotPassword() {
    this.router.navigate(["/forgotpassword"]);
  }

  //Registration

  registerForm: FormGroup;
  IsRegisterCanBeDisabled: boolean;
  registrationsubmitted = false;
  disableLoginButton: boolean;
  createRegistrationFormGroup() {
    return new FormGroup({
      firstname: new FormControl("", [
        Validators.required,
        Validators.minLength(1),
        this.noWhitespaceValidator,
      ]),
      lastname: new FormControl(""),
      email: new FormControl("", [Validators.required, emailValidator]),
    });
  }

  get firstname() {
    return this.registerForm.get("firstname");
  }

  get lastname() {
    return this.registerForm.get("lastname");
  }

  get email() {
    return this.registerForm.get("email");
  }

  newGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
  onRegistrationSubmit() {
    this.claims = new Claims();
    this.submitted = false;
    this.registrationsubmitted = true;
    //

    // stop here if form is invalid
    if (this.registerForm.invalid || this.firstname.value.trim() == "") {
      return;
    }

    this.IsRegisterCanBeDisabled = true;
    let em = new EmailModel();
    em.to = this.email.value;
    em.templateName = "OnboardingOTP";
    em.userId = 0;
    em.subjct = "OTP";
    em.sentTime = 0;
    em.deliveredTime = 0;
    em.status = 1;
    em.emailLogId = this.newGuid();
    var otp = Math.floor(1000 + Math.random() * 9000);
    em.keyValuePairs["OTP"] = otp.toString();

    this.authenticationService
      .checkForUserExistance(this.email.value)
      .subscribe(
        (userData) => {
          if (userData.responseInfo != null) {
            this.toastr.error(this.translate.instant("claimant_exists"));
            this.IsRegisterCanBeDisabled = false;
            this.emailAlreadyExists = true;
          } else {
            let req = new ApiRequest<EmailModel>("", 1, "");
            req.requestInfo = em;

            if (localStorage.getItem("isfromorgsignup") == "true") {
              this.userrequest.RequestInfo = new Userrequestdto();
              this.userrequest.WebApiName = "SoloRegister";
              this.userrequest.WebApiVersion = 1;
              this.userrequest.RequestId = "111";
              this.userrequest.RequestInfo.FirstName = this.firstname.value;
              this.userrequest.RequestInfo.LastName = this.lastname.value;
              this.userrequest.RequestInfo.LoginId = this.email.value;
              this.userrequest.RequestInfo.ReferralCode = this.referralCode;
              this.userrequest.RequestInfo.OTP = otp;
              this.userrequest.RequestInfo.Password = "Aa123456";
              localStorage.setItem(
                "registereduser",
                JSON.stringify(this.userrequest.RequestInfo)
              );
              this.authenticationService.register(this.userrequest).subscribe(
                (data) => {
                  if (data.statusCode == 0) {
                    if (localStorage.getItem("currentUser")) {
                      localStorage.removeItem("currentUser");
                    }

                    this.claims.IsFromOrgLogin = true;
                    this.claims.Token = data.responseInfo.token;
                    this.claims.EmailId = data.responseInfo.emailId;
                    this.claims.UserId = data.responseInfo.userId;
                    this.claims.FinanceManager =
                      data.responseInfo.firstName +
                      " " +
                      data.responseInfo.lastName;
                    this.claims.LoginId = data.responseInfo.emailId;
                    this.claims.FMRoleType = 1;
                    this.claims.isClaimOnWebAllowed =
                      data.responseInfo.isClaimOnWebAllowed;
                    this.claims.orgRoleId = data.responseInfo.orgRoleId;

                    this.claimsService.updateClaims(this.claims);

                    localStorage.removeItem("isfromorgsignup");
                    this.ngxService.stop();

                    this.router.navigate(["/onboard/prewelcomecurrency"]);
                  } else {
                    this.toastr.error(data.errorInfo[0].message);
                  }
                },
                (error) => {}
              );
            } else {
              this.emailService.SendEmail(req).subscribe(() => {
                this.userrequest.RequestInfo = new Userrequestdto();
                this.userrequest.WebApiName = "SoloRegister";
                this.userrequest.WebApiVersion = 1;
                this.userrequest.RequestId = "111";
                this.userrequest.RequestInfo.FirstName = this.firstname.value;
                this.userrequest.RequestInfo.LastName = this.lastname.value;
                this.userrequest.RequestInfo.LoginId = this.email.value;
                this.userrequest.RequestInfo.ReferralCode = this.referralCode;
                this.userrequest.RequestInfo.OTP = otp;
                localStorage.setItem(
                  "registereduser",
                  JSON.stringify(this.userrequest.RequestInfo)
                );

                this.router.navigate(["/onboard/verifyemail"]);
              });
            }
          }
        },
        (error) => {}
      );
  }

  countryName: string;
  ipDetails: any;
  isIndia: boolean;

  async getIpCliente() {
    const response = await fetch(
      "https://api.ipapi.com/check?access_key=2fd83198db3dd7431b553117d2f68d48&format=1"
    );
    const jsonData = await response.json();
    console.log(jsonData);

    this.ipDetails = jsonData;
    this.countryName = jsonData.country_name;
    if (this.countryName.includes("India")) {
      this.isIndia = true;
    }
    localStorage.setItem("countryName", this.countryName);
  }

  closeMobileQrCodePanel() {
    this.showMobileQrCodePanel = false;
  }
  exisitingPasswordType: boolean = false;
  PasswordType: boolean = false;
  timeoutHandler: any;
  mouseup(type) {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
      this.timeoutHandler = null;
    }
    switch (type) {
      case "exisitingPasswordType":
        this.exisitingPasswordType = false;
        break;
      case "PasswordType":
        this.PasswordType = false;
        break;
    }
  }

  mousedown(type) {
    this.timeoutHandler = setTimeout(() => {
      switch (type) {
        case "exisitingPasswordType":
          this.exisitingPasswordType = true;
          break;
        case "PasswordType":
          this.PasswordType = true;
          break;
      }
      this.timeoutHandler = null;
    }, 50);
  }

  allowAcuteChar(e) {
    let regex = new RegExp("^[a-zA-ZÀ-úp{L} '-]+$");
    let str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    } else return false;
  }

  showCaptchaMessage: boolean = false;
  checkForCaptchaVisibility() {
    var loginAttemptCount = 0;

    if (sessionStorage.getItem("CurrentUserTriedLogin")) {
      if (
        sessionStorage.getItem("CurrentUserTriedLogin") != this.username.value
      ) {
        sessionStorage.setItem("CurrentUserLoginAttempt", "0");
      }
    }

    if (sessionStorage.getItem("CurrentUserLoginAttempt")) {
      loginAttemptCount =
        Number(sessionStorage.getItem("CurrentUserLoginAttempt")) + 1;
    } else {
      loginAttemptCount = 1;
    }
    sessionStorage.setItem(
      "CurrentUserLoginAttempt",
      loginAttemptCount.toString()
    );
    sessionStorage.setItem("CurrentUserTriedLogin", this.username.value);
    this.showCaptchaMessage = loginAttemptCount > 0 && loginAttemptCount < 10;
    this.showCaptcha = loginAttemptCount >= 10;
  }

  bindVersionHistory() {
    if (!this.claims.appVersion) {
      this.versionHistoryService
        .getVersionHistory()
        .pipe(first())
        .subscribe((res: any) => {
          if (res.statusCode == 0) {
            let currentVersion = res.responseInfo.filter(
              (x) => x.isCurrentRelease
            )[0].versionNumber;

            this.claims.appVersion = currentVersion;
            this.claimsService.updateClaims(this.claims);
          }
        });
    }
  }

  fnCheckRouteParams() {
    let params = this.route.snapshot.queryParams;
    var paramKeys = Object.keys(params);
    if (paramKeys.length > 0 && params["code"]) {
      console.log(this.route.snapshot.queryParams);
      this.authenticationService.getXeroUserDetails(params["code"]).subscribe(
        (userData) => {
          const token = userData.idToken;
          const decoded = jwt_decode(token);
          const firstName = decoded["given_name"];
          const lastName = decoded["family_name"];
          const email = decoded["email"];

          this.firstname.setValue(firstName);
          this.lastname.setValue(lastName);
          this.email.setValue(email);
        },
        (error) => {}
      );
    }
  }

  private readonly _destroying$ = new Subject<void>();
  profile!: ProfileType;

  loginWithOrgId() {
    
    localStorage.setItem("isfromorglogin", "true");

    localStorage.removeItem("isfromorgsignup");

    // if (localStorage.getItem("isfromorglogin") != "true") {
    //   this.router.navigate(["/orglogin"]);
    // }

    this.authService.instance["browserStorage"].clear();

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  signupWithOrgId() {
    localStorage.setItem("isfromorgsignup", "true");

    localStorage.removeItem("isfromorglogin");

    // if (localStorage.getItem("isfromorgsignup") != "true") {
    //   this.router.navigate(["/orglogin"]);
    // }

    this.authService.instance["browserStorage"].clear();

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }
}
