<h4 class="eod-text-secondary fw-600">{{'step_5_5' | translate}}</h4>
<h1 class="onboarding-title pt-3">
    {{'onboarding_scan_qr_codes' | translate}}
</h1>
<div class="form onboarding onboarding-width mt-2 d-flex flex-column align-content-between">
    <div class="row">
        <div class="col-md-8">
            <img class="mt-4"
                 src="../../../../assets/images/qr-code.png"
                 width="100%"
                 alt="app download QR Code">
        </div>
        <div class="col-md-4 d-flex align-content-center flex-wrap">
            <a target="_blank"
               href="https://play.google.com/store/apps/details?id=com.expenseondemand.soloexpenses"
               class="mb-2"><img src="../../../../assets/images/android-app-download-button.png"
                     alt=""></a>
            <a target="_blank"
               href="https://apps.apple.com/in/app/expenseondemand-for-smb/id643685343"><img
                     src="../../../../assets/images/iphone-app-download-button.png"
                     alt=""></a>
        </div>
    </div>
    <button class="btn eod-btn-secondary btn-lg btn-block my-auto"
            (click)="goToDashboard()">{{'label_close'| translate}}</button>
</div>