  export class ContainerTurnOnSettingResponse{
    isContainerEnabled: boolean;
  }
  
  export interface IContainerTurnOnSettingRequest{
    isContainerEnabled: boolean;
    orgId: number;
    userId: number;
    userName: string;
  }
  
  export class ContainerTurnOnSettingRequest implements IContainerTurnOnSettingRequest{
    isContainerEnabled: boolean;
    orgId: number;
    userId: number;
    userName: string;
  }

  export class IContainerListRequest{
    userId: number;
    orgId: number;
  }

  export class ContainerListRequest implements IContainerListRequest{
    userId: number;
    orgId: number;
  }

  export class ContainerResponse{
    selected: boolean;
    containerId: number;
    name: string;
    description: string;
    userId: number;
    orgId: number;
    submitted: boolean;
    submittedOn: number;
    createdOn: number;
    updatedOn: number;
    createdBy: number;
    updatedBy: number;
    expenseCount: number;
    expenseStatus: number;
    totalExpenseCount: number;
    preApprovalId: number;
    totalExpenseAmount: number;
    isPolicyViolated: boolean;
    potentialFraud: boolean;
    isExpenseEditedByFM: boolean;
    approverAsDeputy: boolean;
    actualApproverId: number;
    approverAsCounterApprover: boolean;
  }

  export class SelectContainerInputModel {
    containerId: number;
    containerName: string;
    claimantId: number;
}

  export class SelectContainerOutputModel {
    containerId: number;
    containerName: string;
}

export class ContainerRequest {
  containerId: number;
  name: string;
  description: string;
  userId: number;
  orgId: number;
  createdOn: number;
  updatedOn: number;
  createdBy: number;
  updatedBy: number;
  userName: string;
}

export class ContainerFormModel extends ContainerRequest {
  isEditMode: boolean;
}

export class AddContainerInputModel {
  containerId: number;
  containerName: string;
  claimantId: number;
  isEditMode: boolean;
}

export class AddContainerOutputModel {
  containerId: number;
  containerName: string;
  isEdited: boolean;
}

export class DeleteContainerRequest {
  containerId: number;
}


export class IExpenseContainerListRequest{
  userId: number;
  orgId: number;
  expenseStatus: number[];
}

export class ExpenseContainerListRequest implements IExpenseContainerListRequest{
  userId: number;
  orgId: number;
  expenseStatus: number[];
}

export class ExpenseContainerResponse{
  containerList: ContainerResponse[];
  totalCount: number;
  incompleteCount: number;
  incompleteCreditCardCount: number;
  rejectedCount: number;
  awaitingReviewCount: number;
  awaitingFinanceApprovalCount: number;
  approvedForPaymentCount: number;
  passedForPaymentCount: number;
  unReportedExpenseCount: number;
}

export class ContainerListResponse{
   containerList: ContainerResponse[];
}

export class SaveContainerResponse{
  containerId: number;
}


export class IUpdateContainerRequest{
  userId: number;
  orgId: number;
  containerId: number;
  action: number;
  expenseInfo: any[];
}

export class UpdateContainerRequest implements IUpdateContainerRequest{
  userId: number;
  orgId: number;
  containerId: number;
  action: number;
  expenseInfo: any[];
}


export class UpdateContainerResponse{
  expenseStatusInfo: any[];
}

export class MoveContainerExpenseInputModel {
  containerId: number;
  containerName: string;
  claimantId: number;
  selectedExpenseList: any[];
}

export class MoveExpensesToContainerRequest {
  expenseIds: number[];
  containerId: number;
  userId: number;
  orgId: number;
}


export class IApproverContainerListRequest {
  userId: number;
  orgId: number;
  claimantId: number;
  expenseStatus: number;
  preApprovalId: number;
  approverAsDeputy: boolean;
  actualApproverId: number;
  containerId: number;
  claimType: number;
}

export class ApproverContainerListRequest implements IApproverContainerListRequest{
  userId: number;
  orgId: number;
  claimantId: number;
  expenseStatus: number;
  preApprovalId: number;
  approverAsDeputy: boolean;
  actualApproverId: number;
  containerId: number;
  claimType: number;
}

export class ApproverContainerListResponse{
  containerList: ContainerResponse[];
  countOfContainer: number;
}

export class ContianerPolicyInputModel{
   containerId: number;
   expenseStatusInfo: any[];
}
