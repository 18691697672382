export enum PolicyType {
    Overall = 1,
    PerNight = 2,
    PerPerson = 3,
    ExpenseTimeLimit = 4,
    DailyCap = 5,
    MonthlyCap = 6,
    YearlyCap = 7,
    AutoApproval = 8,
    Duplicate = 9,
    Escalation = 10
}

export enum ControlType {
    CheckBox = 1,
    Switch = 2,
    Dropdown = 3
}

export enum BaseInformationType {
    DrivingLicense = 1,
    Registration = 2,
    MOT = 3,
    Insurance = 4,
    CarTax = 5
}

export enum ClaimantCCEnum {
    All = 0,
    WithoutCostCentre = 1,
    WithCostCentre = 2
}

export enum ClaimantGradeEnum {
    All = 0,
    WithGrade = 1,
    WithoutGrade = 2
}

export enum ReceiptEnum {
    View = 1,
    NoReceipt = 0
}


export enum ClaimantEnum {
    WithDOC = 1,
    WithoutDOC = 0
}

export enum MileageUOMEnum {
    Mile = 1,
    Kilometre = 2
}

export enum ModeOfJourneyValue {
    Quickest = 1,
    Shortest = 2
}

export enum VehicleTypeEnum {
    All = -1,
    No_Vehicle = 0,
    Four_Wheeler = 1,
    Two_Wheeler = 2,
    Private_Car = 3,
    Company_Car = 4
}

export enum VehicleSubTypeEnum {
    Diesel = 1,
    Petrol = 2,
    Electric = 3,
    Gas = 4
}

export enum VehicleTypeLabelEnum {
    "No Vehicle" = 0,
    "4 Wheeler" = 1,
    "2 Wheeler" = 2,
    "Private Car" = 3,
    "Company Car" = 4
}

export enum UserTypeEnum {
    Claimant = 1,
    Approver = 2,
    FinanceManager = 3,
    Agent = 4,
    Vendor = 5
}

export enum ExpenseStatusEnum {
    Incomplete = 1,
    IncompleteUPIExpense = 2,
    PendingForSubmission = 3,
    Rejected = 4,
    PendingWithApprover = 5,
    Approved = 6,
    ApprovedForPayment = 7,
    PassedForPayment = 8,
    Exported = 9,
    Archived = 10,
    Deleted = 11,
    IncompleteCardExpense = 12,
    Dismissed = 13,
    PendingWithCA = 15
}

export enum MileageRateTypeEnum {
    NoRateSelected = 0,
    FreeForLife = 1,
    SingleRate = 2,
    DifferentialRate = 3,
    MultipleRate = 4,
    AssignRatetoGrade = 5
}

export enum TriangulationRuleValue {
    NoTriangulation = 0,
    TakeLesserOf = 1,
    TakeDifference = 2
}

export enum PaymentTypeEnum {
    Cash = 1,
    PersonalCreditCard = 2,
    DebitCard = 3,
    Cheque = 4,
    DemandDraft = 5,
    CorporateCreditCard = 6,
    UPI = 7,
    Total = 8
}

export enum CostCentreStatusEnum {
    All = 0,
    Active = 1,
    Inactive = 2
}

export enum CostCentreHistoryActionPerformedEnum {
    Created = 1,
    Updated = 2,
    Deactivated = 3,
    Activated = 4
}

export enum EntityType {
    CostCentre = 1,
    Grade = 2,
    CardUserAssignment = 3,
    CardStatementImport = 4,
    Customer = 5,
    Location = 6
}

export enum CreditCardUserAssignmentEnum {
    Assigned = 1,
    UnAssigned = 2,
    All = 3,
    Active = 4,
    InActive = 5
}

export enum GradeStatusEnum {
    All = 0,
    Active = 1,
    Inactive = 2
}
export enum BaseCategoryTypeEnum {
    Mileage = 1,
    MileageGPS = 2,
    MileageGoogle = 3,
    Hotel = 4,
    Entertainment = 5,
    Generic = 6,
    Advance = 7,
    SpecialCategory = 8
}

export enum AccountingSystemMasterEnum {
    Tally = 1,
    Xero = 2,
    QuickBooks = 3,
    Sage50 = 4,
    Sage200 = 5
}

export enum AccountingSystemTypeEnum {
    Realtime = 1,
    NonRealtime = 2
}

export enum XeroExportedExpenseStatus {
    AwaitingApproval = 1,
    AwaitingPayment = 2
}

export enum XeroExportExpensesAs {
    PurchaseBill = 1,

    ExpenseClaims = 2
}

export enum AccountingSystemMasterModelEnum {
    XeroAccounts = 1,
    XeroContacts = 2,
    XeroTaxRate = 3,
    XeroOrganisation = 4,
    QuickBooksCompanyInfo = 5,
    QuickBooksSipplier = 6,
    QuickBooksAccounts = 7,
    QuickBooksTaxCodes = 8,
    QuickBooksTaxRates = 9,
    QuickBooksVendors = 10,
    XeroTrackingCategory = 11,
    QuickBooksProjects = 12
}

export enum AccountingSystemModelTypeEnum {
    Accounts = 1,
    Contacts = 2,
    TaxRate = 3
}

export enum OrderBillingFunctionEnum {
    Trial = 1,
    Purchase = 2
}

export enum BillingsubscriptionStatusEnum {
    TrialFunction = 1,
    PurchaseFunction = 2,
    UnSubscribedTrialFunction = 3,
    UnSubscribedPurchasedFunction = 4
}

export enum VehicleStatusEnum {
    NoVehicle = 0,
    Unauthorised = 1,
    Approved = 2,
    Rejected = 3,
    Deleted = 4,
    NoVehicleForGrid = 5
}

export enum MileageRateStatusEnum {
    All = 0,
    Active = 1,
    FutureRate = 2,
    Inactive = 3,
    Expired = 4
}
export enum UKRateConfigEnum {
    ActivateOwnerShip = 1,
    ActiveRateEdit = 2,
    FutureRateEdit = 3,
    AddNewRate = 4,
    AddNewOwnership = 5,
    CopyRate = 6,
    ImportExport = 7
}

export enum ExportFormatEnum {
    FreeExportFile = 1,
    YesBankPaymentFile = 2,
    Sage50 = 3,
    Xero = 4,
    QuickBooks = 5,
    Tally = 6,
    Sage200 = 7
}

export enum EmailTemplateEnum {
    ActivationRequestWizard = 0,
    ForgotPassword = 1,
    DefaultPassword = 2,
    AddUpdateExployee = 3,
    SendSummaryEmail = 4,
    SendInvitationEmail = 5,
    SendForgotPassword = 6,
    SendReminderEmail = 7,
    SendExportedExpensesToCSV = 8,
    InviteAFriendExisting = 9,
    CategoryActiveReminder = 10,
    SendYblRequest = 11,
    AdvanceGenericEmail = 12,
    ChangePassword = 13
}
export enum ExpenseSyncDataStatus {
    Success = 1,
    Fail = 2,
    InQueue = 3
}

export enum FMRoleType {
    AllPrivilege = 1,
    LimitedPrivilege = 2,
    NoPrivilege = 3
}

export enum ApprovalRuleSetting {
    Manual = 1,
    AutomaticA = 2,
    AutomaticB = 3,
    AutomaticC = 4,
    AutomaticD = 5,
    AutomaticE = 6
}

export enum FunctionPrivilege {
    SetupYourOrganisation = 1,
    Currency = 2,
    DeleteTestData = 3,
    ManageEmployees = 4,
    BasicMileageRates = 5,
    PreApprovals = 6,
    SetupCreditCard = 7,
    SetupCostCentre = 8,
    ExpenseCategoryManagement = 9,
    TwoStepApproval = 10,
    Advances = 11,
    SetupGrade = 12,
    SetupAdvancedMileage = 13,
    SetupExpensePolicy = 14,
    LiabilityReport = 15,
    Customers = 16,
    BusinessPurpose = 17,
    TransferData = 18,
    AdditionalFields = 20,
    Vendors = 21,
    DeputyForApprover = 22,
    ProjectAndBudget = 23,
    AuditTrail = 24,
    NonReimbursableFuel = 25,
    ApproverHistoricalView = 26,
    ReceiptwithMultipleVATRates = 28,
    VATNoVATNoReceipt = 29,
    NinetyDayRule = 30,
    FinanceApprover = 31,
    PassforPayment = 32,
    Invoices = 33,
    ConfigureAdministratorPrivilege = 34,
    assigncategoriestograde = 44,
    AssignDeputyToApprovers = 45,
    EnterOutofOffice = 46,
    AssignRatetoGrade = 47,
    SetupMultipleCompanies = 48,
    DutyofCare = 50,
    DutyofCareApproval = 51,
    OpenApi = 52,
    Location = 53,
    AddAttendeeTypes = 54,
    Supplier = 55,
    LeaveManagement = 56,
    MultipleCountry = 58,
    CounterApprover = 61,
    FxRate = 62,
    Delegate = 63,
    ApAutomation = 64,
    ApproverKYCDocuments = 65,
    CarbonFootprint = 66
}

export enum FunctionPackageEnum {
    SetupYourOrganisation = 1,
    Currency = 2,
    DeleteTestData = 3,
    ManageEmployees = 4,
    ConfigureApprovalRule = 5,
    BasicMileageRates = 6,
    AssignVehicle_toClaimants = 8,
    Pre_Approvals = 9,
    SetupCreditCard = 14,
    SetupCostCentre_Assign_toClaimants = 15,
    ExpenseCategoryManagement = 17,
    TwoStepApproval = 18,
    Advances = 19,
    SetupTaxRate = 20,
    SetupGradesAssign_toClaimants = 22,
    SetupAdvancedMileage = 23,
    SetupExpensePolicy = 28,
    SetupUKRates = 34,
    Triangulation = 36,
    Explore_YesPayBizPortal = 37,
    Travel = 45,
    Customer_Projects = 48,
    LiabilityReport = 49,
    Customer = 50,
    SetupSingleRates = 51,
    SingleRateAssignVehicleToClaimants = 52,
    SetupDifferentialRates = 53,
    DifferentialRateAssignVehicleToClaimants = 54,
    AssignVehicleToClaimants = 35,
    AccountingIntegration = 29,
    DataTransferXERO = 56,
    DataTransferQUICKBOOKS = 57,
    DataTransferSAGE = 58,
    DataTransferTALLY = 59,
    ExportReport = 60,
    ConfigureAdministratorPrivilege = 66,
    ProjectBudget = 67,
    AuditTrail = 70,
    ManualVat = 75,
    VatNoVat = 76,
    PerPersonLimit = 80,
    PerNightLimit = 81,
    ExpenseCappingDaily = 85,
    ExpenseCappingMonthly = 86,
    ExpenseCappingYearly = 87,
    AutoApproval = 88,
    SetupMultipleCompanies = 89,
    NonReimbursableFuel = 72,
    FuelCard = 73,
    AssignDeputyToApprovers = 78,
    EnterOutofOffice = 79,
    assigncategoriestograde = 90,
    assignRatetoGrade = 91,
    DutyofCare = 92,
    DutyofCareApproval = 93,
    OpenApi = 95,
    Location = 96,
    AttendeeType = 97,
    Supplier = 98,
    LeaveManagement = 100,
    UniversalImport = 102,
    MultipleCountry = 103,
    AdditionalFields = 63,
    BusinessPurpose = 55,
    MultipleVehicle = 111,
    PassengerLoadMiles = 112,
    DataTransferSAGE200 = 114,
    DuplicateChecker = 82,
    Delegate = 115,
    CounterApprover = 108,
    APAutomation = 118,
    ApproveKYCDocuments = 123,
    ReceiptsTransferXERO = 122,
    AccessCharge = 124,
    CarbonFootprint=125,
    SingleSignOn = 127,
    FMCanEditExpense = 128,
    RecallExportedExpenses = 129,
    Container = 130,
    SpecialCategory = 131,
    ExpenseTimeLimit = 77,
    OverallDailyLimit = 83,
    ExpenseEscalation = 84,
    ComplianceStatement = 113,
    AntiFraud = 110,
    YearEndRule = 117

}
export enum FunctionPackageTypeEnum {
    Both = 0,
    Web = 1,
    Mobile = 2
}
export enum DasboardExpenseStatusEnum {
    Incomplete = 1,
    PendingWithApprover = 2,
    PendingFinanceApproval = 3,
    PendingPassforPayment = 4,
    PassedForPayment = 5,
    PendingPreApprovalCountWithApprover = 6,
}

export enum CustomerStatusEnum {
    All = 0,
    Active = 1,
    Inactive = 2
}

export enum CustomerRuleStatusEnum {
    All = 0,
    Mandatory = 1,
    NonMandatory = 2,
    NotRequired = 3
}
export enum CustomerRuleEnum {
    NotRequired = 0,
    Mandatory = 1,
    NonMandatory = 2
}
export enum AccountingIntegrationTrackingCategoryOption {
    TCCostCentre = 6,
    TCCustomer = 7,
    TCLocation = 8,
    TCProject = 9
}
export enum WorkflowCurrentStatusEnum {
    Approved = 1,
    Rejected = 2,
    Pending = 3,
    InActive = 4,
    Recall = 5
}

export enum GooglePackagePaymentBillingStatusEnum {
    Pending = 0,
    Success = 1,
    Failed = 2
}
export enum CommonStatusEnum {
    Success = 1,
    Failed = 2,
    Warning = 3
}

export enum BillingInvoiceForEnum {
    Subscription = 0,
    GooglePackageService = 1,
    PlaidPackageService = 2
}

export enum ReceiptTypeEnum {
    VATRecetpt = 1,
    NoVATReceipt = 2,
    NoReceipt = 3,
    ForeignReceipt = 4

}

export enum AttachmentTypeEnum {
    Image = 1,
    Pdf = 2,
    VoiceMemo = 3,
    Other = 4,
}

export enum ReferAndEarnRoleEnum {
    Customer = 1,
    Reseller = 2
}
export enum BankAccountTypeEnum {
    Saving = 1,
    Current = 2
}
export enum AgentFilterStatusEnum {
    All = 0,
    Active = 1,
    Inactive = 2,
    Pending = 3
}
export enum ProjectBudgetEntityType {
    All = 0,
    CostCentre = 1,
    Customer = 2
}
export enum BudgetStatus {
    All = 0,
    Active = 1,
    Inactive = 2
}
export enum ProjectBudgetApprovalRule {
    NormalFlow = 1,
    ProjectManagerFlow = 2,
    ProjectManagerBillableApproverNonBillable = 3
}
export enum ProjectStatus {
    All = 0,
    Active = 1,
    Inactive = 2,
    Delete = 3,
    Closed = 4
}
export enum NRFCStatusEnum {
    NoNRFC = 0,
    NR = 1,
    FC = 2
}
export enum NRCompliantEnum {
    All = 0,
    Compliant = 1,
    NonCompliant = 2
}

export enum CompanyStatusEnum {
    All = 0,
    Active = 1,
    Inactive = 2
}
export enum CategoryAccountingCodeRuleEnum {
    UseMasterCompanyAccountingCode = 1,
    ManuallyEnterAccountingCode = 2
}

export enum CompanyApproverRuleEnum {
    NormalApprovalFlow = 1,
    ApprovalFlowBasedOnCompanyConfiguration = 2
}

export enum SetupCompanyRulesFilterEnum {
    All = 0,
    WithOneFinanceManager = 1,
    WithTwoFinanceManagers = 2,
    WithThreeFinanceManagers = 3,
    WithMoreThanThreeFinanceManagers = 4
}

export enum CurrencyEnum {
    INR = 67,
    GBP = 51,
    EUR = 46,
    AUD = 10,
    USD = 150,
    PKR = 117,
    NPR = 110,
    BDT = 13,
    LKR = 85
}

export enum ClosedProjectType {
    AddBackUnspentMoney = 1,
    ReduceProjectBudgetFromMainBudget = 2,
    HandleManually = 3
}

export enum BillingTypeEnum {
    BasicFunction = 0,
    PerFunction = 1,
    PerTransaction = 2,
    GooglePackageService = 3,
    AssistedSupport = 4
}

export enum LogObjectActionEnum {
    Added = 0,
    Updated = 1,
    Activated = 2,
    Deactivated = 3,
    Assigned = 4,
    UnAssigned = 5,
    Deleted = 6,
    Closed = 7,
    Enabled = 8,
    Disabled = 9,
    MasterDataSync = 10,
    Selected = 11,
    UnSelected = 12,
}


export enum LogObjectActionTemplateEnum {
    SetupYourOrganisation = 1,
    Currency = 2,
    PreApprovals = 3,
    DeleteTestData = 4,
    ManageEmployee = 5,
    Grade = 6,
    ExpenseCategoryManagement = 7,
    Approver1stLevel = 8,
    AssignGradetoClaimants = 9,
    Approver2ndLevel = 10,
    AssignGradetoCategory = 11,
    AssignDeputies = 12,
    SetupOutOfOffice = 13,
    ExpenseSubmissionTimeLimit = 14,
    PerPersonLimitEntertainment = 15,
    PerNightLimitHotelandLodging = 16,
    DuplicateRulePolicy = 17,
    OverallDailyLimitPolicy = 18,
    ExpenseEscalationSettingsPolicy = 19,
    ExpenseCappingDailyPolicy = 20,
    ExpenseCappingMonthlyPolicy = 21,
    ExpenseCappingYearlyPolicy = 22,
    AutoApprovalPolicy = 23,
    SetupvatsRate = 24,
    TaxRateWithComponenets = 25,
    AssignTaxtoMultipleCategories = 26,
    SetupCostCentreAssignToClaimants = 27,
    AssignCostCentre = 28,
    CustomerCostCentreProjectBudget = 29,
    ProjectListing = 30,
    CustomerUserRule = 31,
    SetupCustomer = 32,
    BusinessPurposeSetup = 33,
    AdditionalField = 34,
    BasicMileageRates = 35,
    AssignVehicletoClaimants = 36,
    SetupAdvancedMileage = 37,
    SetupDifferentialRatesOwnershipType = 38,
    SetupUKRatesOwnershipType = 39,
    AssignVehicletoMultipleClaimants = 40,
    RegisterCard = 41,
    SetupImportTemplate = 42,
    SetupCreditCardRules = 43,
    AssignCreditCardtoClaimant = 46,
    AssignPrivilegestoapproverName = 47,
    BuildNewReport = 48,
    AssignApproverToCostCentres = 49,
    AssignMileageRatetoGrade = 50,
    TriangulationOfficeNameAddress = 51,
    TriangulationSetupOfficeWorkers = 52,
    FuelReceiptManagementFunction = 53,
    TriangulationSetupHomeWorkers = 54,
    ExpenseCappingMonthlyClaimantWisePolicy = 55,
    ExpenseCappingYearlyClaimantWisePolicy = 56,
    SetupSupplier = 57,
    AssignSupplierRule = 58,
    DutyofCareStep1 = 59,
    DutyofCareStep2 = 60,
    DutyofCareStep3 = 61,
    DutyofCareStep4 = 62,
    DutyofCareStep5 = 63,
    SetupLocation = 64,
    AssignLocation = 65,
    ConfigureApprovalRule = 70,
    MultipleCountry = 71,
    SetupMultipleCompanies = 72,
    Advances = 73,
    AccountingIntegrationSetup = 74,
    MultipleVehicle = 75,
    AdvancesClaimantCappingPolicy = 76,
    AdvancesGradeCappingPolicy = 77,
    Discount = 80,
    OrganisationDiscount = 81,
    OrganisationDiscountContract = 82,
    CostCentreDisplaySetting = 83,
    AttendeeTypeFunction = 84,
    AllowanceRate = 85,
    AllowanceOwnershipMapping = 86,
    ConfigureCompanyApprovalRule = 90,
    AssignAccountingCodeToCompanyLog = 91,
    AssignEmployeesToCompany = 92,
    ComplianceStatementFunction = 93,
    VatNoVatReceiptType = 94,
    SetupDelegator = 100,
    SetupDelegate = 101,
    AssignDelegate = 102,
    AssignCounterApprover = 103,
    CounterApproverRule = 104,
    AssignCounterApproverToCategory = 105,
    FxRateStep1 = 106,
    FxRateStep3 = 107,
    TriangulationRule = 108,
    PowerUserPreference = 109,
    MileageStep5Rule = 110,
    YearEndPolicy = 111,
    NRUpdateFromDashboardByFM = 112,
    DeputyPreference = 113,
    FCUpdateFromDashboardByFM = 114,
    PassengerPayEnabledForTriangulation = 115,
    AdditionalVendorSettings = 118,

    MileageRateType = 119,
    MileageMeasure = 120,
    CategoryMileage = 121,
    InviteVendor = 122,
    VendorForm = 123,
    ApproveForms = 124,
    KYCFormSetup = 125,
    ConfigureXeroSettings = 130,
    AccIntAssignTaxTypeToTaxCode = 131,
    AccIntMapCostCentre = 132,
    AccIntMapLocation = 133,
    AccIntMapCreditCard = 134,
    CostCentreTrackingCategory = 135,
    LocationTrackingCategory = 136,
    AccIntColumnSelection = 137,
    AccIntMapEmployee = 138,
    ConfigureXeroRefSettings = 139,
    ConfigureXeroDesSettings = 140,
    AccIntMapExpCategory = 141,
    AccIntEmployeeMapRule = 142,
    BuildNewReportReportFields = 143,
    AdditionalFieldCatAssign = 154,
    CFPAssignCategory = 144,
    CFPOwnershipType = 145,
    CFPCategory = 146,
    SingleSignOnEnableDisable = 147,
    SetupPayStep = 150,
    DisappearingExpensesStep1 = 155,
    DisappearingExpensesStep2 = 156,
    CardFunctionToLiveMode = 95,
    PowerUserCasualUser = 157,
    RecallExportedExpense = 158,
    CCFeedPreferencePurchaseLog = 159,
    CCFeedScheduler = 160,
    CCFeedStartDate = 161,
    ManageNegativeTransaction = 162,
    ManageSupplierContact = 163,
    ManageYourCard = 164,
    SpecialCategoryExportSetup = 165,
    SpecialCategory_Step2 = 166,
    QBProjectsTurnOnOff = 162,
    QBProjectsMapping = 163,
    ContainerSetting = 167,
    SetupContainer = 168,
    
}

export enum PricingPageTypeEnum {
    InternalPage = 1,
    ExternalPage = 2
}

export enum LocationStatus {
    All = 0,
    Active = 1,
    Inactive = 2
}

export enum ExpenseErrorWarningTypeEnum {
    CalculateMileageDetails = 1,
    ZeroDistanceNotAllowed = 2,
    InActiveExpenseCategoryAlert = 3,
    ReceiptTypeAlert = 4,
    ZeroDistance = 5,
    CategoryMandetory = 6,
    ZeroAmountNotAllowed = 7,
    SplitCategoryAmountMismatch = 8,
}
export enum EmailEnum {
    ClaimMileageWhenMileageNotSetup = "ClaimMileageWhenMileageNotSetup",
    ClaimMilesWhenVehicleNotSetup = "ClaimMilesWhenVehicleNotSetup",
    ClaimMilesAndVehicleNotApproved = "ClaimMilesAndVehicleNotApproved",
    ClaimantMilesAndOfficeLocationNotSetWithTriangulation = "ClaimantMilesAndOfficeLocationNotSetWithTriangulation",
    GradeNotAssigned = "GradeNotAssigned"
}

export enum AdditionalFieldTypeEnum {
    Text = 1,
    Dropdown = 2
}

export enum OrgFeedbackTypes {
    OnboardingFeedback = 1,
    TrialFeedback = 2
}

export enum EmployeeActionsEnum {
    Deactivate = 1,
    ResendInvite = 2,
    SendInvite = 3,
    Activate = 4,
    Edit = 5
}

export enum CCActionsEnum {
    Deactivate = 1,
    Activate = 2,
    Edit = 3
}

export enum CustomerActionEnum {
    Deactivate = 1,
    Activate = 2,
    Edit = 3
}

export enum Page {
    Home = 1,
    ShoppingCartConfirm = 2
}

export enum EmployeeLocationStatus {
    All = 3,
    WithLocation = 1,
    WithoutLocation = 2
}

export enum BillingMethod {
    PerPersonBilling = 1,
    HybridBilling = 2
}

export enum UniversalImportTemplateEnum {
    Employee = 1,
    ExpenseCategory = 2,
    ExportTemplates = 3,
    ImportCreditCardStatement = 4,
    AssignApproverToCostCentres = 5,
    AdvanceClaimantCappingPolicy = 6,
    Customer = 7,
    ImportEmployees = 8,
    ImportCategories = 9,
    ImportMileageRates = 10,
    CounterApp = 11,
    FxRate = 12,
    ImportVehicleAssignment = 13,
    ImportOfficeBasedClaimants = 14,
    Deputy = 15,
    ImportAssignCreditCard = 16,
    ImportGrade = 17
}
export enum ClaimantWiseCappingPolicyStatusEnum {
    EmployeeWithPolicy = 1,
    EmployeeWithoutPolicy = 2
}

export enum OtherPolicyTypeEnum {
    MonthlyCapping = 6,
    YearlyCapping = 7
}

export enum SupplierStatus {
    All = 0,
    Active = 1,
    Inactive = 2
}

export enum EmployeeSupplierConfig {
    SupplierRuleId = 0,
    ExpenseCategoryField = 1,
    All = 2,
    SupplierField = 3
}

export enum XeroSteps {
    Connect = 1,
    ConfigEmployees = 2,
    ConfigTax = 3,
    ConfigCategories = 4,
    ConfigCreditCard = 5,
    ConfigTcCostCentre = 6,
    ConfigTcCustomer = 7,
    ConfigTcLocation = 8,
    ConfigSupplier = 21,
}

export enum AccSysAssignmentType {
    tally = 1,
    sage50 = 2,
    xeroReference = 3,
    xeroDescription = 4
}

export enum UniversalImportStepEnum {
    UploadFile = 1,
    DefineHeader = 2,
    ConfirmMapping = 3,
    Review = 4,
    ImportSummary = 5
}

export enum UniversalImportReviewFilterEnum {
    WithErrors = 1,
    WithoutErrors = 2
}

export enum DocumentStatus {
    Rejected = 5,
    Approved = 6
}

export enum CardSupervisoryActionEnum {
    NoAction = 0,
    ReAssigned = 1,
    Deleted = 2
}

export enum AdvanceType {
    CashAdvance = 1,
    CreditCardAdvance = 2,
    AdvanceReturn = 3
}

export enum AdvanceCappingPolicyType {
    CappingByIndividual = 1,
    CappingByGrade = 2
}

export enum UniversalImportEmployeesResponseEnum {
    CostCentre = 1,
    Grade = 2,
    ActivatedOwnershipType = 3,
    OfficeSetup = 4
}

export enum CostCentreApproversResponseEnum {
    CostCentre = 1
}

export enum UniversalImportStepsEnum {
    UploadFile = 1,
    DefineHeader = 2,
    ConfirmMapping = 3,
    Review = 4,
    ImportSummary = 5
}

export enum ExpenseStatusAction {
    Delete = 1,
    Submit = 2,
    Recall = 3,
    Archive = 4
}

export enum PreApprovalStatusEnum {
    Approved = 6,
    Rejected = 4,
    Pending = 5,
    PassForPayment = 8, // ApprovedForPayment => PassForPayment
    Draft = 3,//All recalled expenses
    All = 0 //All indicates all other status.
}
export enum DashboardCountEnum {
    Approver_Count = 1,
    Finance_Approver_Count = 2,
    Dashboard_Count = 3,
    Mileage_Wizard_Count = 4
}

export enum MultipleCountryStatusEnum {
    All = 0,
    Active = 1,
    Inactive = 2
}

export enum CcImportTemplateTypeEnum {
    SingleUser = 1,
    MultipleUser = 2
}

export enum CcImportCardNumberSelectionModeEnum {
    Column = 1,
    Cell = 2
}

export enum AccIntMappingModelEnum {
    ExpenseCategory = 1,
    EntertainmentExpenseCategory = 2,
    UserOrgMapping = 3,
    TaxRateMaster = 4,
    TaxRateComponent = 5,
    CostCentre = 6,
    Customer = 7,
    Location = 8,
    Supplier = 9,
    Projects = 10

}
export enum StatusEnum {
    Inactive = 0,
    Active = 1
}

export enum DiscountMonthsEnum {
    oneMonthsDiscount = 1,
    threeMonthsDiscount = 3,
    sixMonthsDiscount = 6,
    twelveMonthsDiscount = 12,
    twentyFourMonthsDiscount = 24,
    thirtySixMonthsDiscount = 36,
    fourtyEightMonthsDiscount = 48,
    sixtyMonthsDiscount = 60
}
export enum TaxRateStatusEnum {
    Active = 0,
    Inactive = 1,
    FutureRate = 2
}
export enum GradePolicyFilterEnum {
    GradeWithPolicy = 1,
    GradeWithoutPolicy = 2
}
export enum JourneyTypeEnum {
    Single = 1,
    Return = 2,
    Multiple = 3
}
export enum AcctIntEmployeeSyncStatusEnum {
    All = 0,
    Linked = 1,
    Ignored = 2,
    NotLinked = 99,
}

export enum AcctIntDomainStatusEnum {
    All = 0,
    Syncd = 1, //Syncd
    Ignored = 2, //Ignored for AutoSync
    Pending = 3 //Pending Action for AutoSync
}

export enum SSOStatusEnum {
    All = 0,
    Enabled = 1,
    Disabled = 2,
    RequresAction = 3
}

export enum MileageAllowanceEnum {
    OnePassenger = 1,
    TwoPassenger = 2,
    ThreePassenger = 3,
    FourPassenger = 4,
    OneLoad = 5,
    OnePassengerOneLoad = 6,
    TwoPassengerOneLoad = 7,
    ThreePassengerOneLoad = 8,
    FourPassengerOneLoad = 9
}
export enum EntertainmentEmpEnum {
    Employee = 1,
    Guest = 2,
    Tips = 3
}


export enum FunctionCategoryEnum {
    YourOrganisation = 1,
    EmployeesApprovers = 2,
    CostCentresCustomers = 3,
    ExpenseCategories = 4,
    TaxRates = 5,
    ExpensePolicy = 6,
    CreditCards = 7,
    MileageRates = 8,
    DataExport = 9,
    Analysis = 14,
    OpenAPIs = 15,
    UniversalImport = 16,
    SalesOnDemand = 17,
    APAutomation = 18,
    ReportWriter = 19,
    AccessCharge=0
}

export enum FunctionCategoryNameEnum {
    YourOrganisation = 'Your Organisation',
    EmployeesApprovers = 'Employees & Approvers',
    CostCentresCustomers = 'Cost Centres & Customers',
    ExpenseCategories = 'Expense Categories',
    TaxRates = 'Tax Rates',
    ExpensePolicy = 'Expense Policy',
    CreditCards = 'Credit Cards (CC)',
    MileageRates = 'Mileage Rates',
    DataExport = 'Data Export',
    Analysis = 'Analysis',
    OpenAPIs = 'Open APIs',
    UniversalImport = 'Universal Import',
    SalesOnDemand = 'Sales on Demand',
    APAutomation = 'AP Automation',
    ReportWriter = 'Report Writer',
    AccessCharge = 'Access Charge'
}


export enum ImportEmployeesResponseEnum {
    CostCentre = 1,
    Grade = 2,
    EmployeeErrorsList = 3,
    SuccessRecordsCounts = 4,
    ChangedClaimantsApproverCounts = 5,
    ChangedClaimantsGradeCounts = 6,
    ChangedClaimantsCostCentreCounts = 7,
    ChangedClaimantsLastNameCounts = 8,
    ChangedClaimantsFirstNameCounts = 9,

}
export enum ApproverStatusEnum {
    NotInvited = 2,
    InActive = 3,
    NoJoined = 4
}
export enum CCLiveModeEnum {
    TestMode = 0,
    LiveMode = 1,
    NotConfirm = 2
}
export enum DuplicateCheck {
    All = -1,
    On = 1,
    Off = 2
}

export enum AssignTaxToCategoriesFilterEnum {
    All = 0,
    WithTax = 1,
    WithoutTax = 2
}
export enum CheckType {
    Document = 1,
    Switch = 2,
    MOT = 3
}

export enum FrequencyType {
    Option1 = 1,
    Option2 = 2,
    Option3 = 3,
    Option4 = 4,
    Option5 = 5,
    Option6 = 6
}



export enum CounterAppType {
    All = 1,
    ApproverswithCA = 2,
    ApproverswithoutCA = 3
}

export enum CounterAppSeqType {
    Or = 1,
    All = 2,
    Mandatory = 3,
    End = 4,
    End2 = 5
}

export enum CounterAppDetType {
    All = 1,
    Current = 2,
    Deleted = 3
}

export enum AlertPanelTypeEnum {
    Purchase = 1,
    SetuptIncomplete = 2,
    NotApplicable = 3,
    ComingSoon = 4
}
export enum DelegateFilterEnum {
    All = 0,
    Active = 1,
    InActive = 2
}
export enum DelegateActionTypeEnum {
    Delegator = 1,
    Delegate = 2
}
export enum DelegatorExpenseEnum {
    MyExpense = 1,
    DelegatorExpense = 2
}
export enum ApproverCostCentreFilterEnum {
    All = 0,
    NoAproverCount = 1,
    OneAproverCount = 2,
    ManyApproverCount = 3
}

export enum DelegatorFilterEnum {
    All = 0,
    Active = 1,
    InActive = 2
}

export enum WizardActionEnum {
    SetupMileage = 1,
    VehicleManagement = 2,
    MultipleVehicle = 3,
    Triangulation = 4,
    NRFC = 5,
    DOC = 6,
    PassengerLoad = 7,
    ExpenseCategory = 8,
    Delegate = 9,
    CounterApprover = 10,
    FxRate = 11,
    Vendor = 12,
    AccountingIntegration = 13,
    Deputy = 14,
    ManageEmployee = 26,
    ClaimantMyMileage = 27,
    ReportWriter = 28,
    Tax = 25,
    AdditionalFields = 20,
    AdditionalFieldsStep1 = 21,
    AdditionalFieldsStep2 = 22,
    AdditionalFieldsStep3 = 23,
    AdditionalFieldsStep4 = 24,
    VatNoVat = 15,
    CarbonFootprint = 29,
    DisappearingExpense = 31,
    SingleSignOn = 32,
    Grade = 33,
    ManageCCFeedTransactions = 34,
    AccIntTrackingCategoryLocation = 35,
    AccIntTrackingCategoryCostCentre = 36,
    SpecialCategory = 37,
    QuickBooksProjects = 38
}

export enum PanelClosActionEnum {
    Close = 1,
    Save = 2,
    Log = 3,
    Delete = 4,
    Inactive = 5,
    Active = 4
}
export enum ApproverDeputy {
    ApproverWithAll = 0,
    ApproverWithDeputy = 1,
    ApproverWithoutDeputy = 2
}
export enum PolicyDashboardLoadType {
    InitialLoad = 0,
    GridFilterLoad = 1
}
export enum PolicyGridLoadType {
    Category = 1,
    Claimant = 2,
    Approver = 3,
    All = 4
}


export enum WorkflowApproverEnum {
    Approver = 1,
    MultiApprover = 2,
    CounterApprover = 3
}

export enum CounterApproverRule {
    All = 0,
    Single = 1,
    Multiple = 2
}

export enum FxRateRule {
    Default = 1,
    Editable = 2,
    NotEditable = 3
}

export enum FxRateSubRule {
    All = 1,
    Imported = 2
}

export enum CurrencyRateEnum {
    All = 0,
    WithRate = 1,
    WithoutRate = 2
}

export enum CurrencyRateSelectionEnum {
    Current = 1,
    Future = 2,
    Expired = 3
}

export enum HideMileageFunctions {
    SetupUKRates = 34,
    AssignVehicleToClaimants = 35,
    Triangulation = 36,
    SetupSingleRates = 51,
    SingleRateAssignVehicleToClaimants = 52,
    SetupDifferentialRates = 53,
    DifferentialRateAssignVehicleToClaimants = 54,
    AssignRatetoGrade = 91,
    BasicMileageRates = 6,
    FuelCard = 73,
    MultipleVehicle = 111,
    NonReimbursableFuel = 72,
    PassengerLoadMiles = 112,
    AssignVehicleToClaimant = 8
}

export enum FilterFxRateHistory {
    All = 1,
    Next6Months = 2,
    Next12Months = 3
}
export enum PolicyDashboardGridType {
    Category = 1,
    Claimant = 2,
    Approver = 3
}
export enum PolicyDashboardGridColumnType {
    selected = "selected",
    policyBreachCount = "policyBreachCount"
}

export enum UnsubscribeCountryOptionEnum {
    Deactivate = 1,
    Move = 2,
}
export enum ClaimType {
    Expense = 1,
    Invoice = 2
}

export enum ClaimAction {
    ClaimList = 1,
    CreateClaim = 2,
    EditClaim = 3,
    NoClaimMsg = 4,
    CreateClaimBtnMsg = 5,
    ClaimDetail = 6,
    ApproperClaimantListCount = 7,
    ClaimCount = 8,
    ClaimantName = 9,
    FilterClaimDate = 10,
    AddReceipt = 11,
    ViewReceipt = 12
}



export enum VendorKYCStatusEnum {
    NotRequired = 7,
    PleaseComplete = 1,
    Incomplete = 2,
    PendingForSubmission = 3,
    PendingForApproval = 4,
    Rejected = 5,
    Approved = 6,

}
export enum FieldType {
    Text = 1,
    Date = 2,
    Img = 3,
    RadioButton = 4,
    Dropdown = 5
}

export enum TextChanges {
    Displayname = 1,
    CountofCharacters = 2,
    NotesCharacterCount = 3
}

export enum CheckBoxChanges {
    SpecialCharacters = 1,
    ImgAlignment = 2
}

export enum FormType {
    Active = 1,
    Archived = 2
}

export enum FormAction {
    Archive = 1,
    Copy = 2,
    Edit = 3
}
export enum ClaimantTypeEnum {
    ALL = 0,
    Claimant = 1,
    Vendor = 2
}

export enum EmployeeTypeEnum {
    Claimant = 0,
    Vendor = 1
}

export enum VehicleDOCExistsEnum {
    Exists = 1,
    NotExists = 2
}

export enum ImportValidation {
    PastDate = 1,
    Invaid = 2,
    Blank = 3,
    Duplicate = 4,
    LessThan = 5,
    GreaterThan = 6
}
export enum CCPendingTransactionsFilterEnum {
    All = 0,
    NotCompleted = 1,
    ReRouted = 2,
    Dismissed = 3,
    Orphan = 4,
    Completed = 5
}

export enum CCPendingSupervisoryTransactionsFilterEnum {
    All = 0,
    Deleted = 1,
    ReAssigned = 2
}

export enum DeleteExpenseEnum {
    DeletedWithReceipt,
    Cancel,
    New,
    Delete
}

export enum TaxRateComponentStatusEnum {
    Active = 1,
    Inactive = 2,
    Future = 3
}

export enum TaxActionEnum {
    Edit = 1,
    Delete = 2
}

export enum AdditionalFieldCatAssignEnum {
    All = 0,
    Assigned = 1,
    Partially = 2,
    Unassigned = 3
}

export enum AdditionalFieldTypesEnum {
    FieldType1 = 'additionalField1',
    FieldType2 = 'additionalField2',
    FieldType3 = 'additionalField3',
    FieldType4 = 'additionalField4'
}

export enum CFPCategoryEnum {
    All = 0,
    Assigned = 1,
    UnAssigned = 2
}

export enum DOCStatusEnum {
    notValid = 0,
    incomplete = 2,
    pleaseComplete = 1,
    pendingSubmission = 3,
    pendingApproval = 4,
    approved = 6,
    rejected = 5,
    expired = 7,
    recalled = 8,
    aboutToExpire = 10,
    suspended = 9,
    notRequired = 11
}
export enum FCInfoStatusEnum {
    ClosingBalanceRequired = 1,
    FuelCardRequired = 2,
    ClosingBalUpdateRequired = 3,
    ExpenseToBeDel = 4,
    OpeningBalDateNotValid = 5,
    ClosingBalRequiredOnLastDayOfMonth = 6,
    ClosingBalDateNotValid = 7,
    MaxReceiptCountBreach = 8,
    DisableOpeningBalanceDetail = 9,
    VehicleChanged = 10,
}


export enum CounterCategoryType {
    All = 0,
    CACategoryByPassApprover = 1,
    CACategoryWithApprover = 2,
    CACategoryWithApproverAndCAApprover = 3
}

export enum ReceiptScanOption {
    NotScanned = 1,
    Discarded = 2,
    Accepted = 3
}

export enum UserCustomSettingEnum {
    DragAndDropApproverPanel = 1
}

export enum ApprovalSectionEnum{
    expenseDetails = 1,
    taxDetails = 2,
    additionalDetails = 3,
    ccDetails = 4
}

export enum CCTransactionsFilterEnum {
    All = 0,
    NotCompleted = 1,
    ReRouted = 2,
    Dismissed = 3,
    Orphan = 4,
    Completed = 5
}

export enum SpecialCategoryExportTypeEnum {
    EndDate = 1,
    StartDate = 2
}